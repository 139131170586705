import React from 'react';

const CheckSuccessIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3612_189318)">
        <mask
          id="mask0_3612_189318"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="50"
          height="48"
        >
          <rect x="-0.000732422" width="48.0015" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3612_189318)">
          <path
            d="M19.0998 36L7.69946 24.6L10.5496 21.75L19.0998 30.2999L37.4504 11.95L40.3005 14.8L19.0998 36Z"
            fill="#36C692"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3612_189318">
          <rect
            width="48.0015"
            height="48"
            fill="white"
            transform="translate(-0.000732422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckSuccessIcon;
