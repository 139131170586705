import React from 'react';

type INotFoundIcon = {
  color?: string;
};

const NotFoundIcon: React.FC<INotFoundIcon> = ({ color = '#232A31' }) => {
  return (
    <div>
      <svg
        width="154"
        height="69"
        viewBox="0 0 154 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.808 43.5H37.528V0.875999H27.16H19.48L0.952 40.812V53.772H27.16V67.596H37.528V53.772H42.808V43.5ZM11.224 43.5V43.116L27.16 8.748V43.5H11.224ZM77.6973 0.107998H77.5052C65.6973 0.107998 56.1933 9.70799 56.1933 21.516V46.956C56.1933 58.668 65.6973 68.268 77.5052 68.268H77.6973C89.5053 68.268 99.1053 58.668 99.1053 46.956V21.516C99.1053 9.70799 89.5053 0.107998 77.6973 0.107998ZM77.5052 10.476H77.6973C79.9053 10.476 82.0173 11.052 83.6493 12.204L66.4653 44.364V21.516C66.4653 15.372 71.3613 10.476 77.5052 10.476ZM77.6973 57.996H77.5052C75.3933 57.996 73.3773 57.324 71.7453 56.364L88.7373 24.492V46.956C88.7373 53.004 83.8413 57.996 77.6973 57.996ZM153.902 43.5H148.622V0.875999H138.254H130.574L112.046 40.812V53.772H138.254V67.596H148.622V53.772H153.902V43.5ZM122.318 43.5V43.116L138.254 8.748V43.5H122.318Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default NotFoundIcon;
