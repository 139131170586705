import React from 'react';
import styles from './Card.module.scss';

const Card: React.FC<{
  title?: string;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
}> = ({ title = '', children = <></>, actionButton }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['title-bar']}>
        <div className={styles['title']}>{title}</div>
        {actionButton && (
          <div className={styles['action-button']}>{actionButton}</div>
        )}
      </div>
      <div className={styles['content']}>{children}</div>
    </div>
  );
};

export default Card;
