// UserFeedback.thunk.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PostFeedbackRequestDto,
  Product,
  Topic,
  UserFeedbackCommandApi
} from '@src/API/generated-user-feedback';
import {
  saveFeedbackRequest,
  saveFeedbackSuccess,
  saveFeedbackFailure,
  selectUserFeedback
} from './UserFeedback.reducer';
import { AppDispatch, store } from '@src/store';

interface SaveFeedbackPayload {
  value: {
    platform: string;
    topic: string;
    message: string;
    captchaToken: string;
  };
  _api: UserFeedbackCommandApi;
}

export const saveFeedback = createAsyncThunk(
  'userFeedback/saveFeedback',
  async (
    payload: {
      value: {
        platform: string;
        topic: string;
        message: string;
        captchaToken: string;
      };
      _api: UserFeedbackCommandApi;
    },
    { dispatch }
  ) => {
    const { value, _api } = payload;
    try {
      dispatch(saveFeedbackRequest(payload)); // Dispatch the request action
      const formValue = {
        ...value,
        product: value.platform.replace(/ /g, '') as Product,
        topic: value.topic.replace(/ /g, '') as Topic,
        description: value.message,
        captchaToken: value.captchaToken
      } as PostFeedbackRequestDto;

      const res = await _api.feedbackPost(undefined, formValue);
      if (res.status === 200) {
        dispatch(saveFeedbackSuccess()); // Dispatch the success action
      } else {
        dispatch(saveFeedbackFailure('error'));
      }
    } catch (err) {
      const checkErr = `${err}`.includes('undefined')
        ? 'Error, Something went wrong. Please refresh or try again later'
        : err;
      dispatch(saveFeedbackFailure(checkErr)); // Dispatch the failure action
    }
  }
);

type RetrySaveFeedback = () => (dispatch: AppDispatch) => void;

export const retrySaveFeedback: RetrySaveFeedback = () => (dispatch) => {
  const state = selectUserFeedback(store.getState());
  dispatch(
    saveFeedback({
      value: state.savedForm,
      _api: state._api as UserFeedbackCommandApi
    })
  ); // Dispatch the saveFeedback action
};
