// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SC_oPGyeUxhydV2b5axl{width:100%;font-family:"Barlow",Arial,sans-serif;font-style:normal;font-weight:600;font-size:28px;line-height:38px;text-align:center;color:#fff;margin:0px 0px 24px}.olgCx5OwYKGhlYbYX1xF{margin:0px 0px 24px;display:flex;align-items:center;justify-content:center;width:100%}.D75A8ZOB6zxYUUL5wpt9{font-family:"Barlow",Arial,sans-serif;font-style:normal;font-weight:400;font-size:16px;line-height:24px;text-align:center;color:#fff;width:100%;margin:0px 0px 56px}.uoMRhUbk7GIWGHsBx8rZ{margin:0px 18px}.uoMRhUbk7GIWGHsBx8rZ>button{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Error/Error.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,qCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,mBAAA,CAGF,sBACE,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,qCAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,mBAAA,CAGF,sBACE,eAAA,CACA,6BACE,UAAA","sourcesContent":[".title {\n  width: 100%;\n  font-family: 'Barlow', Arial, sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 28px;\n  line-height: 38px;\n  text-align: center;\n  color: #ffffff;\n  margin: 0px 0px 24px;\n}\n\n.icon {\n  margin: 0px 0px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.content {\n  font-family: 'Barlow', Arial, sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  text-align: center;\n  color: #ffffff;\n  width: 100%;\n  margin: 0px 0px 56px;\n}\n\n.button {\n  margin: 0px 18px;\n  & > button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SC_oPGyeUxhydV2b5axl`,
	"icon": `olgCx5OwYKGhlYbYX1xF`,
	"content": `D75A8ZOB6zxYUUL5wpt9`,
	"button": `uoMRhUbk7GIWGHsBx8rZ`
};
export default ___CSS_LOADER_EXPORT___;
