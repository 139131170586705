import React from 'react';
import styles from './FullPageLoading.module.scss';
import { IFullPageLoading } from './FullPageLoading.type';
import { Loading } from '@jupiter/react-common-component';

const SocialBindLoading: React.FC<IFullPageLoading> = ({ title = '' }) => {
  return (
    <>
      <div className={styles['container']}>
        <div className={styles['content-container']}>
          <div className={styles['content-title']}>
            <p>Connecting</p>
          </div>
          <Loading size={40} />
          <div className={styles['content-description']}>
            <p>
              Connecting to your <span>{title}</span> may take a few minutes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialBindLoading;
