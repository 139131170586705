import {
  addModal,
  closeLastModal,
  onClickBackgroundLastModal,
  removeLastModal
} from '@src/domain/modal/modal.reducer';
import { AppDispatch } from '@src/store';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

type IUseModal = (props?: { onClickBackground?: () => void }) => {
  showModal: (props: React.ReactNode) => void;
  closeModal: () => void;
};

const useModal: IUseModal = (props) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (props?.onClickBackground) {
      dispatch(onClickBackgroundLastModal(props.onClickBackground));
    }
  }, [dispatch, props?.onClickBackground]);

  const showModal = useCallback(
    (modal: React.ReactNode) => {
      dispatch(addModal({ content: modal }));
    },
    [dispatch]
  );

  const closeAnimate = () =>
    new Promise((resolve) => {
      dispatch(closeLastModal());
      setTimeout(() => {
        resolve(true);
      }, 500);
    });

  const closeModal = () => {
    closeAnimate().then(() => {
      dispatch(removeLastModal());
    });
  };

  return { showModal, closeModal };
};

export default useModal;
