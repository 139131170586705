// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nvl0rbEyWWwNxY0DLmar{width:100%;height:100%;min-height:calc(100vh - 90px);display:flex;align-items:center;justify-content:center}@media screen and (max-width: 376px){.nvl0rbEyWWwNxY0DLmar{min-height:calc(100vh - 150px)}}`, "",{"version":3,"sources":["webpack://./src/pages/SignInPage/SignInPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,6BAAA,CAIA,YAAA,CACA,kBAAA,CACA,sBAAA,CALA,qCAJF,sBAKI,8BAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  min-height: calc(100vh - 90px);\n  @media screen and (max-width: 376px) {\n    min-height: calc(100vh - 150px);\n  }\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n// @media screen and (min-width: 768px) and (max-width: 1023px) {\n//   .wrapper {\n//     margin-top: 64px;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `nvl0rbEyWWwNxY0DLmar`
};
export default ___CSS_LOADER_EXPORT___;
