import React, { useState, useCallback, useEffect } from 'react';
import styles from './CropProfile.module.scss';
import { Button } from '@jupiter/react-common-component';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { getCroppedImg } from './GetCroppedImage';

type ICropProfile = {
  selectedImage: string | null;
  showCrop?: boolean;
  setShowCrop: React.Dispatch<React.SetStateAction<boolean>>;
  setCroppedImage: React.Dispatch<
    React.SetStateAction<{
      file: File | null;
      fileUrl: string | null;
    }>
  >;
  adjustmentRange: boolean;
};

const CropProfile: React.FC<ICropProfile> = ({
  selectedImage,
  showCrop,
  setCroppedImage,
  setShowCrop,
  adjustmentRange
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [editedImage, setEditedImage] = useState<string>('');
  const [resultCropArea, setResultCropArea] = useState<Area>();
  const [flip, setFlip] = useState({ vertical: false, horizontal: false });
  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    if (croppedAreaPixels.width < 200 || croppedAreaPixels.height < 200) {
      setErrorMsg(
        `Your picture is too small (${croppedAreaPixels.height}x${croppedAreaPixels.width}). Please adjust your zoom.`
      );
      return true;
    } else {
      setErrorMsg('');
      setResultCropArea(croppedAreaPixels);
    }
  }, []);

  const increment = () => {
    if (zoom < 10) {
      setZoom((prev) => prev + 0.5);
    }
  };
  const decrement = () => {
    if (zoom >= 1.5) {
      setZoom((prev) => prev - 0.5);
    }
  };

  useEffect(() => {
    if (selectedImage !== null) {
      setEditedImage(selectedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  useEffect(() => {
    if (zoom === 1) {
      setCrop({ x: 0, y: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  const resetForm = () => {
    setErrorMsg('');
    setShowCrop(false);
    setZoom(1);
    setFlip({ vertical: false, horizontal: false });
    setRotation(0);
    setEditedImage('');
  };

  const resetCroppedImage = () => {
    setCroppedImage((prev) => ({
      ...prev,
      fileUrl: ''
    }));
  };

  const handleSave = async () => {
    if (resultCropArea && selectedImage) {
      const result = await getCroppedImg({
        imageSrc: selectedImage,
        pixelCrop: resultCropArea,
        rotation: rotation,
        flip
      });
      if (result) {
        const file = new File([result.blob], 'profile.jpg', {
          lastModified: new Date().getTime(),
          type: result.blob.type
        });
        setCroppedImage((prev) => ({
          ...prev,
          file: file,
          fileUrl: result.dataUrl
        }));
        setShowCrop(false);
        resetForm();
      }
    }
  };

  return (
    <>
      {showCrop && (
        <div className={styles['container']}>
          <div className={styles['content-wrapper']}>
            <div className={styles['title-wrapper']}>
              <p className={styles['title']}>Update Profile Picture</p>
            </div>
            <div className={styles['image-wrapper']}>
              {selectedImage && (
                <img
                  style={{ display: 'none' }}
                  loading="lazy"
                  onError={() => {
                    resetForm();
                    resetCroppedImage();
                  }}
                  className={styles['photo-profile']}
                  src={selectedImage}
                />
              )}
              <div className={styles['crop-wrapper']}>
                <Cropper
                  image={`${editedImage}`}
                  crop={crop}
                  objectFit="auto-cover"
                  zoom={adjustmentRange ? zoom : undefined}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </div>
            <div>
              <p
                style={{
                  color: 'red',
                  paddingTop: '8px',
                  textAlign: 'center',
                  fontSize: 12
                }}
              >
                {errorMsg}
              </p>
            </div>
            <div className={styles['scale-wrapper']}>
              <div
                className={`${styles['decrement']} ${
                  !adjustmentRange && styles['disabled']
                }`}
                onClick={() => (adjustmentRange ? decrement() : null)}
              ></div>
              <div className={styles['range-wrapper']}>
                <input
                  type="range"
                  max={adjustmentRange ? 10 : 0}
                  min={1}
                  value={zoom}
                  onChange={(e) => setZoom(Number(e.target.value))}
                  step={0.1}
                  className={styles['win10-thumb']}
                  disabled={!adjustmentRange}
                />
              </div>
              <div
                className={`${styles['increment']} ${
                  !adjustmentRange && styles['disabled']
                }`}
                onClick={() => (adjustmentRange ? increment() : null)}
              ></div>
            </div>
            <div className={styles['button-wrapper']}>
              <div className={styles['left-button']}>
                <Button
                  isLoading={false}
                  label="Cancel"
                  onClick={() => {
                    resetForm();
                    resetCroppedImage();
                  }}
                  size="responsive"
                  variant="default-magenta"
                  theme="dark"
                  className={styles['button']}
                />
              </div>
              <div className={styles['right-button']}>
                <Button
                  disabled={errorMsg !== ''}
                  isLoading={false}
                  label="Apply"
                  onClick={() => {
                    handleSave();
                  }}
                  size="responsive"
                  variant="default-magenta"
                  className={styles['button']}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CropProfile;
