import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicyContent = () => {
  return (
    <div className={styles['content-wrapper']}>
      <div className={styles['header-letter']}>
        <p>Privacy Policy</p>
        <p>Last Updated On 06-Nov-2023</p>
        <p>Effective Date 06-Nov-2023</p>
      </div>

      <div className={styles['paragraph']}>
        <p>
          This Privacy Policy describes the policies of Mythic Portal, email:
          legal@confiction.com on the collection, use and disclosure of your
          information that we collect when you use our content and product
          (https://www.confiction.com). (the “Service”). By accessing or using
          the Service, you are consenting to the collection, use and disclosure
          of your information in accordance with this Privacy Policy. If you do
          not consent to the same, please do not access or use the Service.
        </p>
        <p>
          We may modify this Privacy Policy at any time without any prior notice
          to you and will post the revised Privacy Policy on the Service. The
          revised Policy will be effective 180 days from when the revised Policy
          is posted in the Service and your continued access or use of the
          Service after such time will constitute your acceptance of the revised
          Privacy Policy. We therefore recommend that you periodically review
          this page.
        </p>
      </div>
      <div className={styles['list-section']}>
        <p>Information We Collect:</p>
        <p>
          We will collect and process the following personal information about
          you:
        </p>
        <ul>
          <li>Name</li>
          <li>Username </li>
          <li>Email </li>
          <li>Transaction and Payment Info </li>
          <li>Riftstorm Account ID (linked) </li>
        </ul>
      </div>

      <div className={styles['paragraph']}>
        <p>Information Collection and Use </p>
        <p>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to Mythic Portal. The information that we
          request will be retained by us and used as described in this privacy
          policy.
        </p>
      </div>
      <div className={styles['list-section']}>
        <p>
          The Service does use third-party services that may collect information
          used to identify you.
        </p>
        <p>
          Link to the privacy policy of third-party service providers used by
          accessing or using the Service.
        </p>
        <ul>
          <li>Riftstorm Account ID (linked)</li>
          <li>Digital payment gateway provider </li>
        </ul>
      </div>
      <div className={styles['paragraph']}>
        <p>Log Data </p>
        <p>
          We want to inform you that whenever you use our Service we may collect
          Log Data may include information such as your device Internet Protocol
          (“IP”) address, device name, operating system version, the
          configuration when utilizing our Service, the time and date of your
          use of the Service, and other statistics.{' '}
        </p>
      </div>
      <div className={styles['list-section']}>
        <p> How We Collect Your Information: </p>
        <p>We collect/receive information about you in the following manner:</p>
        <ul>
          <li>
            When a user fills up the registration form or otherwise submits
            personal information
          </li>
          <li>Interacts with the website. </li>
        </ul>
      </div>
      <div className={styles['list-section']}>
        <p> How We Use Your Information: </p>
        <p>
          We will use the information that we collect about you for the
          following purposes:
        </p>
        <ul>
          <li>Marketing/ Promotional </li>
          <li>Creating user account </li>
          <li>Customer feedback collection </li>
          <li>Support </li>
          <li>Administration info </li>
          <li>Site protection </li>
          <li>Dispute resolution </li>
        </ul>
      </div>
      <div className={styles['paragraph']}>
        <p>Manage user account </p>
        <p>
          If we want to use your information for any other purpose, we will ask
          you for consent and will use your information only on receiving your
          consent and then, only for the purpose(s) for which grant consent
          unless we are required to do otherwise by law.
        </p>
      </div>
      <div className={styles['list-section']}>
        <p>How We Share Your Information: </p>
        <p>
          We will not transfer your personal information to any third party
          without seeking your consent, except in limited circumstances as
          described below:
        </p>
        <ul>
          <li>Legal entities </li>
          <li>Analytics </li>
          <li>Payment recovery services </li>
          <li>Data collection & process </li>
        </ul>
      </div>
      <div className={styles['ordered-list-section']}>
        <p>
          We require such third parties to use the personal information we
          transfer to them only for the purpose for which it was transferred and
          not to retain it for longer than is required for fulfilling the said
          purpose.
        </p>
        <p>We may also disclose your personal information for the following:</p>
        <ol>
          <li>
            <p>
              to comply with applicable law, regulation, court order or other
              legal process;
            </p>
          </li>
          <li>
            <p>
              to enforce your agreements with us, including this Privacy Policy;
              or
            </p>
          </li>
          <li>
            <p>
              to respond to claims that your use of the Service violates any
              third-party rights. If the Service or our company is merged or
              acquired with another company, your information will be one of the
              assets that is transferred to the new owner.
            </p>
          </li>
        </ol>
      </div>
      <div className={styles['paragraph']}>
        <p> Retention Of Your Information: </p>
        <p>
          We will retain your personal information with us for 90 days to 2
          years after users terminate their accounts or for as long as we need
          it to fulfill the purposes for which it was collected as detailed in
          this Privacy Policy. We may need to retain certain information for
          longer periods such as record-keeping / reporting in accordance with
          applicable law or for other legitimate reasons like enforcement of
          legal rights, fraud prevention, etc. Residual anonymous information
          and aggregate information, neither of which identifies you (directly
          or indirectly), may be stored indefinitely.
        </p>
      </div>
      <div className={styles['paragraph']}>
        <p> Your Rights: </p>
        <p>
          {' '}
          Depending on the law that applies, you may have a right to access and
          rectify or erase your personal data or receive a copy of your personal
          data, restrict or object to the active processing of your data, ask us
          to share (port) your personal information to another entity, withdraw
          any consent you provided to us to process your data, a right to lodge
          a complaint with a statutory authority and such other rights as may be
          relevant under applicable laws. To exercise these rights, you can
          write to us at legal@confiction.com. We will respond to your request
          in accordance with applicable law.{' '}
        </p>
      </div>
      <div className={styles['paragraph']}>
        <p>
          You may opt-out of direct marketing communications or the profiling we
          carry out for marketing purposes by writing to us at
          legal@confiction.com.{' '}
        </p>
        <p>
          Do note that if you do not allow us to collect or process the required
          personal information or withdraw the consent to process the same for
          the required purposes, you may not be able to access or use the
          services for which your information was sought.
        </p>
      </div>
      <div className={styles['paragraph']}>
        <p> Security: </p>
        <p>
          {' '}
          The security of your information is important to us and we will use
          reasonable security measures to prevent the loss, misuse or
          unauthorized alteration of your information under our control.
          However, given the inherent risks, we cannot guarantee absolute
          security and consequently, we cannot ensure or warrant the security of
          any information you transmit to us and you do so at your own risk.{' '}
        </p>
      </div>
      <div className={styles['paragraph']}>
        <p>Grievance / Data Protection Officer: </p>
        <p>
          If you have any queries or concerns about the processing of your
          information that is available with us, you may email our Grievance
          Officer email: legal@confiction.com. We will address your concerns in
          accordance with applicable law.{' '}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
