import React from 'react';
import ModalV2 from './ModalV2';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { ErrorBoundary } from 'react-error-boundary';
import Error from '../Error/Error';

const ModalV2Provider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { modal: modalState } = useSelector((state: RootState) => state);
  return (
    <>
      {children}
      {modalState.modals.map((item, i) => (
        <React.Fragment key={`modal-${i}`}>
          <ErrorBoundary FallbackComponent={Error}>
            <ModalV2
              onClickBg={item.onClickBg}
              content={item.content}
              isClose={item.isClose}
            />
          </ErrorBoundary>
        </React.Fragment>
      ))}
    </>
  );
};

export default ModalV2Provider;
