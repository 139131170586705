import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import SocialBindLoading from '@src/components/atoms/LoadingScreen/SocialBindLoading';
import { getUserProfile } from '@src/domain/User/User.thunk';
import useDiscordAuth from '@src/hooks/useDiscord';
import useLazyFetch from '@src/hooks/useLazyFetch';
import useModalSteam from '@src/hooks/useModalSteam';
import useToaster from '@src/hooks/useToaster';
import { useTwitterData } from '@src/hooks/useTwitterData';

export interface IProvider {
  provider: string;
  accessToken: string;
}

// types.ts
export interface RedirectData {
  redirectUri: string;
}

export interface ApiResponse {
  portal: RedirectData;
  induction: RedirectData;
  threat: RedirectData;
  repository: RedirectData;
  dumb: RedirectData;
  xpsr: RedirectData;
}

const BindCallback: React.FC = () => {
  const env = process.env.REACT_APP_ENV;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const { id } = params;

  const { getTwitterAuthToken } = useTwitterData();
  const { fetchTokenDiscord, error: errorDiscord } = useDiscordAuth();
  const { setMessage } = useToaster();
  const { showModal } = useModalSteam();
  const [onBindAccount] = useLazyFetch({
    url: '/spa/user-profile/bind-external-account',
    method: 'PATCH'
  });

  const [onValidateSteamToken] = useLazyFetch({
    url: '/spa/user-profile/validate-steam',
    method: 'PUT'
  });

  const fetchRedirectUri = async () => {
    try {
      const response = await fetch(
        `https://staticassets.mythicprotocol.com/web/mythic-assets-fe/assets/json/product-config-data-${env}.json`
      );

      if (!response.ok && response.status !== 404) {
        setMessage({
          render: response.status.toString(),
          variant: 'error'
        });
        // throw new Error('Network response was not ok');
      }

      dispatch(getUserProfile());
      const json: ApiResponse = await response.json();

      if (state !== null) {
        const states = state.split(' ');
        states.forEach((s) => {
          const redirectUri = checkIfKeyExists(s, json);
          if (redirectUri) {
            // Use the redirectUri if it exists
            window.location.replace(redirectUri);
          } else {
            navigate('/');
          }
        });
      }
      navigate('/');
    } catch (error) {
      navigate('/');
    }
  };

  const checkIfKeyExists = (key: string, data: ApiResponse) => {
    if (key in data) {
      return (data as any)[key].redirectUri; // Access the value dynamically
    }
    return null;
  };

  const handleConnect = async (providerData: IProvider) => {
    if (providerData.provider !== '') {
      onBindAccount(
        {
          accessToken: providerData.accessToken,
          providerName: providerData.provider
        },
        async (_, error) => {
          await fetchRedirectUri();

          if (!error) {
            setMessage({
              render: `Successfully connected to ${providerData.provider}`,
              variant: 'success'
            });
          } else {
            navigate(`/`);
            if (error.status === 409) {
              showModal(error);
            } else {
              const errorMessage =
                error?.data.message ?? 'Something went wrong';
              const cleanedUpErrorMessage: string = errorMessage.replace(
                'Invalid Operation',
                ''
              );
              setMessage({
                render: cleanedUpErrorMessage || 'Something went wrong!',
                variant: 'error'
              });
            }
          }
        }
      );
    }
  };

  const handleSteamLogin = async () => {
    const token = searchParams.get('openid.identity');

    if (token) {
      const accessToken = token.replace(
        'https://steamcommunity.com/openid/id/',
        ''
      );
      onValidateSteamToken(
        {
          steamId: accessToken,
          steamToken: searchParams.toString()
        },
        async (_, error) => {
          if (!error) {
            // Use the access token for your desired purposes
            const bindData: IProvider = {
              provider: 'Steam',
              accessToken
            };
            handleConnect(bindData);
          } else {
            navigate(`/`);
            if (error.status === 409) {
              showModal(error);
            } else {
              const errorMessage =
                error?.data.message ?? 'Something went wrong';
              const cleanedUpErrorMessage: string = errorMessage.replace(
                'Invalid Operation',
                ''
              );
              setMessage({
                render: cleanedUpErrorMessage || 'Something went wrong!',
                variant: 'error'
              });
            }
          }
        }
      );
    }
  };

  const handleDiscordCallback = async () => {
    const callbackCode = searchParams.get('code');

    if (!callbackCode) {
      navigate('/register');
      return;
    }

    const tokenData = await fetchTokenDiscord(callbackCode);

    const bindData: IProvider = {
      provider: 'Discord',
      accessToken: tokenData
    };

    if (!errorDiscord && tokenData) {
      await handleConnect(bindData);
    }
  };

  const handleTwitterLogin = async () => {
    const callbackCode = searchParams.get('code');
    const redirectUri = `${window.location.origin}/bind/twitter/callback`;
    if (!callbackCode) {
      navigate('/register');
      return;
    }
    if (callbackCode) {
      const { data } = await getTwitterAuthToken(callbackCode, redirectUri);

      const bindData: IProvider = {
        provider: 'Twitter',
        accessToken: data.access_token
      };
      if (data) {
        handleConnect(bindData);
      }
    }
  };

  useEffect(() => {
    if (id === 'discord') {
      handleDiscordCallback();
    }

    if (id === 'twitter') {
      handleTwitterLogin();
    }

    if (id === 'steam') {
      handleSteamLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <SocialBindLoading title={id} />;
};

export default BindCallback;
