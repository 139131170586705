import React from 'react';

const LogoutIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1742_34703)">
      <mask
        id="mask0_1742_34703"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <rect x="0.5" y="0.5" width="25" height="25" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1742_34703)">
        <path
          d="M5.70833 22.375C5.13542 22.375 4.64497 22.171 4.23698 21.763C3.82899 21.355 3.625 20.8646 3.625 20.2917V5.70833C3.625 5.13542 3.82899 4.64497 4.23698 4.23698C4.64497 3.82899 5.13542 3.625 5.70833 3.625H13V5.70833H5.70833V20.2917H13V22.375H5.70833ZM17.1667 18.2083L15.7344 16.6979L18.3906 14.0417H9.875V11.9583H18.3906L15.7344 9.30208L17.1667 7.79167L22.375 13L17.1667 18.2083Z"
          fill="#F2F2F2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1742_34703">
        <rect
          width="25"
          height="25"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoutIcon;
