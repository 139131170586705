import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (params: { rememberMe?: boolean } | void, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/spa/User-Profile`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return { result, rememberMe: params?.rememberMe ? true : false };
    } catch (err) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response);
      }
      return rejectWithValue(err);
    }
  }
);
