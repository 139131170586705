import React from 'react';

const SecurityIcon: React.FC<{ color?: string }> = ({ color = '#E4E4E4' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4164_170418"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4164_170418)">
        <path
          d="M9 16.5C7.2625 16.0625 5.82825 15.0655 4.69725 13.509C3.56575 11.953 3 10.225 3 8.325V3.75L9 1.5L15 3.75V8.325C15 10.225 14.4345 11.953 13.3035 13.509C12.172 15.0655 10.7375 16.0625 9 16.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SecurityIcon;
