import useRequireAuth from '@src/hooks/useRequireAuth';
import React from 'react';
import { useLocation } from 'react-router-dom';
import FullPageLoading from '../atoms/LoadingScreen/FullPageLoading';

const AuthorizedPage: React.FC<{
  children?: React.ReactNode;
}> = ({ children = <></> }) => {
  const isLogin = useRequireAuth();
  const { pathname } = useLocation();
  const isRegisterOrLoginPage =
    ['/login', '/register'].findIndex((item) => item === pathname) >= 0;

  if (isLogin && isRegisterOrLoginPage) {
    return <FullPageLoading />;
  }
  return <>{children}</>;
};

export default AuthorizedPage;
