import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Pages from './pages';
import { store } from './store';
import './index.scss';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';
import '@jupiter/react-common-component/dist/global-styles/index.scss';

import 'regenerator-runtime/runtime';
import ModalV2Provider from './components/atoms/ModalV2/ModalV2Provider';
import OidcProvider from './components/atoms/Oidc/OidcProvider';
import Error from './components/atoms/Error/Error';
import { ErrorBoundary } from 'react-error-boundary';
import Toaster from './components/atoms/Toaster/Toaster';
import { datadogRum } from '@datadog/browser-rum';
import {
  getEnvironmentName,
  isLocalEnv,
  isProductionEnv,
  removeConsole
} from './common/utils/utils';

import './localization';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './common/constants/Auth.OidcConfig';
import { Web3Provider } from './domain/Web3/Config/Web3Provider';

export const App = () => {
  if (!isLocalEnv()) {
    datadogRum.init({
      applicationId: process.env.DATADOG_APP_ID ?? '',
      clientToken: process.env.DATADOG_CLIENT_TOKEN ?? '',
      site: 'datadoghq.com',
      service: 'account-react-v3',
      env: getEnvironmentName(),
      // Specify a version number to identify the deployed version of your application in Datadog     // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        /https:\/\/.*\.agatedev\.net/,
        /https:\/\/.*\.mythicprotocol\.com/,
        /https:\/\/.*\.mythicprotocol\.net/
      ]
    });
    datadogRum.startSessionReplayRecording();
  }

  if (isProductionEnv()) {
    removeConsole();
  }

  return (
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <BrowserRouter>
          <OidcProvider>
            <ModalV2Provider>
              <Toaster>
                <ErrorBoundary FallbackComponent={Error}>
                  <Suspense fallback={<FullPageLoading />}>
                    <Web3Provider>
                      <Pages />
                    </Web3Provider>
                  </Suspense>
                </ErrorBoundary>
              </Toaster>
            </ModalV2Provider>
          </OidcProvider>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
};
const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

export default root.render(<App />);
