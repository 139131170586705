import {
  Button,
  Input,
  Loading,
  Select
} from '@jupiter/react-common-component';
import ModalContent from '@src/components/atoms/ModalContent/ModalContent';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import Agreements from '../Agreements/Agreements';

import {
  AgreementContent,
  ChecklistIcon,
  ConfictionLogo
} from '../SignUp/SignUp.constant';

import styles from './OauthFormMetamask.module.scss';
import {
  HandleFieldError,
  disable3rdPartyEULA,
  isValidUsername
} from '@src/common/utils/utils';
import { iso3166 } from '@src/common/constants/IsoLists';

// todo: Split This Component

const SignupOauthFormMetamask: React.FC<{
  show: boolean;
  username: string;
  caseName: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitName: (
    submitedName: {
      firstname: string;
      lastname: string;
      username: string;
      country: string;
    },
    setAction: {
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
      setShowEmailForm: React.Dispatch<React.SetStateAction<boolean>>;
      setShowNameForm: React.Dispatch<React.SetStateAction<boolean>>;
      setShowComplete: React.Dispatch<React.SetStateAction<boolean>>;
      setErrApiResponse: React.Dispatch<React.SetStateAction<string>>;
    }
  ) => void;
  onSubmitEmail: (
    submitedEmail: string,
    setAction: {
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
      setShowVerify: React.Dispatch<React.SetStateAction<boolean>>;
      setShowEmailForm: React.Dispatch<React.SetStateAction<boolean>>;
      setShowNameForm: React.Dispatch<React.SetStateAction<boolean>>;
      setErrApiResponse: React.Dispatch<React.SetStateAction<string>>;
    }
  ) => void;
}> = ({
  show,
  caseName,
  onSubmitName: oSN,
  onSubmitEmail: oSE,
  username,
  setShow
}) => {
  const [showNameForm, setShowNameForm] = useState<boolean>(true);
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  // Name Form
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isEulaChecked, setIsEulaChecked] = useState<boolean>(false);
  const [country, setCountry] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);

  const [errApiResponse, setErrApiResponse] = useState<string>('');

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    firstName: false,
    lastName: false,
    userName: false,
    general: false,
    isEulaChecked: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>(
    {
      email: '',
      firstName: '',
      lastName: '',
      userName: '',
      general: '',
      isEulaChecked: ''
    }
  );

  const contentVariants = {
    hidden: {
      x: 150,
      opacity: 0,
      transition: { duration: 1.2 }
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, delay: 0.5 }
    },
    exit: {
      opacity: 0,
      x: -300,
      transition: { duration: 1.2 }
    }
  };

  const resetForm = () => {
    setFieldErrors({
      email: false,
      firstName: false,
      lastName: false,
      general: false,
      isEulaChecked: false
    });
  };

  const setFieldErrorWithMessage = (fieldName: string, errMessage: string) => {
    HandleFieldError(fieldName, errMessage, setFieldErrors, setErrorMessages);
  };

  const onSubmitName = () => {
    resetForm();
    if (!firstName) {
      setFieldErrorWithMessage('firstName', 'This field is required');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, firstName: '' }));
    }

    if (!lastName) {
      setFieldErrorWithMessage('lastName', 'This field is required');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, lastName: '' }));
    }

    if (!userName) {
      setFieldErrorWithMessage('userName', 'This field is required');
      return;
    } else if (!isValidUsername(userName)) {
      setFieldErrorWithMessage(
        'userName',
        'Please input letters (a-z, A-Z), numbers (0-9) and certain special characters (*, +, -, @, _, .) only'
      );
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, userName: '' }));
    }

    if (!country) {
      setFieldErrorWithMessage('country', 'This field is required');
      return;
    } else if (!iso3166.find((it) => it.alpha2 == country)) {
      setFieldErrorWithMessage('country', 'Please select country');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, country: '' }));
    }
    oSN(
      {
        firstname: firstName,
        lastname: lastName,
        username: userName,
        country: country
      },
      {
        setIsLoading,
        setShowEmailForm,
        setShowNameForm,
        setShowComplete,
        setErrApiResponse
      }
    );
  };

  const onSubmitEmail = async () => {
    resetForm();
    const emailRegex = /^[a-zA-Z0-9._-]{1,60}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      setFieldErrorWithMessage('email', 'This field is required');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, email: '' }));
    }

    if (!emailRegex.test(email)) {
      setFieldErrorWithMessage('email', 'Please enter a valid email');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, email: '' }));
    }

    if (!disable3rdPartyEULA && !isEulaChecked) {
      setFieldErrorWithMessage(
        'isEulaChecked',
        'You must check this before continue'
      );
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, isEulaChecked: '' }));
    }

    oSE(email, {
      setIsLoading,
      setShowVerify,
      setShowEmailForm,
      setShowNameForm,
      setErrApiResponse
    });
  };

  useEffect(() => {
    if (caseName === 'SubmitName') {
      setShowNameForm(true);
    }
  }, [caseName]);

  const onCloseModal = () => {
    setShow(false);
    history.replaceState({}, document.title);
  };

  return (
    <>
      <ModalContent
        show={show}
        onClose={() => {
          showVerify && onCloseModal();
        }}
        noTitle={!showVerify}
      >
        <AnimatePresence exitBeforeEnter>
          {showNameForm && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="nameForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#e4e4e4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Hi, {username}</p>
                <p className={styles['subtitle']}>
                  Please enter your full name
                </p>
              </div>
              <div className={styles['desc-container']}>
                <p>
                  For security reasons, we require you to put your full name.
                </p>
              </div>
              <div className={styles['input-container']}>
                <Input
                  label="First Name"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  error={errorMessages.firstName || ''}
                />
                <Input
                  label="Last Name"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  error={errorMessages.lastName || ''}
                />
                <Input
                  label="Username"
                  value={userName}
                  name="userName"
                  onChange={(e) => setUserName(e.currentTarget.value)}
                  error={errorMessages.userName || ''}
                />
                <div className={styles['select-wrapper']}>
                  <Select
                    name="country"
                    label="Select your Region"
                    variant="dark-theme"
                    value={country}
                    isExpanded={isCountryOpen}
                    setIsExpanded={setIsCountryOpen}
                    options={iso3166
                      .filter((item) => item.alpha2 !== null)
                      .map((item) => ({
                        text: item.name,
                        value: item.alpha2 ?? ''
                      }))}
                    withFilter
                    // borderColor='e4e4e4'
                    error={errorMessages.country || ''}
                    onChange={(e) => {
                      setCountry(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div></div>
              <div className={styles['oauth-container__button']}>
                <Button
                  isLoading={false}
                  label="Continue"
                  variant="default-magenta"
                  size="responsive"
                  onClick={() => {
                    onSubmitName();
                  }}
                />
              </div>
            </motion.div>
          )}

          {isLoading && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key="loading"
              className={styles['oauth-loading__container']}
            >
              <Loading size={60} />
            </motion.div>
          )}

          {showEmailForm && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="emailForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#e4e4e4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Just a few more steps</p>
                <p className={styles['subtitle']}>
                  Please enter your active email
                </p>
              </div>
              {errApiResponse && errApiResponse !== '' && (
                <p className={styles['danger-text__medium']}>
                  {errApiResponse}
                </p>
              )}
              <div className={styles['desc-container']}>
                <p>
                  For security reasons, we require you to put your active email.
                </p>
              </div>
              <div className={styles['input-container']}>
                <Input
                  label="Enter your email"
                  value={email}
                  name="email"
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                    setErrApiResponse('');
                  }}
                  error={errorMessages.email || ''}
                />
              </div>
              {!disable3rdPartyEULA ? (
                <Agreements
                  isEulaChecked={isEulaChecked}
                  setIsEulaChecked={setIsEulaChecked}
                  eulaErrMessage={errorMessages.isEulaChecked}
                  setShowAgreements={setShowPrivacyPolicy}
                  isEulaErr={fieldErrors.isEulaChecked}
                />
              ) : (
                <></>
              )}
              <div className={styles['oauth-container__button']}>
                <Button
                  isLoading={isLoading}
                  label="Continue"
                  variant="default-magenta"
                  size="responsive"
                  onClick={() => {
                    onSubmitEmail();
                  }}
                />
              </div>
            </motion.div>
          )}

          {showVerify && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="verifyForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#e4e4e4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Please verify your email</p>
              </div>
              <div className={styles['desc-container']}>
                <p>
                  A verification link has been sent to <span>{email}</span>{' '}
                </p>
                <p>Please check your email now.</p>
              </div>
            </motion.div>
          )}

          {showComplete && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="completeForm"
            >
              <div className={styles['logo-container']}>
                <ChecklistIcon />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Congrats!</p>
                <p className={styles['subtitle']}>
                  Your account registration was <span>successful!</span>
                </p>
              </div>
              <div className={styles['loading-container']}>
                <Loading size={26} />
              </div>
              <div className={styles['redirect-container']}>
                <p>Please wait, you will automatically be redirected</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalContent>

      <ModalContent
        title="Privacy Policy"
        show={showPrivacyPolicy}
        onClose={() => {
          setShowEmailForm(true);
          setShowPrivacyPolicy(!showPrivacyPolicy);
        }}
      >
        <AgreementContent />
      </ModalContent>
    </>
  );
};

export default SignupOauthFormMetamask;
