import axios, { AxiosResponse } from 'axios';

export const TWITTER_STATE = 'twitter-state';
const TWITTER_CODE_CHALLENGE = 'challenge';
const TWITTER_AUTH_URL = 'https://twitter.com/i/oauth2/authorize';
const TWITTER_SCOPE = ['tweet.read', 'users.read', 'offline.access'].join(' ');

export const useTwitterData = () => {
  const clientId = process.env.OAUTH_TWITTER_CLIENT_ID;
  // const clientSecret = process.env.OAUTH_TWITTER_CLIENT_SECRET;

  const getTwitterOAuthURL = (redirectUri: string) => {
    return getURLWithQueryParams(TWITTER_AUTH_URL, {
      response_type: 'code',
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: TWITTER_SCOPE,
      state: TWITTER_STATE,

      code_challenge: TWITTER_CODE_CHALLENGE,
      code_challenge_method: 'plain'
    });
  };

  const getTwitterAuthToken = async (
    callbackCode: string,
    redirectUri: string,
    onError?: (arg0: any) => void
  ) => {
    try {
      const formData = {
        code: callbackCode,
        redirect_uri: redirectUri
      };
      const { data, status } = await axios.post(
        '/spa/external/get-twitter-access-token',
        formData
      );

      if (status === 200) {
        return {
          data
        };
      }
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  };

  const getUserTwitterData = async (
    userId = 'me',
    authorizationCode: string,
    onSuccess: (arg0: AxiosResponse) => void,
    onError: (arg0: any) => void
  ) => {
    try {
      // &redirect_uri=https://localhost:8080/auth/twitter/callback
      const config = {
        'Content-type': 'application/json',
        headers: { Authorization: `Bearer ${authorizationCode}` }
      };

      const response = await axios.get(
        `api/twitter/2/users/${userId}?user.fields=created_at,description,entities,id,location,name,pinned_tweet_id,profile_image_url,protected,public_metrics,url,username,verified,verified_type,withheld`,
        config
      );
      if (response.status === 200) {
        onSuccess(response.data);
      } else {
        onError(response);
      }
    } catch (e) {
      onError(e);
    }
  };

  const getURLWithQueryParams = (
    baseUrl: string,
    params: Record<string, any>
  ) => {
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return `${baseUrl}?${query}`;
  };

  return {
    getTwitterOAuthURL,
    getTwitterAuthToken,
    getUserTwitterData,
    getURLWithQueryParams
  };
};
