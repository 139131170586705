import ResetPasswordSuccess from '@src/components/molecules/ResetPassword/ResetPasswordSucces';
import React from 'react';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordSuccessPage = () => {
  return (
    <div className={styles['wrapper']}>
      <ResetPasswordSuccess />
    </div>
  );
};

export default ResetPasswordSuccessPage;
