import { createSlice } from '@reduxjs/toolkit';
import { IUserFeedbackState } from './UserFeedback.type';
import { RootState } from '@src/store';

const initialState: IUserFeedbackState = {
  status: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ''
  },
  savedForm: {
    platform: '',
    topic: '',
    message: '',
    captchaToken: ''
  },
  _api: undefined
};

const userFeedbackSlice = createSlice({
  name: 'userFeedback',
  initialState,
  reducers: {
    saveFeedbackRequest: (state, action) => {
      state.status = {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: ''
      };
      state.savedForm = action.payload.value;
      state._api = action.payload._api;
    },
    saveFeedbackSuccess: (state) => {
      state.status = {
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: ''
      };
    },
    saveFeedbackFailure: (state, action) => {
      state.status = {
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload
      };
    },
    clearUserfeedbackState: (state) => {
      (state.status = {
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: ''
      }),
        (state.savedForm = {
          platform: '',
          topic: '',
          message: '',
          captchaToken: ''
        }),
        (state._api = undefined);
    }
  }
});

export const {
  saveFeedbackRequest,
  saveFeedbackSuccess,
  saveFeedbackFailure,
  clearUserfeedbackState
} = userFeedbackSlice.actions;

export const selectUserFeedback = (state: RootState) => state.userFeedback;

export default userFeedbackSlice.reducer;
