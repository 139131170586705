import { createSlice } from '@reduxjs/toolkit';
import { IModalSteamState } from './ModalSteam.type';

const initialState: IModalSteamState = {
  isShow: false,
  titleError: '',
  message: '',
  providerUserName: ''
};

export const ModalSteamSlice = createSlice({
  name: 'ModalSteam',
  initialState,
  reducers: {
    addData: (state, action) => {
      state.isShow = action.payload.isShow;
      state.message = action.payload.message;
      state.providerUserName = action.payload.providerUserName;
      state.titleError = action.payload.titleError;
    },

    onClose: (state) => {
      state.isShow = false;
    }
  }
});

export const { addData, onClose } = ModalSteamSlice.actions;

export default ModalSteamSlice.reducer;
