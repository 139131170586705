import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUi } from './ui.type';

const initialState: IUi = {
  showErrorModal: false,
  errorMessage: '',
  isLoading: false,
  isOpenSidebar: false
};

export const counterSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.showErrorModal = true;
      state.errorMessage = action.payload;
    },
    setIsOpenSidebar: (state, action: PayloadAction<boolean>) => {
      state.isOpenSidebar = action.payload;
    }
  }
});

export const { setIsOpenSidebar } = counterSlice.actions;

export default counterSlice.reducer;
