import React from 'react';

const ErrorIcon: React.FC<{ color?: string }> = ({ color = '#BF100E' }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2614_33818"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <rect width={16} height={16} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2614_33818)">
      <path
        d="M8.00004 11.3334C8.18893 11.3334 8.34726 11.2695 8.47504 11.1417C8.60282 11.0139 8.66671 10.8556 8.66671 10.6667C8.66671 10.4778 8.60282 10.3195 8.47504 10.1917C8.34726 10.0639 8.18893 10 8.00004 10C7.81115 10 7.65282 10.0639 7.52504 10.1917C7.39726 10.3195 7.33337 10.4778 7.33337 10.6667C7.33337 10.8556 7.39726 11.0139 7.52504 11.1417C7.65282 11.2695 7.81115 11.3334 8.00004 11.3334ZM7.33337 8.66671H8.66671V4.66671H7.33337V8.66671ZM8.00004 14.6667C7.07782 14.6667 6.21115 14.4917 5.40004 14.1417C4.58893 13.7917 3.88337 13.3167 3.28337 12.7167C2.68337 12.1167 2.20837 11.4112 1.85837 10.6C1.50837 9.78893 1.33337 8.92226 1.33337 8.00004C1.33337 7.07782 1.50837 6.21115 1.85837 5.40004C2.20837 4.58893 2.68337 3.88337 3.28337 3.28337C3.88337 2.68337 4.58893 2.20837 5.40004 1.85837C6.21115 1.50837 7.07782 1.33337 8.00004 1.33337C8.92226 1.33337 9.78893 1.50837 10.6 1.85837C11.4112 2.20837 12.1167 2.68337 12.7167 3.28337C13.3167 3.88337 13.7917 4.58893 14.1417 5.40004C14.4917 6.21115 14.6667 7.07782 14.6667 8.00004C14.6667 8.92226 14.4917 9.78893 14.1417 10.6C13.7917 11.4112 13.3167 12.1167 12.7167 12.7167C12.1167 13.3167 11.4112 13.7917 10.6 14.1417C9.78893 14.4917 8.92226 14.6667 8.00004 14.6667ZM8.00004 13.3334C9.48893 13.3334 10.75 12.8167 11.7834 11.7834C12.8167 10.75 13.3334 9.48893 13.3334 8.00004C13.3334 6.51115 12.8167 5.25004 11.7834 4.21671C10.75 3.18337 9.48893 2.66671 8.00004 2.66671C6.51115 2.66671 5.25004 3.18337 4.21671 4.21671C3.18337 5.25004 2.66671 6.51115 2.66671 8.00004C2.66671 9.48893 3.18337 10.75 4.21671 11.7834C5.25004 12.8167 6.51115 13.3334 8.00004 13.3334Z"
        fill={color}
      />
    </g>
  </svg>
);

export default ErrorIcon;
