import {
  Button,
  DiscordButton,
  FacebookButton,
  GoogleButton,
  MetamaskButton,
  SteamButton,
  XButton
} from '@jupiter/react-common-component';
import React from 'react';
import styles from './SignInMoreOption.module.scss';
import { enableFacebookOauth } from '@src/common/utils/utils';
import { useTranslation } from 'react-i18next';
export interface ISignInMoreOption {
  onSignUp: () => void;
  onClickButton: (
    e:
      | 'google'
      | 'mythic'
      | 'twitter'
      | 'metamask'
      | 'steam'
      | 'discord'
      | 'facebook'
  ) => void;
}
const SignInMoreOption: React.FC<ISignInMoreOption> = ({
  onSignUp,
  onClickButton
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['wrapper']} data-testid="SignInMoreOption">
      <div className={styles['body-container']}>
        <p className={styles['title']}>{t('general.signIn')}</p>
        <div className={styles['button-list-container']}>
          <div className={styles['button-container']}>
            <Button
              isSocialMedia
              size="responsive"
              onClick={() => {
                onClickButton('mythic');
              }}
              isLoading={false}
              label="Confiction account"
              variant="mythic"
              prefixIcon={
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.4037 8.84621L21.2548 6.7662C21.9286 8.31557 22.2234 10.0032 22.1146 11.6893C22.0059 13.3753 21.4967 15.0112 20.6294 16.4611C17.6168 21.4954 11.0715 23.1421 6.03768 20.1289C1.0039 17.1156 -0.642129 10.5698 2.37051 5.53691C5.38315 0.503984 11.9285 -1.1413 16.9622 1.87055C17.4962 2.18988 18.0013 2.55527 18.4717 2.96255L15.6137 5.04811C15.484 4.95867 15.3529 4.8727 15.2143 4.79088C13.764 3.92235 12.0553 3.58929 10.385 3.84958C8.71474 4.10987 7.18842 4.94706 6.07123 6.21569C4.95405 7.48432 4.3166 9.10423 4.26965 10.794C4.2227 12.4837 4.76919 14.1365 5.8142 15.4652C6.85921 16.7939 8.33669 17.7146 9.98995 18.0672C11.6432 18.4199 13.3678 18.1822 14.864 17.3956C16.3603 16.6089 17.5336 15.3229 18.1803 13.7611C18.8269 12.1993 18.906 10.4603 18.4037 8.84621ZM15.1072 15.1228C16.1059 14.2487 16.7531 13.0418 16.9288 11.7263H6.07011C6.24577 13.0418 6.89305 14.2487 7.89167 15.1228C8.89028 15.9969 10.1723 16.4787 11.4995 16.4787C12.8266 16.4787 14.1086 15.9969 15.1072 15.1228Z"
                    fill="#E2E2E2"
                  />
                </svg>
              }
            />
          </div>
          <div className={styles['button-container']}>
            <GoogleButton
              size="responsive"
              onClick={() => {
                onClickButton('google');
              }}
            />
          </div>
          <div className={styles['button-container']}>
            <MetamaskButton
              size="responsive"
              onClick={() => {
                onClickButton('metamask');
              }}
            />
          </div>
          <div className={styles['button-container']}>
            <DiscordButton
              size="responsive"
              onClick={() => {
                onClickButton('discord');
              }}
            />
          </div>
          <div className={styles['button-container']}>
            <XButton
              size="responsive"
              onClick={() => {
                onClickButton('twitter');
              }}
            />
          </div>
          {enableFacebookOauth && (
            <FacebookButton
              size="responsive"
              onClick={() => {
                onClickButton('facebook');
              }}
            />
          )}
          <SteamButton
            size="responsive"
            onClick={() => {
              onClickButton('steam');
            }}
          />
        </div>
      </div>

      <div className={styles['noaccount']}>
        <p>{t('signin.form.dontHave')} </p>
        <a onClick={onSignUp}>{t('general.signUp')}</a>
      </div>
    </div>
  );
};

export default SignInMoreOption;
