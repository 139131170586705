import useLazyFetch from './useLazyFetch';
import { AxiosResponse } from 'axios';
import { ethers } from 'ethers';

const useEthereum = () => {
  const [getNonce] = useLazyFetch({
    url: '/spa/wallet/nonce',
    method: 'GET',
    credentials: 'include'
  });

  const [refreshNonce] = useLazyFetch({
    url: '/spa/user-wallet/refresh-nonce',
    method: 'PATCH'
  });

  const getAccounts = (
    cb: (error: unknown, result: string | null | undefined) => void
  ) => {
    window.ethereum
      .request({
        method: 'eth_accounts',
        params: []
      })
      .then((result) => {
        cb(null, result);
      })
      .catch((err) => {
        cb(err, null);
      });
  };

  const requestAccounts = (
    cb: (error: unknown, result: string | null | undefined) => void
  ) => {
    window.ethereum
      .request({
        method: 'eth_requestAccounts',
        params: []
      })
      .then((result) => {
        cb(null, result);
      })
      .catch((err) => {
        cb(err, null);
      });
  };

  const getSignature = (
    params: { nonce: string; walletAddress: string },
    cb: (error: unknown, result: string | null | undefined) => void
  ) => {
    const nonce = params.nonce;
    const messageToSign = `Need to sign nonce: ${nonce} for binding wallet to the Mythic Account.`;
    (
      window.ethereum as unknown as {
        request: (props: { method: string; params: string[] }) => Promise<null>;
      }
    )
      .request({
        method: 'personal_sign',
        params: [messageToSign, params.walletAddress]
      })
      .then((result) => {
        cb(null, result);
      })
      .catch((err) => {
        cb(err, null);
      });
  };

  const onGetSignature = (
    nonce: string,
    walletAddress: string,
    cb?: (
      err: unknown,
      result: {
        walletAddress: string;
        signature?: string | null;
        nonce?: string | null;
      } | null
    ) => void
  ) => {
    return getSignature(
      {
        nonce,
        walletAddress
      },
      (err, result) => {
        cb?.(err, { walletAddress, signature: result, nonce: nonce });
      }
    );
  };

  const handleGetNonce = (
    walletAddress: string,
    config: { refreshNonce: boolean },
    success?: AxiosResponse,
    error?: AxiosResponse,
    cb?: (
      err: unknown,
      result: {
        walletAddress: string;
        signature?: string | null;
        nonce?: string | null;
      } | null
    ) => void
  ) => {
    if (!error) {
      if (config.refreshNonce) {
        refreshNonce({}, (refresh, errRefresh) => {
          if (!errRefresh) {
            onGetSignature(refresh?.data.data.nonce, walletAddress, cb);
          } else {
            cb?.(errRefresh, null);
          }
        });
      } else {
        onGetSignature(success?.data.data.nonce, walletAddress, cb);
      }
    } else {
      cb?.(error, null);
    }
  };

  const connectMetamask = (
    config: { refreshNonce: boolean },
    cb: (
      err: unknown,
      result: {
        walletAddress: string;
        signature?: string | null;
        nonce?: string | null;
      } | null
    ) => void
  ) => {
    if (window.ethereum?.isMetaMask !== undefined) {
      getAccounts((err, accounts) => {
        if (!err) {
          if (accounts != null && accounts.length > 0) {
            getNonce({ walletAddress: accounts[0] }, (success, error) =>
              handleGetNonce(accounts[0], config, success, error, cb)
            );
          } else {
            requestAccounts((err, res) => {
              if (!err) {
                if (res != null && res.length > 0) {
                  getNonce({ walletAddress: res[0] }, (success, error) =>
                    handleGetNonce(res[0], config, success, error, cb)
                  );
                }
              } else {
                cb(err, null);
              }
            });
          }
        } else {
          cb(err, null);
        }
      });
    } else {
      alert('Please install MetaMask first!');
      window.open('https://metamask.io/download/');
      return;
    }
  };

  const getBalance = (
    walletAddress: string,
    cb: (err: unknown, success?: string | null) => void
  ) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.ethereum,
        'any'
      );
      requestAccounts((err) => {
        if (!err) {
          provider
            .getBalance(walletAddress ?? '')
            .then((result) => {
              const balanceInEth = ethers.utils.formatEther(result);
              cb(null, balanceInEth);
            })
            .catch((err) => {
              cb(err, null);
            });
        } else {
          cb(err, null);
        }
      });
    }
  };

  return {
    getAccounts,
    requestAccounts,
    getSignature,
    getBalance,
    connectMetamask
  };
};

export default useEthereum;
