export const RETURN_URI_KEY_STORAGE = 'returnUri';
export const RETURN_URL_KEY_PARAM = 'returnUrl';
export const BACK_TO_PORTAL_KEY_PARAM = 'back_url';
export const BACK_TO_PORTAL_KEY_STORAGE = 'backUrl';
export const EMAILVERIFICATION_SENT_KEY_STORAGE = 'emailVerificationSent';
export const EMAILVERIFICATION_LAST_SENT_KEY_STORAGE = 'lastEmailVerifSent';
export const FORGOT_PASSWORD_EMAIL_SENT_KEY_STORAGE = 'forgotPasswordEmailSent';
export const FORGOT_PASSWORD_LAST_EMAIL_SENT_KEY_STORAGE =
  'lastforgotPasswordEmailSent';

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*.`/\\-_+=|~;:'?])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
