import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@src/store';

export interface ISlider {
  page: number;
  direction: number;
  isPlay: boolean;
}

const initialState: ISlider = {
  page: 0,
  direction: 0,
  isPlay: true
};

export const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    setPage: (state: ISlider, action) => {
      state.page = action.payload.page;
      state.direction = action.payload.direction;
    },
    setIsPlay: (state: ISlider, action) => {
      state.isPlay = action.payload.isPlay;
    }
  }
});

export const { setPage, setIsPlay } = sliderSlice.actions;

export const selectSlider = (state: RootState) => state.slider;

export default sliderSlice.reducer;
