import { Toaster as Toastr } from '@jupiter/react-common-component';
import { AppDispatch, RootState } from '@src/store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import Error from '../Error/Error';
import { setToaster } from '@src/domain/toaster/toaster.reducer';

const Toaster: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { toaster: toasterState } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();

  const closeToaster = () => {
    dispatch(setToaster({ ...toasterState, render: '', show: false }));
  };

  return (
    <>
      {children}
      <ErrorBoundary FallbackComponent={Error}>
        <Toastr
          render={() => toasterState.render}
          position={toasterState.position ?? {}}
          show={toasterState.show ?? false}
          timeout={toasterState.timeout ?? 3000}
          variant={toasterState.variant}
          setShow={(result) => {
            if (!result) {
              closeToaster();
            }
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default Toaster;
