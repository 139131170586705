import React, { FC, ReactElement, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from './routes';
import AuthorizedPage from '@src/components/hoc/AuthorizedPage';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';

export interface RouteConfig {
  Component: FC | React.LazyExoticComponent<React.ComponentType<any>>;
  requireAuth?: boolean;
  isLazy?: boolean;
  props?: any;
  path: string;
  key?: string;
  name?: string;
  index?: boolean;
  lazy?: boolean;
  routes?: RouteConfig[];
  // any other route properties you may have...
}

const renderRoute = (route: RouteConfig): ReactElement => {
  const { requireAuth, isLazy, props } = route;

  const renderRoutes = (routes: RouteConfig[]): ReactElement[] | null => {
    return routes ? routes.map(renderRoute) : null;
  };
  const Component = route.Component as unknown as () => JSX.Element;

  const routeElement = requireAuth ? (
    <AuthorizedPage>
      <Component {...props} />
    </AuthorizedPage>
  ) : (
    <Component {...props} />
  );

  const routeComponent = isLazy ? (
    <Suspense fallback={<FullPageLoading />}>{routeElement}</Suspense>
  ) : (
    routeElement
  );

  return (
    <Route path={route.path} key={route.name} element={routeComponent}>
      {route.routes && renderRoutes(route.routes)}
    </Route>
  );
};

const Pages: FC = () => {
  return <Routes>{routes.map(renderRoute)}</Routes>;
};

export default Pages;
