import React from 'react';

import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectKitProvider } from 'connectkit';

import { configDefault } from './Config';

const queryClient = new QueryClient();

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={configDefault}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider
          debugMode
          customTheme={{
            '--ck-border-radius': 42,
            '--ck-font-family': 'Barlow Condensed, sans-serif',
            '--ck-accent-color': '#00D54B',
            '--ck-accent-text-color': '#ffffff'
          }}
          theme="auto"
          mode="dark"
        >
          {children}
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
