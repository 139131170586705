import React from 'react';
import styles from './ProfileAlert.module.scss';

const ProfileAlert = ({ text }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['icon-wrapper']}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9C6.17 9 6.3126 8.9424 6.4278 8.8272C6.543 8.712 6.6004 8.5696 6.6 8.4C6.6 8.23 6.5424 8.0874 6.4272 7.9722C6.312 7.857 6.1696 7.7996 6 7.8C5.83 7.8 5.6874 7.8576 5.5722 7.9728C5.457 8.088 5.3996 8.2304 5.4 8.4C5.4 8.57 5.4576 8.7126 5.5728 8.8278C5.688 8.943 5.8304 9.0004 6 9ZM5.4 6.6H6.6V3H5.4V6.6ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.212 2.295 10.7846 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.158 7.61 0.0004 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.788 2.93 1.2154 2.295 1.755 1.755C2.295 1.215 2.93 0.7876 3.66 0.4728C4.39 0.158 5.17 0.0004 6 0C6.83 0 7.61 0.1576 8.34 0.4728C9.07 0.788 9.705 1.2154 10.245 1.755C10.785 2.295 11.2126 2.93 11.5278 3.66C11.843 4.39 12.0004 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.212 9.07 10.7846 9.705 10.245 10.245C9.705 10.785 9.07 11.2126 8.34 11.5278C7.61 11.843 6.83 12.0004 6 12Z"
            fill="#C0862C"
          />
        </svg>
      </div>
      <div className={styles['text-wrapper']}>{text}</div>
    </div>
  );
};

export default ProfileAlert;
