import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ResetPassword from '@src/components/molecules/ResetPassword/ResetPassword';

import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  useEffect(() => {
    if (!token || !email) {
      navigate('/invalid-token', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email]);

  return (
    <div className={styles['wrapper']}>
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;
