import React, { Fragment, useState, useEffect } from 'react';
import {
  Input,
  Button,
  GoogleButton,
  MythicButton,
  MetamaskButton,
  DiscordButton,
  XButton,
  FacebookButton,
  SteamButton,
  Select
} from '@jupiter/react-common-component';
import styles from './SignUp.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import LoginOptions from '../LoginOptions/LoginOptions';
import SignupOauthForm from '../OauthForm/OauthForm';
import Agreements from '../Agreements/Agreements';
import { enableFacebookOauth } from '@src/common/utils/utils';
import { useTranslation } from 'react-i18next';
import { logEvent } from '@src/domain/Analytic/Analythic';
import { AnalyticsEvent } from '@src/domain/Analytic/Analythic.type';
import ModalPrivacyPolicy from '@src/pages/PrivacyPolicy/Modal/ModalPrivacyPolicy';
import { iso3166 } from '@src/common/constants/IsoLists';

type ISignUp = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isEmailErr: boolean;
  emailErrMess: string;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  isPassErr: boolean;
  passErrMess: string;
  onSignUp: () => void;
  onSignIn: () => void;
  onDiscordClick: () => void;
  onGoogleClick: () => void;
  onMetamaskClick: () => void;
  onFacebookClick: () => void;
  onTwitterClick: () => void;
  onSteamClick: () => void;
  isErr: boolean;
  errMessage: string;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  isFirstNameErr: boolean;
  firstNameErrMessage: string;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  isLastNameErr: boolean;
  // setIsLastNameErr: React.Dispatch<React.SetStateAction<boolean>>;
  lastNameErrMessage: string;
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  isConfirmPasswordErr: boolean;
  // setIsConfirmPasswordErr: React.Dispatch<React.SetStateAction<boolean>>;
  confirmPasswordErrMessage: string;
  isEulaChecked: boolean;
  setIsEulaChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isEulaErr: boolean;
  setIsEulaErr: React.Dispatch<React.SetStateAction<boolean>>;
  eulaErrMessage: string;
  recaptchaRef: React.LegacyRef<ReCAPTCHA>;
  isSigningUp: boolean;
  popupModal: string;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  isRegionErr: boolean;
  regionErrMess: string;
};

const SignUp: React.FC<ISignUp> = ({
  email,
  setEmail,
  isEmailErr,
  emailErrMess,
  password,
  setPassword,
  isPassErr,
  passErrMess,
  onSignUp,
  onSignIn,
  onDiscordClick,
  onGoogleClick,
  onMetamaskClick,
  onFacebookClick,
  onTwitterClick,
  onSteamClick,
  isErr,
  errMessage,
  firstName,
  setFirstName,
  isFirstNameErr,
  firstNameErrMessage,
  lastName,
  setLastName,
  isLastNameErr,
  lastNameErrMessage,
  confirmPassword,
  setConfirmPassword,
  isConfirmPasswordErr,
  confirmPasswordErrMessage,
  isEulaChecked,
  setIsEulaChecked,
  eulaErrMessage,
  isEulaErr,
  recaptchaRef,
  isSigningUp,
  popupModal,
  region,
  setRegion,
  isRegionErr,
  regionErrMess
}) => {
  const { t } = useTranslation();
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const [showRegisterForm, setShowRegisterForm] = useState<boolean>(false);
  const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);

  const [nameLength, setNameLength] = useState<{ [key: string]: number }>({
    firstName: 0,
    lastName: 0
  });
  const siteKey = process.env.REACT_INVISIBLE_RECAPTHA_SITE_KEY;
  const nameMaxLength = 50;

  useEffect(() => {
    if (firstName === '' && lastName === '') {
      return;
    }
    setNameLength({
      ...nameLength,
      lastName: nameMaxLength - firstName.length,
      firstName: nameMaxLength - lastName.length
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, nameMaxLength]);

  useEffect(() => {
    if (popupModal && popupModal !== '') {
      setShowRegisterForm(true);
      setShowMoreOptions(true);
    }
  }, [popupModal]);

  return (
    <Fragment>
      <div
        className={`${styles['wrapper']} ${
          showMoreOptions ? styles['wrapper-more-option'] : ''
        }`}
        data-testid="sign-up"
      >
        <div className={styles['container']}>
          {!showMoreOptions && (
            <Fragment>
              <div className={styles['signup-container']}>
                <div className={styles['content-wrapper']}>
                  <p className={styles['title']}>{t('signup.form.title')}</p>
                  <form className={styles['input-wrapper']}>
                    <div className={styles['field-input']}>
                      <div className={styles['input-container']}>
                        <Input
                          value={firstName}
                          label={t('signup.form.firstName')}
                          maxLength={nameLength['firstName']}
                          name="firstName"
                          onChange={(e) => setFirstName(e.currentTarget.value)}
                          error={isFirstNameErr ? firstNameErrMessage : ''}
                        />
                        <Input
                          value={lastName}
                          label={t('signup.form.lastName')}
                          maxLength={nameLength['lastName']}
                          name="lastName"
                          error={isLastNameErr ? lastNameErrMessage : ''}
                          onChange={(e) => setLastName(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className={styles['field-input']}>
                      <div className={styles['input']}>
                        <Input
                          value={email}
                          label={t('signup.form.email')}
                          name="email"
                          type="email"
                          error={isEmailErr ? emailErrMess : ''}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className={styles['field-input']}>
                      <div className={styles['input']}>
                        <Input
                          value={password}
                          label={t('signup.form.password')}
                          name="password"
                          type="password"
                          error={isPassErr ? passErrMess : ''}
                          onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className={styles['field-input']}>
                      <div className={styles['input']}>
                        <Input
                          value={confirmPassword}
                          label={t('signup.form.confirmPassword')}
                          name="confirmPassword"
                          type="password"
                          onChange={(e) =>
                            setConfirmPassword(e.currentTarget.value)
                          }
                          error={
                            isConfirmPasswordErr
                              ? confirmPasswordErrMessage
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className={styles['field-input']}>
                      <div className={styles['select-wrapper']}>
                        <Select
                          name="country"
                          label="Select your Region"
                          variant="dark-theme"
                          value={region}
                          isExpanded={isCountryOpen}
                          setIsExpanded={setIsCountryOpen}
                          options={iso3166
                            .filter((item) => item.alpha2 !== null)
                            .map((item) => ({
                              text: item.name,
                              value: item.alpha2 ?? ''
                            }))}
                          withFilter
                          onChange={(e) => {
                            setRegion(e.currentTarget.value);
                          }}
                          error={isRegionErr && !region ? regionErrMess : ''}
                        />
                      </div>
                    </div>
                    <Agreements
                      isEulaChecked={isEulaChecked}
                      setIsEulaChecked={setIsEulaChecked}
                      eulaErrMessage={eulaErrMessage}
                      setShowAgreements={setShowPrivacyPolicy}
                      className={styles['field-width']}
                      isEulaErr={isEulaErr}
                    />
                    <div className={styles['captcha-container']}>
                      <ReCAPTCHA
                        sitekey={siteKey as string}
                        ref={recaptchaRef}
                        size="invisible"
                        className={styles['recaptcha']}
                      />
                    </div>
                    {isErr && (
                      <p className={styles['input-err']}>{errMessage}</p>
                    )}
                    <div className={styles['button-wrapper']}>
                      <Button
                        isLoading={isSigningUp}
                        label={t('general.signUp')}
                        size="responsive"
                        onClick={onSignUp}
                        variant="default-magenta"
                      />
                    </div>
                  </form>
                  <div className={styles['separator-container']}>
                    <hr />
                    <span className={styles['separator-caption']}>
                      {t('general.or')}
                    </span>
                    <hr />
                  </div>
                  <div className={styles['option-container']}>
                    <LoginOptions
                      onBtnGoogleClick={onGoogleClick}
                      onBtnSteamClick={onSteamClick}
                      onBtnTwitterClick={onTwitterClick}
                    />
                    <div className={styles['more-option__container']}>
                      <a
                        className={styles['more-option']}
                        onClick={() => setShowMoreOptions(!showMoreOptions)}
                      >
                        {t('general.moreOptions')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {showMoreOptions && (
            <Fragment>
              <div className={styles['content-wrapper']}>
                <p className={styles['title']}>{t('general.signUp')}</p>
                <div className={styles['social-button__container']}>
                  <div className={styles['button-container']}>
                    <MythicButton
                      size="responsive"
                      onClick={() => setShowMoreOptions(!showMoreOptions)}
                    />
                  </div>
                  <div className={styles['button-container']}>
                    <GoogleButton size="responsive" onClick={onGoogleClick} />
                  </div>
                  <div className={styles['button-container']}>
                    <MetamaskButton
                      size="responsive"
                      onClick={() => {
                        logEvent(
                          AnalyticsEvent.BUTTON_CLICKED,
                          'Metamask Button SignUp Click'
                        );
                        onMetamaskClick();
                      }}
                    />
                  </div>
                  <div className={styles['button-container']}>
                    <DiscordButton size="responsive" onClick={onDiscordClick} />
                  </div>
                  <div className={styles['button-container']}>
                    <XButton size="responsive" onClick={onTwitterClick} />
                  </div>
                  <div className={styles['button-container']}>
                    <SteamButton size="responsive" onClick={onSteamClick} />
                  </div>
                  {enableFacebookOauth && (
                    <FacebookButton
                      size="responsive"
                      onClick={onFacebookClick}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <p className={styles['mythic-account__container']}>
          {t('signup.form.have')}{' '}
          <span onClick={onSignIn}>{t('general.signIn')}</span>
        </p>
      </div>
      <SignupOauthForm
        show={showRegisterForm}
        setShow={setShowRegisterForm}
        name={popupModal}
      />
      <ModalPrivacyPolicy
        show={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(!showPrivacyPolicy)}
      />
    </Fragment>
  );
};

export default SignUp;
