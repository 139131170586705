import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { onLogout } from '@src/domain/User/User.reducer';
import { AppDispatch, RootState } from '@src/store';

import useLazyFetch from '@src/hooks/useLazyFetch';

import {
  HeaderAccount,
  ModalError,
  ModalUserFeedback,
  ModalUserFeedbackSuccess
} from '@jupiter/react-common-component';
import { RETURN_URI_KEY_STORAGE } from '@src/common/constants/string';
import { returnUri } from '@src/common/utils/utils';
import BackToPortal from '@src/components/hoc/BackToPortal';
import SidebarV2 from '@src/components/molecules/SidebarV2/SidebarV2';
import { initGA, logPageView } from '@src/domain/Analytic/Analythic';
import { getUserProfile } from '@src/domain/User/User.thunk';
import { setIsOpenSidebar } from '@src/domain/ui/ui.reducer';
import { defaultProfilePicture, menu } from './DashboardLayout.constants';
import styles from './DashboardLayout.module.scss';
import useDashboard from './useDashboard';
import { clearUserfeedbackState } from '@src/domain/UserFeedback/UserFeedback.reducer';
import MetaHelmet from '@src/components/atoms/Helmet/MetaHelmet';
import ReCAPTCHA from 'react-google-recaptcha';

const siteKeyCaptchaUserFeedback =
  process.env.REACT_APP_USER_FEEDBACK_CAPTCHA_KEY ?? '';

const DashboardLayout = () => {
  const {
    user: userLogs,
    ui: uiStore,
    userFeedback: UserFeedbackStore
  } = useSelector((state: RootState) => ({
    user: state.user,
    ui: state.ui,
    userFeedback: state.userFeedback
  }));

  const dispatch: AppDispatch = useDispatch();
  const {
    showUserFeedbackForm,
    setShowUserFeedbackForm,
    onUserFeedbackSubmit,
    onRetry,
    getAccessToken,
    recaptchaRef
  } = useDashboard();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const logoCenter = ['/invalid-token', '/error'];
  const includeSidebar = [
    '/',
    '/wallet',
    '/security',
    '/profile',
    '/inventory'
  ];

  const getLogOut = `/spa/logout`;
  const [onAction] = useLazyFetch({
    url: getLogOut,
    method: 'POST'
  });

  const handleLogOut = () => {
    onAction({}, (_, error) => {
      if (!error || error?.status !== 500) {
        window.location.replace('/login');
        dispatch(onLogout());
        setTimeout(() => {
          sessionStorage.removeItem(RETURN_URI_KEY_STORAGE);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    if (!(window as unknown as { [x: string]: string }).GA_INITIALIZED) {
      initGA();
      (window as unknown as { [x: string]: string | boolean }).GA_INITIALIZED =
        true;
    }
    logPageView();
  }, []);

  useEffect(() => {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const isLogin = userLogs.isLogin;
    if (isLogin && !userLogs.data) {
      dispatch(getUserProfile());
      getAccessToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogs.isLogin, userLogs.data]);

  const isOauthCallback = window.location.pathname.indexOf('/callback') > -1;
  const isIncludedSidebar =
    includeSidebar.findIndex(
      (item) => item.toLowerCase() === pathname.toLowerCase()
    ) >= 0;
  const isLogoCenter = logoCenter.findIndex((item) => item === pathname) >= 0;

  const hideNavbar = isOauthCallback;

  const handleRedirectUri = () => {
    const returnUrlParam = sessionStorage.getItem(RETURN_URI_KEY_STORAGE);
    const returnUrl = returnUrlParam ? returnUri(returnUrlParam) : '/';

    if (userLogs.isLogin && returnUrl) {
      sessionStorage.removeItem(RETURN_URI_KEY_STORAGE);
      window.location.assign(returnUrl);
    } else {
      window.location.href = '/';
    }
  };

  return (
    <>
      <MetaHelmet />
      <div
        className={
          styles['layout-container'] + ' ' + (hideNavbar ? styles['hide'] : '')
        }
        style={{ background: 'url(/assets/background-home.png)' }}
      >
        <div
          className={
            styles['content-wrapper'] +
            ' ' +
            (showUserFeedbackForm ? styles['disable-scroll'] : '')
          }
        >
          {!hideNavbar && (
            <HeaderAccount
              profile={{
                email: userLogs.data?.email ?? '',
                nickname: userLogs.data?.nickname ?? '',
                accountId: `#${userLogs.data?.tag}`,
                entitlement: '',
                profilePictureUrl: userLogs.data?.profilePictureUrl ?? undefined
              }}
              walletAccount={userLogs.data?.walletAddress ?? ''}
              isAuthenticated={
                ['/security/password'].findIndex(
                  (item) => item === window.location.pathname
                ) < 0
                  ? userLogs.isLogin
                  : false
              }
              target={pathname === '/oidc/success' ? '_blank' : '_self'}
              onSignOut={handleLogOut}
              hamburger={userLogs.isLogin && isIncludedSidebar}
              onHamburgerClick={() =>
                dispatch(setIsOpenSidebar(!uiStore.isOpenSidebar))
              }
              isSidebarOpen={uiStore.isOpenSidebar}
              handleLogoClick={
                pathname === '/new-password' ? handleRedirectUri : undefined
              }
              buttonBack={<BackToPortal />}
              isShowFeedbackIcon={userLogs.isLogin}
              handleFeedback={() => setShowUserFeedbackForm((v) => !v)}
              headingTitle="Account"
              theme="black"
            />
          )}
          <div
            className={`${styles['container']} ${
              isOauthCallback && styles['p-0']
            }`}
          >
            {!userLogs.data ? (
              <></>
            ) : (
              <>
                {isIncludedSidebar && (
                  <div className={styles['sidebar']}>
                    <SidebarV2
                      name={userLogs.data?.nickname ?? ''}
                      email={userLogs.data?.email ?? ''}
                      tag={`#${userLogs.data?.tag}`}
                      walletAddress={userLogs.data?.walletAddress ?? ''}
                      isOpen={uiStore.isOpenSidebar}
                      onClose={() =>
                        dispatch(setIsOpenSidebar(!uiStore.isOpenSidebar))
                      }
                      onLogout={() => handleLogOut()}
                      menu={menu(navigate, userLogs.data?.walletAddress)}
                    />
                  </div>
                )}
              </>
            )}
            <div
              className={`${styles['content']} ${
                isOauthCallback && styles['p-0']
              }`}
            >
              <Outlet />
            </div>
            {showUserFeedbackForm &&
            !UserFeedbackStore.status.isSuccess &&
            !UserFeedbackStore.status.isError ? (
              <>
                <ModalUserFeedback
                  show={showUserFeedbackForm}
                  loading={UserFeedbackStore.status.isLoading}
                  onClose={() => {
                    setShowUserFeedbackForm((s) => !s);
                    dispatch(clearUserfeedbackState());
                  }}
                  onSubmit={onUserFeedbackSubmit}
                />
                <ReCAPTCHA
                  className={styles['recaptcha']}
                  sitekey={siteKeyCaptchaUserFeedback}
                  ref={recaptchaRef}
                  size="invisible"
                />
              </>
            ) : null}
            {UserFeedbackStore.status.isSuccess ? (
              <ModalUserFeedbackSuccess
                show={UserFeedbackStore.status.isSuccess}
                onClose={() => {
                  setShowUserFeedbackForm((s) => !s);
                  dispatch(clearUserfeedbackState());
                }}
              />
            ) : null}
            {UserFeedbackStore.status.isError ? (
              <ModalError
                show={UserFeedbackStore.status.isError}
                title="Error"
                onRetry={onRetry}
                onClose={() => {
                  setShowUserFeedbackForm((s) => !s);
                  dispatch(clearUserfeedbackState());
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
