import { Button } from '@jupiter/react-common-component';
import NotFoundIcon from '@src/components/atoms/svg/404/index';
import React from 'react';

import styles from './notFound.module.scss';

const NotFoundContents = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
      }}
    >
      <div className={styles['notfound-container']}>
        <NotFoundIcon color="#e4e4e4" />
        <div className={styles['text-container']}>
          <p>PAGE NOT FOUND</p>
        </div>
        <div className={styles['button-container']}>
          <Button
            label="Back to Login"
            size="responsive"
            variant={'default-magenta'}
            onClick={() => {
              window.location.assign('/');
            }}
            isLoading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundContents;
