import {
  Button,
  Input,
  Loading,
  Select
} from '@jupiter/react-common-component';
import ModalContent from '@src/components/atoms/ModalContent/ModalContent';
import axios, { AxiosError } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Agreements from '../Agreements/Agreements';
import { disable3rdPartyEULA, isValidUsername } from '@src/common/utils/utils';
import { iso3166 } from '@src/common/constants/IsoLists';

import { ChecklistIcon, ConfictionLogo } from '../SignUp/SignUp.constant';

import styles from './OauthForm.module.scss';
import { HandleFieldError } from '@src/common/utils/utils';
import ModalPrivacyPolicy from '@src/pages/PrivacyPolicy/Modal/ModalPrivacyPolicy';

// todo: Split This Component

const SignupOauthForm = ({
  show,
  name,
  setShow
}: {
  show: boolean;
  name: string;
  setShow: any;
}) => {
  const { state } = useLocation();
  const [isUseCustomEmailFB, setIsUseCustomEmailFB] = useState(false);
  const [showNameForm, setShowNameForm] = useState<boolean>(false);
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [isSkipEmail, setIsSkipEmail] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    firstName: false,
    lastName: false,
    userName: false,
    general: false,
    isEulaChecked: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>(
    {
      email: '',
      firstName: '',
      lastName: '',
      userName: '',
      general: '',
      isEulaChecked: ''
    }
  );

  // Name Form
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [isEulaChecked, setIsEulaChecked] = useState<boolean>(false);

  const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);

  const contentVariants = {
    hidden: {
      x: 150,
      opacity: 0,
      transition: { duration: 1.2 }
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, delay: 0.5 }
    },
    exit: {
      opacity: 0,
      x: -300,
      transition: { duration: 1.2 }
    }
  };

  const resetForm = () => {
    setFieldErrors({
      email: false,
      firstName: false,
      lastName: false,
      general: false,
      isEulaChecked: false
    });
  };

  const onSubmitName = async () => {
    resetForm();
    if (!firstName) {
      setFieldErrorWithMessage('firstName', 'This field is required');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, firstName: '' }));
    }

    if (!lastName) {
      setFieldErrorWithMessage('lastName', 'This field is required');
      return;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, lastName: '' }));
    }

    if (state.provider.toLowerCase() === 'steam') {
      if (!userName || userName.length === 0) {
        setFieldErrorWithMessage('userName', 'This field is required');
        return;
      } else if (userName.length > 32) {
        setFieldErrorWithMessage(
          'userName',
          'Username length exceeds the maximum allowed length of 32 characters.'
        );
        return;
      } else if (!isValidUsername(userName)) {
        setFieldErrorWithMessage(
          'userName',
          'Please input letters (a-z, A-Z), numbers (0-9) and certain special characters (*, +, -, @, _, .) only'
        );
        return;
      }
    }

    if (!isEulaChecked) {
      setFieldErrors((prev) => ({
        ...prev,
        isEulaChecked: true
      }));
      setFieldErrorWithMessage('isEulaChecked', '');
      return;
    } else {
      setFieldErrors((prev) => ({
        ...prev,
        isEulaChecked: false
      }));
    }

    try {
      setShowNameForm(false);
      setIsLoading(true);
      const formData = {
        provider: state.provider,
        providerUserId: state.providerUserId,
        firstName,
        lastName,
        userName,
        country
      };
      const { data, status } = await axios.post(
        '/spa/external/submit-name',
        formData
      );
      if (status === 200) {
        if (data.popupName) {
          setTimeout(() => {
            setIsLoading(false);
            setShowEmailForm(true);
          }, 3000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            setShowComplete(true);
          }, 3000);

          setTimeout(() => {
            window.location.assign('/');
          }, 6000);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        const errorMessage = response?.data.message ?? 'Something went wrong';
        const cleanedUpErrorMessage: string = errorMessage.replace(
          'Invalid Operation',
          ''
        );
        if (response?.data.target) {
          setFieldErrorWithMessage(response.data.target, cleanedUpErrorMessage);
          setFieldErrorWithMessage('general', '');
        } else {
          setFieldErrorWithMessage('general', cleanedUpErrorMessage);
        }
        setTimeout(() => {
          setIsLoading(false);
          setShowNameForm(true);
        }, 3000);
      }
    }
  };

  const onSubmitEmail = async () => {
    resetForm();
    const emailRegex = /^[a-zA-Z0-9._-]{1,60}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!isSkipEmail) {
      if (!email) {
        setFieldErrorWithMessage('email', 'This field is required');
        return;
      } else {
        setErrorMessages((prevState) => ({ ...prevState, email: '' }));
      }

      if (!emailRegex.test(email)) {
        setFieldErrorWithMessage('email', 'Please enter a valid email');
        return;
      } else {
        setErrorMessages((prevState) => ({ ...prevState, email: '' }));
      }

      if (!disable3rdPartyEULA && !isEulaChecked) {
        setFieldErrorWithMessage('isEulaChecked', '');
        setIsSkipEmail(false);
        return;
      } else {
        setErrorMessages((prevState) => ({ ...prevState, isEulaChecked: '' }));
      }
    }

    try {
      setShowEmailForm(false);
      setIsLoading(true);
      const formData = {
        provider: state.provider,
        providerUserId: state.providerUserId,
        email: isSkipEmail ? state.email : email
      };

      const { status } = await axios.post(
        '/spa/external/associate-account',
        formData
      );
      if (status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          if (state.provider === 'Facebook' && isSkipEmail) {
            window.location.replace('/');
          } else {
            setShowVerify(true);
          }
        }, 3000);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        if (response) {
          if (response?.data.target) {
            setFieldErrorWithMessage(
              response.data.target,
              response.data.message ?? 'Something went wrong'
            );
            setFieldErrorWithMessage('general', '');
            setErrorCode(response.data.code);
          } else if (response?.data.message) {
            setFieldErrorWithMessage('general', response?.data.message);
            setErrorCode(response?.data.code);
          } else {
            setFieldErrorWithMessage('general', response?.data);
            setErrorCode(`${response.status}`);
            setIsLoading(false);
            setShowEmailForm(true);

            setTimeout(() => {
              window.location.replace('/login');
            }, 3000);
          }
        }

        setTimeout(() => {
          setIsLoading(false);
          setShowEmailForm(true);
        }, 3000);
      }
    }
  };

  const setFieldErrorWithMessage = (fieldName: string, errMessage: string) => {
    HandleFieldError(fieldName, errMessage, setFieldErrors, setErrorMessages);
  };

  useEffect(() => {
    if (
      state != undefined &&
      state.provider !== undefined &&
      state.provider.toLowerCase() === 'steam'
    ) {
      if (state.userName) {
        setUserName(state.userName);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (name === 'SubmitName') {
  //     setShowNameForm(true);
  //   }
  //   if (name === 'AssociateWithEmail') {
  //     setShowEmailForm(true);
  //   }
  // }, [name]);

  useEffect(() => {
    setShowNameForm(true);
  }, [name]);

  useEffect(() => {
    if (isSkipEmail) {
      onSubmitEmail();
    }
  }, [isSkipEmail]);

  const ErrRegisteredEmail = () => (
    <Fragment>
      This user is already registered to Mythic Protocol. Please enter a
      different email or{' '}
      <span
        onClick={() => window.location.replace('/login')}
        className={styles['signin-link']}
      >
        Sign In
      </span>{' '}
      to access to your account
    </Fragment>
  );
  const onCloseModal = () => {
    setShow(false);
    history.replaceState({}, document.title);
  };
  return (
    <>
      <ModalContent
        show={show}
        onClose={() => {
          showVerify && onCloseModal();
        }}
        noTitle={!showVerify}
        title={undefined}
      >
        <AnimatePresence exitBeforeEnter>
          {state && showNameForm && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="nameForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#E4E4E4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>
                  Hi, {state.name ?? state.userName}
                </p>
                <p className={styles['subtitle']}>
                  Please enter your full name
                </p>
              </div>
              <div className={styles['desc-container']}>
                <p>
                  For security reasons, we require you to put your full name.
                </p>
              </div>
              <div className={styles['input-container']}>
                <Input
                  label="First Name"
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  error={errorMessages.firstName || ''}
                />
                <Input
                  label="Last Name"
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  error={errorMessages.lastName || ''}
                />
                <Input
                  label="Username"
                  value={userName}
                  name="userName"
                  onChange={(e) => setUserName(e.currentTarget.value)}
                  error={errorMessages.userName || ''}
                />
                <div className={styles['select-wrapper']}>
                  <Select
                    name="country"
                    label="Select your Region"
                    variant="dark-theme"
                    value={country}
                    isExpanded={isCountryOpen}
                    setIsExpanded={setIsCountryOpen}
                    options={iso3166
                      .filter((item) => item.alpha2 !== null)
                      .map((item) => ({
                        text: item.name,
                        value: item.alpha2 ?? ''
                      }))}
                    withFilter
                    onChange={(e) => {
                      setCountry(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className={styles['agreement-wrapper']}>
                  <Agreements
                    isEulaChecked={isEulaChecked}
                    setIsEulaChecked={setIsEulaChecked}
                    eulaErrMessage={errorMessages.isEulaChecked}
                    setShowAgreements={setShowPrivacyPolicy}
                    isEulaErr={fieldErrors.isEulaChecked}
                  />
                </div>
              </div>
              {fieldErrors && fieldErrors.general && (
                <p className={styles['danger-text__medium']}>
                  {errorMessages.general}
                </p>
              )}
              <div></div>
              <div className={styles['oauth-container__button']}>
                <Button
                  isLoading={false}
                  label="Continue"
                  variant="default-magenta"
                  size="responsive"
                  onClick={() => {
                    onSubmitName();
                  }}
                />
              </div>
            </motion.div>
          )}

          {isLoading && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key="loading"
              className={styles['oauth-loading__container']}
            >
              <Loading size={60} />
            </motion.div>
          )}

          {state && showEmailForm && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="emailForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#E4E4E4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Just a few more steps</p>
                <p className={styles['subtitle']}>
                  Please enter your active email
                </p>
              </div>
              {state.provider === 'Facebook' ? (
                <div className={styles['desc-container__facebook']}>
                  <p>
                    We got this email <span>{state.email}</span> from your
                    Facebook account. Please confirm or update it if the email
                    is incorrect or inactive.
                  </p>
                </div>
              ) : (
                <div className={styles['desc-container']}>
                  <p>
                    For security reasons, we require you to put your active
                    email.
                  </p>
                </div>
              )}
              {state.provider === 'Facebook' && (
                <>
                  {!isUseCustomEmailFB && (
                    <Fragment>
                      <div className={styles['btn-use-fb-email']}>
                        <Button
                          label={'Yes, use this Facebook Email'}
                          size={'responsive'}
                          isLoading={false}
                          variant={'default'}
                          onClick={() => setIsSkipEmail(true)}
                        />
                      </div>
                      <div className={styles['skip-facebook__separator']}>
                        <svg
                          width="56"
                          height="2"
                          viewBox="0 0 56 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M56 1H0" stroke="#E4E4E4" />
                        </svg>
                        <p>Or</p>
                        <svg
                          width="56"
                          height="2"
                          viewBox="0 0 56 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M56 1H0" stroke="#E4E4E4" />
                        </svg>
                      </div>

                      <div
                        className={styles['btn-custom-email']}
                        onClick={() => setIsUseCustomEmailFB(true)}
                      >
                        Use another Email
                      </div>
                    </Fragment>
                  )}
                </>
              )}
              {(state.provider !== 'Facebook' || isUseCustomEmailFB) && (
                <div className={styles['input-container']}>
                  <Input
                    label="Enter your email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    error={
                      errorCode !== '122302' ? (
                        errorMessages.email
                      ) : (
                        <ErrRegisteredEmail />
                      )
                    }
                  />
                </div>
              )}
              {!disable3rdPartyEULA &&
                (state.provider !== 'Facebook' || isUseCustomEmailFB) && (
                  <Agreements
                    isEulaChecked={isEulaChecked}
                    setIsEulaChecked={setIsEulaChecked}
                    eulaErrMessage={errorMessages.isEulaChecked}
                    setShowAgreements={setShowPrivacyPolicy}
                    isEulaErr={fieldErrors.isEulaChecked}
                  />
                )}
              {fieldErrors && fieldErrors.general && (
                <p className={styles['danger-text__medium']}>
                  {errorMessages.general}
                </p>
              )}
              {(state.provider !== 'Facebook' || isUseCustomEmailFB) && (
                <div className={styles['oauth-container__button']}>
                  <Button
                    isLoading={false}
                    label="Continue"
                    variant="default-magenta"
                    size="responsive"
                    onClick={() => {
                      onSubmitEmail();
                    }}
                  />
                </div>
              )}
            </motion.div>
          )}

          {showVerify && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="verifyForm"
            >
              <div className={styles['logo-container']}>
                <ConfictionLogo color="#E4E4E4" />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Please verify your email</p>
              </div>
              <div className={styles['desc-container']}>
                <p>
                  A verification link has been sent to <span>{email}</span>{' '}
                </p>
                <p>Please check your email now.</p>
              </div>
            </motion.div>
          )}

          {showComplete && (
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['oauth-form__container']}
              key="completeForm"
            >
              <div className={styles['logo-container']}>
                <ChecklistIcon />
              </div>
              <div className={styles['title-container']}>
                <p className={styles['title']}>Congrats!</p>
                <p className={styles['subtitle']}>
                  Your account registration was <span>successful!</span>
                </p>
              </div>
              <div className={styles['loading-container']}>
                <Loading size={26} />
              </div>
              <div className={styles['redirect-container']}>
                <p>Please wait, you will automatically be redirected</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalContent>

      <ModalPrivacyPolicy
        show={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(!showPrivacyPolicy)}
      />
    </>
  );
};

export default SignupOauthForm;
