import React from 'react';
import styles from './ModalPrivacyPolicy.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import PrivacyPolicy from '../PrivacyPolicy';

type IModalPrivacyPolicy = {
  onClose: () => void;
  show: boolean;
};

const ModalPrivacyPolicy: React.FC<IModalPrivacyPolicy> = ({
  onClose,
  show
}) => {
  const modalVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: {
      opacity: 0,
      when: 'afterChildren',
      duration: { delay: 1 }
    }
  };

  const contentVariants = {
    hidden: {
      height: 0,
      opacity: 0
    },
    visible: {
      opacity: 1,
      height: 'max-content',
      transition: { duration: 0.5, delay: 0.5 }
    },
    exit: {
      opacity: 0,
      height: 0
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <>
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={styles['drop-shadow']}
            onClick={onClose}
            data-testid="modal-content"
          ></motion.div>
          <div>
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['content-container']}
            >
              <div className={styles['close-button']} onClick={onClose}>
                &times;
              </div>
              <div className={styles['content-wrapper']}>
                <PrivacyPolicy height="600px" />
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ModalPrivacyPolicy;
