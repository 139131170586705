/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// eslint-disable-next-line react/no-unescaped-entities

export const ConfictionLogo: React.FC<{ color?: string }> = ({
  color = '#E2E2E2'
}) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.807 17.6924L41.509 13.5324C42.8567 16.6311 43.4461 20.0065 43.2286 23.3786C43.0111 26.7507 41.9928 30.0223 40.2583 32.9222C34.2332 42.9909 21.143 46.2842 11.0757 40.2577C1.0085 34.2313 -2.28346 21.1397 3.74163 11.0738C9.76672 1.00797 22.8569 -2.28261 32.9242 3.7411C33.9921 4.37976 35.0022 5.11054 35.9429 5.92511L30.2271 10.0962C29.9678 9.91734 29.7057 9.74539 29.4284 9.58177C26.5279 7.8447 23.1106 7.17859 19.7702 7.69917C16.4297 8.21975 13.3771 9.89412 11.1428 12.4314C8.90854 14.9686 7.6337 18.2085 7.53979 21.5879C7.44588 24.9674 8.53885 28.273 10.6288 30.9305C12.7188 33.5879 15.6736 35.4292 18.98 36.1345C22.2864 36.8397 25.7355 36.3644 28.7279 34.7911C31.7203 33.2178 34.0669 30.6459 35.3602 27.5222C36.6534 24.3986 36.8116 20.9205 35.807 17.6924ZM29.2146 30.2456C31.2118 28.4974 32.5063 26.0835 32.8576 23.4527H11.1409C11.4922 26.0835 12.7868 28.4974 14.7839 30.2456C16.7811 31.9938 19.345 32.9575 21.9993 32.9575C24.6535 32.9575 27.2175 31.9938 29.2146 30.2456Z"
        fill={color}
      />
    </svg>
  );
};

export const ChecklistIcon = () => {
  return (
    <svg
      width="33"
      height="26"
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6367 25.2181L0 13.5817L4.07285 9.50901L11.6367 17.0726L27.9281 0.781738L32.001 4.85446L11.6367 25.2181Z"
        fill="#04963E"
      />
    </svg>
  );
};

export const AgreementContent = () => (
  <div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          This Community Agreement ("Agreement") is between the user or
          community member ("Member") and [Mythic Protocol] ("Mythic Protocol"),
          and it governs Member's use of the Mythic Protocol's blockchain
          platform ("Platform").
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <u>
          <span
            style={{
              fontSize: '16px',
              fontFamily: '"Segoe UI",sans-serif',
              color: '#e4e4e4'
            }}
          >
            Definition <br />
            &nbsp;{' '}
          </span>
        </u>
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          <br />
          <strong>Account or member access</strong> means the authorization
          granted to an individual or entity to access a particular service or
          content on a platform. To gain account or member access, a user
          usually must provide personal information such as their name, email
          address, and password, and agree to the terms and conditions of the
          service or platform.{' '}
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <strong>
          <span
            style={{
              fontSize: '16px',
              fontFamily: '"Segoe UI",sans-serif',
              color: '#e4e4e4'
            }}
          >
            Blockchain Platform
          </span>
        </strong>
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          &nbsp;means a decentralized digital ledger that records and stores
          transactions across a network of computers. The ledger is secured
          through cryptographic techniques, making it tamper-proof and
          transparent. Blockchain platforms are often used to enable secure and
          transparent transactions for cryptocurrencies and other digital
          assets.
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <strong>
          <span
            style={{
              fontSize: '16px',
              fontFamily: '"Segoe UI",sans-serif',
              color: '#e4e4e4'
            }}
          >
            Community Member&nbsp;
          </span>
        </strong>
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          means an individual or entity who has joined a group or platform to
          participate in discussions, share ideas, or access content. Community
          members often must agree to certain terms and conditions in order to
          become a part of the community.
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <strong>
          <span
            style={{
              fontSize: '16px',
              fontFamily: '"Segoe UI",sans-serif',
              color: '#e4e4e4'
            }}
          >
            Transaction fee
          </span>
        </strong>
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          &nbsp;means a payment that community members are required to make to
          access certain features or content on a platform.
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          padding: '0cm'
        }}
      >
        <strong>
          <span
            style={{
              fontSize: '16px',
              fontFamily: '"Segoe UI",sans-serif',
              color: '#e4e4e4'
            }}
          >
            Community data
          </span>
        </strong>
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          &nbsp;means the personal information that community members provide to
          a platform when they sign up for an account or access certain features
          or content. Community data may include information such as email
          address, billing information and etc, This data is typically protected
          by the platform's privacy policy&nbsp; <br />
          &nbsp;{' '}
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={1}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Acceptance of Agreement
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          By accessing or using the Platform, Member agrees to be bound by the
          terms and conditions of this Agreement. If Member does not agree to
          these terms and conditions, Member may not access or use the Platform.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={2}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Registration and Account
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          Member must create an account to access the Platform. Member agrees to
          provide accurate and complete information during the registration
          process and to update such information to keep it accurate and
          complete. Member is responsible for maintaining the confidentiality of
          the login credentials and for all activities that occur under Member's
          account. Member agrees to immediately notify Mythic Protocol of any
          unauthorized use of Member's account or any other breach of security.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={3}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Use of Platform
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          Member agrees to use the Platform in accordance with all applicable
          laws and regulations and in a manner that does not infringe on the
          rights of any third party or interfere with the operation of the
          Platform.
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (a) Member shall use the Platform only for lawful purposes. <br />
        </span>
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (b) Member shall not use the Platform to:
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm',
        marginLeft: '36.0pt',
        marginRight: '0cm'
      }}
    >
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (i) violate any applicable laws, regulations, or rules.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (ii) infringe any intellectual property rights or other proprietary
          rights of any third party.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (iii) transmit any viruses, worms, or other malicious software.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (iv) engage in any fraudulent, abusive, or unethical conduct.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (v) interfere with the operation of the Platform or the networks used
          to provide the Platform.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (vi) gain unauthorized access to any computer systems or networks used
          to provide the Platform.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (vii) engage in any activity that could damage, disable, overburden,
          or impair the Platform or the networks used to provide the Platform.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (viii) transmit any content that is unlawful, harmful, threatening,
          abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of
          another's privacy, hateful, or racially, ethnically, or otherwise
          objectionable.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (ix) transmit any unsolicited or unauthorized advertising, promotional
          materials, spam, or any other form of solicitation.
        </span>
      </p>
      <p
        style={{
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0 0 7px',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '1rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (x) impersonate any person or entity or falsely represent Member's
          affiliation with any person or entity.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span style={{ fontFamily: '"Segoe UI",sans-serif', color: '#e4e4e4' }}>
          (c) Member acknowledges that the Platform is provided on an "as is"
          and "as available" basis, and that Operator does not warrant that the
          Platform will be uninterrupted or error-free.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={4}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Intellectual Property
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          Member acknowledges that the Platform and all content contained
          therein, including but not limited to text, graphics, logos, images,
          and software, are the property of the Mythic Protocol or its licensors
          and are protected by intellectual property laws. Member agrees not to
          reproduce, modify, distribute, or create derivative works based on any
          portion of the Platform without the prior written consent of the
          Mythic Protocol.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={5}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Transaction Fees
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          Member will be charged a transaction fee when buy, sell, or transfer
          NFTs on our platform. The transaction fee amount may vary depending on
          the current market conditions, the value of the NFT being traded, and
          other factors that we deem relevant:&nbsp;
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm',
        color: '#e4e4e4',
        marginRight: '0cm',
        paddingLeft: '3rem'
      }}
    >
      <ol start={1} style={{ listStyleType: 'lower-alpha' }}>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              color: '#e4e4e4',
              marginBottom: '2px'
            }}
          >
            The transaction fee will be deducted from the total amount of the
            transaction
          </span>
        </li>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              color: '#e4e4e4',
              marginBottom: '2px'
            }}
          >
            We reserve the right to change the transaction fee at any time,
            without notice.
          </span>
        </li>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              color: '#e4e4e4',
              marginBottom: '2px'
            }}
          >
            By using our platform, member agree to pay the applicable
            transaction fees, we may offer discounts or waive transaction fees
            in certain circumstances, at our sole discretion.
          </span>
        </li>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              color: '#e4e4e4',
              marginBottom: '2px'
            }}
          >
            Members are responsible for paying any taxes or other charges
            associated with your transactions on our platform, including any
            transaction fees
          </span>
        </li>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              color: '#e4e4e4',
              marginBottom: '2px'
            }}
          >
            If Member dispute any transaction fee, must notify us in writing
            within seven (7) days of the transaction. Failure to do so will
            result in your waiving any right to dispute the transaction fee.
          </span>
        </li>
        <li style={{ marginTop: '8px' }}>
          <span
            style={{
              fontFamily: '"Segoe UI",sans-serif',
              fontSize: '12.0pt',
              marginBottom: '2px'
            }}
          >
            We may use third-party payment processors to facilitate transactions
            on our platform, and such processors may charge additional fees. You
            are responsible for reviewing and accepting any terms and conditions
            of such third-party payment processors before using their services
            on our platform.
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={6}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Disclaimer of Warranties
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          THE PLATFORM IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. THE MYTHIC PROTOCOL DOES NOT WARRANT THAT THE
          PLATFORM WILL MEET MEMBER'S REQUIREMENTS OR THAT THE OPERATION OF THE
          PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={7}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Limitation of Liability
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          THE MYTHIC PROTOCOL SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH THIS AGREEMENT OR THE USE OF THE PLATFORM, EVEN IF THE
          MYTHIC PROTOCOL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          THE MYTHIC PROTOCOL'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION
          WITH THIS AGREEMENT OR THE USE OF THE PLATFORM SHALL NOT EXCEED THE
          AMOUNT PAID BY MEMBER FOR ACCESS TO THE PLATFORM.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={8}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Termination
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          Either party may terminate this Agreement at any time for any reason
          upon written notice to the other party. Upon termination, Member's
          access to the Platform shall immediately cease.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={9}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Governing Law and Dispute Resolution
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          marginBottom: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          This Agreement shall be governed by and construed in accordance with
          the laws of the jurisdiction in which the Mythic Protocol is located,
          without regard to its conflict of law provisions. Any dispute arising
          out of or relating to this Agreement, or the use of the Platform shall
          be resolved through binding arbitration in accordance with the rules
          of the Singapore International Arbritation Centre, and judgment upon
          the award rendered by the arbitrator(s) may be entered in any court
          having jurisdiction thereof.
        </span>
      </p>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 5.0pt',
        fontWeight: 'bold',
        marginRight: '0cm'
      }}
    >
      <ol
        start={10}
        style={{ marginBottom: '0cm', marginTop: '0cm' }}
        type={'1'}
      >
        <li
          style={{
            margin: '0cm',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            color: '#e4e4e4',
            fontWeight: 'bold',
            textAlign: 'justify',
            border: 'none',
            padding: '0cm'
          }}
        >
          <span
            style={{ fontSize: '16px', fontFamily: '"Segoe UI",sans-serif' }}
          >
            Entire Agreement
          </span>
        </li>
      </ol>
    </div>
    <div
      style={{
        margin: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        padding: '0cm 0cm 0cm 0cm'
      }}
    >
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          This Agreement constitutes the whole Agreement between Community
          Member and the Mythic Protocol regarding the use of the Platform. It
          supersedes all prior agreements and understandings, whether written or
          oral.&nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          Modification The Mythic Protocol may modify this Agreement from time
          to time in its sole discretion by posting the revised Agreement on the
          Platform. Member's continued use of the Platform following any such
          modification constitutes Member's acceptance of the revised
          Agreement.&nbsp;
        </span>
      </p>
      <p
        style={{
          marginRight: '0cm',
          marginLeft: '0cm',
          fontSize: '15px',
          fontFamily: '"Calibri",sans-serif',
          margin: '0cm',
          marginTop: '8pt',
          textAlign: 'justify',
          border: 'none',
          paddingLeft: '3rem'
        }}
      >
        <span
          style={{
            fontSize: '16px',
            fontFamily: '"Segoe UI",sans-serif',
            color: '#e4e4e4'
          }}
        >
          By clicking "I agree," Member acknowledges that Member has read this
          Agreement, understands it, and agrees to be bound by its terms and
          conditions.
        </span>
      </p>
    </div>
    <p
      style={{
        marginRight: '0cm',
        marginLeft: '0cm',
        fontSize: '16px',
        fontFamily: '"Times New Roman",serif',
        margin: '0cm',
        textAlign: 'justify',
        verticalAlign: 'baseline'
      }}
    >
      <span style={{ fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
        &nbsp;
      </span>
    </p>
    <p
      style={{
        marginRight: '0cm',
        marginLeft: '0cm',
        fontSize: '15px',
        fontFamily: '"Calibri",sans-serif',
        margin: '0cm',
        textAlign: 'justify'
      }}
    >
      &nbsp;
    </p>
  </div>
);
