import React, { Fragment } from 'react';

import NotFoundContents from './contents';

const NotFoundPage = () => {
  return (
    <Fragment>
      <NotFoundContents />
    </Fragment>
  );
};

NotFoundPage.template = 'dashboard';

export default NotFoundPage;
