import React, { useCallback, useEffect, useState } from 'react';

import { Button, Input } from '@jupiter/react-common-component';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './ResetPassword.module.scss';
import { HandleFieldError } from '@src/common/utils/utils';
import useToaster from '@src/hooks/useToaster';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';
import { setIsLogin } from '@src/domain/User/User.reducer';
import { useDispatch } from 'react-redux';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confPassword, setConfPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const { setMessage } = useToaster();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    newPassword: false,
    confPassword: false,
    general: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>(
    {
      newPassword: '',
      confPassword: '',
      general: ''
    }
  );

  const resetForm = () => {
    setFieldErrors({ newPassword: false, confPassword: false, general: false });
  };

  const setFieldErrorWithMessage = (fieldName: string, errMessage: string) => {
    HandleFieldError(fieldName, errMessage, setFieldErrors, setErrorMessages);
  };

  useEffect(() => {
    if (email && token) {
      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = async () => {
    setIsChecking(true);
    try {
      const response = await axios.get('/spa/check-forgot-password-token', {
        params: {
          token,
          email
        }
      });
      if (response.status !== 200) {
        if (response.data.redirectUrl) {
          navigate(response.data.redirectUrl);
        }
        return;
      }
      setIsChecking(false);
    } catch (err) {
      setIsChecking(false);
      if (err instanceof AxiosError) {
        if (err && err.response?.data.redirectUrl) {
          navigate(err.response.data.redirectUrl);
        } else {
          setMessage({ render: 'Something went wrong' });
        }
      }
    }
  };

  const newPasswordValidation = useCallback(() => {
    resetForm();
    if (newPassword.trim() === '') {
      setFieldErrorWithMessage('newPassword', 'This field is required');
      return false;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, newPassword: '' }));
    }

    if (
      !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/.test(
        newPassword.trim()
      )
    ) {
      setFieldErrorWithMessage(
        'newPassword',
        'Password must be 8 or more characters with a minimum of each an uppercase letter, lowercase letter, a number and a symbol.'
      );
      return false;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, newPassword: '' }));
    }

    if (confPassword.trim() === '') {
      setFieldErrorWithMessage('confPassword', 'This field is required');
      return false;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, confPassword: '' }));
    }

    if (confPassword.trim() !== newPassword.trim()) {
      setFieldErrorWithMessage(
        'confPassword',
        'The passwords entered do not match. Please retype the new password in both fields.'
      );
      return false;
    } else {
      setErrorMessages((prevState) => ({ ...prevState, confPassword: '' }));
    }

    return true;
  }, [newPassword, confPassword]);

  const handleUpdate = useCallback(() => {
    const checkValidation = newPasswordValidation();
    if (checkValidation) {
      setIsLoading(true);

      const payload = {
        password: newPassword,
        confirmPassword: confPassword,
        email,
        token
      };

      const config = {
        method: 'POST',
        url: `/spa/reset-password`,
        headers: {
          'content-type': 'application/json'
        },
        credentials: 'include',
        data: JSON.stringify(payload)
      };

      if (token && email) {
        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              setIsLoading(false);
              dispatch(setIsLogin(false));
              if (response.data.redirectUrl) {
                navigate(response.data.redirectUrl);
              }
            }
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.response.data && err.response.data.redirectUrl) {
              navigate(err.response.data.redirectUrl, { replace: true });
            } else {
              setMessage({
                render: err.response.data.message,
                variant: 'error'
              });
            }
            // setFieldErrorWithMessage('general', err.response.data.message);
          });
      }
    }
  }, [
    setMessage,
    newPasswordValidation,
    newPassword,
    navigate,
    confPassword,
    email,
    token
  ]);

  return (
    <div className={styles['wrapper']}>
      {isChecking ? (
        <FullPageLoading />
      ) : (
        <div className={styles['body-container']}>
          <div className={styles['input-container']}>
            <p className={styles['title']}>Reset Password</p>
          </div>
          <form
            className={styles['input-wrapper']}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={styles['input-container']}>
              <Input
                onChange={(e) => {
                  setNewPassword(e.currentTarget.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                }}
                hint="Password must be 8 or more characters with a minimum of each an uppercase letter, lowercase letter, a number and a symbol."
                variant="general-line"
                error={errorMessages.newPassword}
                type="password"
                label="Enter new password"
                value={newPassword}
                name="new-password"
              />
            </div>
            <div className={styles['input-container']}>
              <Input
                onChange={(e) => {
                  setConfPassword(e.currentTarget.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                }}
                variant="general-line"
                error={errorMessages.confPassword}
                type="password"
                label="Confirm new password"
                value={confPassword}
                name="confirm-password"
              />
            </div>

            {fieldErrors && fieldErrors.general && (
              <div className={styles['input-container']}>
                <div className={styles['message-err']}>
                  <p>{errorMessages.general}</p>
                </div>
              </div>
            )}

            <div className={styles['button-wrapper']}>
              <Button
                isLoading={isLoading}
                label="Continue"
                size="responsive"
                variant={'default-magenta'}
                onClick={handleUpdate}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
