import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useTwitterData } from '@src/hooks/useTwitterData';
import useToaster from '@src/hooks/useToaster';
import { returnUri, urlRedirection } from '@src/common/utils/utils';
import OIDCLoading from '@src/components/atoms/LoadingScreen/OIDCLoading';
import {
  RETURN_URI_KEY_STORAGE,
  RETURN_URL_KEY_PARAM
} from '@src/common/constants/string';

export interface IProvider {
  provider: string;
  accessToken: string;
}

const Callback = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { id } = params;
  const { getTwitterAuthToken } = useTwitterData();
  const { setMessage } = useToaster();

  const storageReturnUri = sessionStorage.getItem(RETURN_URI_KEY_STORAGE);
  const returnUrl = storageReturnUri
    ? encodeURIComponent(storageReturnUri)
    : '';
  const errorRedirectUrl = returnUrl
    ? `/register?returnUrl=${returnUrl}`
    : '/register';

  const processLogin = async (data: IProvider) => {
    try {
      const returnUrlParam =
        searchParams.get(RETURN_URL_KEY_PARAM) ??
        sessionStorage.getItem(RETURN_URI_KEY_STORAGE);

      const returnUrl = returnUrlParam ? returnUri(returnUrlParam) : '';

      const { data: loginData, status } = await axios.post(
        '/spa/external/process-login',
        {
          provider: data.provider,
          accessToken: data.accessToken,
          returnUrl: returnUrl ?? ''
        }
      );
      if (status == 200) {
        if (loginData.popupName) {
          const userName = loginData.userName || '';
          navigate(loginData.redirectUrl, {
            state: {
              registerModal: loginData.popupName,
              provider: loginData.provider,
              providerUserId: loginData.providerUserId,
              userName: loginData.userName || '',
              email: loginData.email || '',
              name: loginData.name ?? ''
            }
          });
        } else {
          if (loginData && loginData.redirectUrl) {
            const redirectUrlParam = loginData.redirectUrl;
            localStorage.setItem('isLogin', JSON.stringify('true'));
            urlRedirection(
              redirectUrlParam,
              loginData.redirectUrl,
              navigate,
              undefined,
              true
            );
          } else {
            window.location.assign('/');
          }
        }
      } else {
        navigate('/register');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setMessage({ render: 'Something went wrong!', variant: 'error' });
        setTimeout(() => {
          window.location.replace('/login');
        }, 1500);
      }
    }
  };

  const handleDiscordCallback = async () => {
    const callbackCode = searchParams.get('code');
    const clientId = process.env.OAUTH_DISCORD_ID;
    const clientSecret = process.env.OAUTH_DISCORD_SECRET;
    const redirectUri = encodeURIComponent(
      window.location.origin + '/auth/discord/callback'
    );

    if (!callbackCode) {
      navigate(errorRedirectUrl);
      return;
    }
    try {
      const { data } = await axios.post(
        'https://discord.com/api/oauth2/token',
        `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${callbackCode}&redirect_uri=${redirectUri}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      const loginData: IProvider = {
        provider: 'Discord',
        accessToken: data.access_token
      };

      await processLogin(loginData);
    } catch (e) {
      navigate(errorRedirectUrl);
    }
  };

  const handleGoogleLogin = async () => {
    const callbackCode = searchParams.get('code');
    const clientId = process.env.OAUTH_GOOGLE_CLIENT_ID;
    const clientSecret = process.env.OAUTH_GOOGLE_CLIENT_SECRET;
    const redirectUri = `${window.location.origin}/auth/google/callback`;

    if (!callbackCode) {
      navigate(errorRedirectUrl);
      return;
    }
    try {
      const { data } = await axios.post('https://oauth2.googleapis.com/token', {
        code: callbackCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code'
      });

      const loginData: IProvider = {
        provider: 'Google',
        accessToken: data.access_token
      };

      await processLogin(loginData);
    } catch (error) {
      navigate(errorRedirectUrl);
    }
  };

  const handleFacebookLogin = async () => {
    const callbackCode = searchParams.get('code');

    if (!callbackCode) {
      navigate(errorRedirectUrl);
      return;
    }
    try {
      const loginData: IProvider = {
        provider: 'Facebook',
        accessToken: callbackCode
      };

      await processLogin(loginData);
    } catch (error) {
      navigate(errorRedirectUrl);
    }
  };

  const handleTwitterLogin = async () => {
    const callbackCode = searchParams.get('code');
    const redirectUri = `${window.location.origin}/auth/twitter/callback`;
    if (!callbackCode) {
      navigate(errorRedirectUrl);
      return;
    }
    if (callbackCode) {
      const { data } = await getTwitterAuthToken(callbackCode, redirectUri);

      const loginData: IProvider = {
        provider: 'Twitter',
        accessToken: data.access_token
      };
      if (data) {
        await processLogin(loginData);
      }
    }
  };

  const handleSteamLogin = async () => {
    const token = searchParams.get('openid.identity');

    if (token) {
      const accessToken = token.replace(
        'https://steamcommunity.com/openid/id/',
        ''
      );
      // Use the access token for your desired purposes
      const loginData: IProvider = {
        provider: 'steam',
        accessToken
      };
      await processLogin(loginData);
    }
  };

  useEffect(() => {
    if (id === 'discord') {
      handleDiscordCallback();
    }

    if (id === 'google') {
      handleGoogleLogin();
    }

    if (id === 'twitter') {
      handleTwitterLogin();
    }

    if (id === 'facebook') {
      handleFacebookLogin();
    }
    if (id === 'steam') {
      handleSteamLogin();
    }
  }, [location]);

  return <OIDCLoading />;
};

export default Callback;
