import React from 'react';
const ErrorIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2058_63678"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-1"
      y="0"
      width="50"
      height="48"
    >
      <rect x="-0.000732422" width="48.0015" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2058_63678)">
      <path
        d="M24.0001 34C24.5668 34 25.0418 33.8083 25.4252 33.425C25.8085 33.0417 26.0002 32.5667 26.0002 32C26.0002 31.4333 25.8085 30.9583 25.4252 30.575C25.0418 30.1917 24.5668 30 24.0001 30C23.4335 30 22.9584 30.1917 22.5751 30.575C22.1917 30.9583 22.0001 31.4333 22.0001 32C22.0001 32.5667 22.1917 33.0417 22.5751 33.425C22.9584 33.8083 23.4335 34 24.0001 34ZM22.0001 26H26.0002V14H22.0001V26ZM24.0001 44C21.2334 44 18.6333 43.475 16.1999 42.425C13.7665 41.375 11.6498 39.95 9.84969 38.15C8.04964 36.35 6.62459 34.2333 5.57456 31.8C4.52453 29.3667 3.99951 26.7667 3.99951 24C3.99951 21.2333 4.52453 18.6333 5.57456 16.2C6.62459 13.7667 8.04964 11.65 9.84969 9.85C11.6498 8.05 13.7665 6.625 16.1999 5.575C18.6333 4.525 21.2334 4 24.0001 4C26.7669 4 29.367 4.525 31.8004 5.575C34.2338 6.625 36.3505 8.05 38.1506 9.85C39.9506 11.65 41.3757 13.7667 42.4257 16.2C43.4757 18.6333 44.0008 21.2333 44.0008 24C44.0008 26.7667 43.4757 29.3667 42.4257 31.8C41.3757 34.2333 39.9506 36.35 38.1506 38.15C36.3505 39.95 34.2338 41.375 31.8004 42.425C29.367 43.475 26.7669 44 24.0001 44ZM24.0001 40C28.4669 40 32.2504 38.45 35.3505 35.35C38.4506 32.25 40.0006 28.4667 40.0006 24C40.0006 19.5333 38.4506 15.75 35.3505 12.65C32.2504 9.55 28.4669 8 24.0001 8C19.5333 8 15.7499 9.55 12.6498 12.65C9.54969 15.75 7.99964 19.5333 7.99964 24C7.99964 28.4667 9.54969 32.25 12.6498 35.35C15.7499 38.45 19.5333 40 24.0001 40Z"
        fill="#E31D40"
      />
    </g>
  </svg>
);

export default ErrorIcon;
