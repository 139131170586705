const useFacebook = () => {
  const init = (cb: () => void) => {
    window.FB.init({
      appId: process.env.OAUTH_FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v12.0'
    });

    window.FB.AppEvents.logPageView();

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    cb();
  };

  const onLogin = (
    cb: (failed: boolean, success: { accessToken: string } | null) => void
  ) => {
    init(() => {
      window.FB.login(
        (response: { authResponse: { accessToken: string } }) => {
          if (response.authResponse) {
            cb(false, response.authResponse);
          } else {
            cb(true, null);
          }
        },
        { scope: 'email' }
      );
    });
  };

  return { onLogin };
};

export default useFacebook;
