/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { RETURN_URI_KEY_STORAGE } from '../constants/string';

export const monthLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const dayLong = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const addZero = (value: string) => {
  return Number(value) < 10 ? `0${value}` : `${value}`;
};

export const onConvToDateServer = (value: string) => {
  const dt = new Date(value.split('/').reverse().join('-'));
  return `${dt.getFullYear()}-${addZero(
    (dt.getMonth() + 1).toString()
  )}-${addZero(`${dt.getDate()}`)}`;
};

export const enableFacebookOauth =
  process.env.REACT_APP_ENABLE_FACEBOOK_OAUTH === 'true' ? true : false;
export const disable3rdPartyEULA =
  process.env.REACT_APP_DISABLE_3RD_PARTY_EULA === 'true' ? true : false;

export const canDisconnectPlatform =
  process.env.REACT_APP_CAN_DISCONNECT_PLATFORM === 'true' ? true : false;

export const delay = (cb: () => void, timeout = 500) => {
  cb();
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });
};

export const HandleFieldError = (
  fieldName: string,
  errMessage: string,
  setFieldErrors: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >,
  setErrorMessages: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >
) => {
  setFieldErrors((prevState) => ({ ...prevState, [fieldName]: true }));
  setErrorMessages((prevState) => ({
    ...prevState,
    [fieldName]: errMessage
  }));
};

export const returnUri = (url: string) => {
  const returnUrlParam = url;
  let returnUrl = null;
  if (
    returnUrlParam != null &&
    returnUrlParam.includes('/connect/authorize/callback')
  ) {
    returnUrl = returnUrlParam?.substring(
      returnUrlParam.indexOf('/connect'),
      returnUrlParam.length
    );
  }

  return returnUrl;
};

export const urlRedirection = (
  urlParam: string,
  redirectUrl: string,
  redirectNavigation: (url: string, body: any) => void,
  email?: string,
  setLogin?: boolean,
  isPopupOpen?: boolean
) => {
  const options =
    email && email !== ''
      ? {
          replace: true,
          state: {
            email: email
          }
        }
      : {
          replace: true
        };

  const setLoginData = (cb: () => void) => {
    if (setLogin) {
      localStorage.setItem('isLogin', JSON.stringify('true'));
    }
    cb();
  };

  if (urlParam.includes('/connect/authorize/callback')) {
    setLoginData(() => {
      window.location.assign(redirectUrl);
      sessionStorage.removeItem(RETURN_URI_KEY_STORAGE);
    });
  } else {
    const redirectUri = sessionStorage.getItem(RETURN_URI_KEY_STORAGE);
    if (redirectUri && isPopupOpen) {
      setLoginData(() => {
        window.location.assign(redirectUri);
        sessionStorage.removeItem(RETURN_URI_KEY_STORAGE);
      });
    } else {
      setLoginData(() => {
        redirectNavigation(redirectUrl, options);
      });
    }
  }
};

export const getEnvironmentName = () => {
  const environmentType = process.env.REACT_APP_TYPE;
  let environtmentName = '';
  switch (environmentType) {
    case 'dev':
      environtmentName = 'development';
      break;
    case 'stag':
      environtmentName = 'staging';
      break;
    case 'prod':
      environtmentName = 'release';
      break;
    default:
      environtmentName = environmentType as string;
      break;
  }
  return environtmentName;
};

export const isLocalEnv = () => {
  const env = process.env.REACT_APP_TYPE;
  return env === 'local' || env === 'dev';
};

export const isProductionEnv = () => {
  const env = process.env.REACT_APP_TYPE;
  return env === 'prod';
};

export const pad = (num: number, totalLength = 2) => {
  return String(num).padStart(totalLength, '0');
};

export const removeConsole = () => {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
};

export const getUrlParamFromUrl = (
  url: string,
  returnUrl: string | null,
  key: string
) => {
  let backToPortal = getUrlParameter(url, key);
  if (backToPortal == null && returnUrl != null) {
    backToPortal = getUrlParameter(returnUrl, key);
  }
  return backToPortal;
};

export const getUrlParameter = (url: string, name: string) => {
  const params = new URLSearchParams(url.split('?')[1]);
  return params.get(name);
};

export const isValidUsername = (input: string): boolean => {
  const allowedCharacters = /^[a-zA-Z0-9*\-._@+]+$/;
  return allowedCharacters.test(input);
};

/**
 * Formats a given number into a string with dots as thousand separators.
 * If the number exceeds 999,999, it returns "999.999+".
 *
 * @param num - The number to format.
 * @returns The formatted string.
 */
export const formatNumber = (num: number | undefined): string => {
  // Check if the number exceeds 999,999
  if (!num) return '-';

  if (num > 999999) {
    return '999,999+';
  }
  // Convert number to string and format with dots as thousand separators
  return num.toLocaleString('de-DE');
};
