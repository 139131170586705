import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import useCountdown from '@src/hooks/useCountdown';
import useToaster from '@src/hooks/useToaster';
import {
  EMAILVERIFICATION_LAST_SENT_KEY_STORAGE,
  EMAILVERIFICATION_SENT_KEY_STORAGE
} from '@src/common/constants/string';

const maxValue = 5 * 60; // 5 minutes

interface EmailVerificationState {
  emailSent: boolean;
  loading: boolean;
  onSendEmailVerification: (email: string | null) => Promise<void>;
  countdown: {
    minutes: number;
    seconds: number;
  };
}

const useEmailVerification = (
  state: { email: string } | undefined
): EmailVerificationState => {
  const [emailSent, setEmailSent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { setMessage } = useToaster();

  const checkVerifiedStatus = () => {
    const data = localStorage.getItem('isEmailVerified');
    if (data != null) {
      localStorage.removeItem('isEmailVerified');
      window.location.replace('/');
    }
  };
  const { startInterval, countdown } = useCountdown(
    maxValue,
    setEmailSent,
    EMAILVERIFICATION_SENT_KEY_STORAGE,
    EMAILVERIFICATION_LAST_SENT_KEY_STORAGE,
    checkVerifiedStatus
  );

  useEffect(() => {
    if (!state) {
      window.location.replace('/');
      return;
    }
  }, [state, emailSent]);

  const onSendEmailVerification = useCallback(async (email: string | null) => {
    const realEmail = email ?? state?.email;
    if (realEmail && realEmail != '') {
      sessionStorage.removeItem(EMAILVERIFICATION_SENT_KEY_STORAGE);
      sessionStorage.removeItem(EMAILVERIFICATION_LAST_SENT_KEY_STORAGE);
      setLoading(true);

      const config = {
        method: 'POST',
        url: `/spa/send-email-confirmation`,
        headers: {
          'content-type': 'application/json'
        },
        credentials: 'include',
        data: JSON.stringify(realEmail)
      };

      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            sessionStorage.setItem(EMAILVERIFICATION_SENT_KEY_STORAGE, 'true');
            sessionStorage.setItem(
              EMAILVERIFICATION_LAST_SENT_KEY_STORAGE,
              Date.now().toString()
            );
            startInterval();
          }
          setMessage({
            render: `Successfully send the verification email to your email`,
            variant: 'success'
          });
          setLoading(false);
        })
        .catch((err) => {
          setMessage({
            render: err.message,
            variant: 'error'
          });
          setLoading(false);
          setEmailSent(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    emailSent,
    loading,
    onSendEmailVerification,
    countdown: countdown
  };
};

export default useEmailVerification;
