import { Button } from '@jupiter/react-common-component';
import React from 'react';
import styles from './ResetPassword.module.scss';

const ResetPasswordSuccess: React.FC = () => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['body-container']}>
        <p className={styles['title']}>Success!</p>

        <div className={styles['body-wrapper']}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.6362 36.2181L7.99951 24.5817L12.0724 20.509L19.6362 28.0726L35.9277 11.7817L40.0005 15.8545L19.6362 36.2181Z"
              fill="#04963E"
            />
          </svg>
        </div>
        <div className={styles['body-wrapper']}>
          <p className={styles['description']}>
            Your password was <strong>successfully</strong> changed. <br />
          </p>
        </div>
        <div className={styles['button-wrapper']}>
          <Button
            label="Sign In"
            size="responsive"
            variant={'default-magenta'}
            isLoading={false}
            onClick={() => {
              window.location.replace('/login');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
