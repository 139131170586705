import { getDefaultConfig } from 'connectkit';
import { createPublicClient } from 'viem';
import { createConfig, http } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { FZ_MINT_DEV } from '../ABIs';

const currentNetwork = process.env.REACT_APP_DEFAULT_NETWORK;
export const FZMINT_ADDRESS =
  currentNetwork === '1'
    ? '0x80e8FC2cD3c7ABA04B6F5fF5c5c574D01FFEf931'
    : '0x525C84fC52e34ab21074059a3d5A2b9199967733';

export const FZMINT_ABI = FZ_MINT_DEV;

export const publicClient = createPublicClient({
  chain: currentNetwork === '1' ? mainnet : sepolia,
  transport: http()
});

export const networkDefault = currentNetwork === '1' ? mainnet : sepolia;
export const defaultConfig = getDefaultConfig({
  // Your dApps chains
  chains: [networkDefault],
  transports: {
    // RPC URL for each chain
    [networkDefault.id]: http()
  },

  // Required API Keys
  walletConnectProjectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID!,

  // Required App Info
  appName: 'Confiction Labs Account',

  // Optional App Info
  appDescription:
    'Manage your Confiction Labs account. Access various products and perzonalize your profiles.',
  appUrl:
    'https://account-asset.mythicprotocol.com/assets/img/png/account-v2-cover.png', // your app's url
  appIcon:
    'https://account-asset.mythicprotocol.com/assets/img/png/account-v2-cover.png' // your app's icon, no bigger than 1024x1024px (max. 1MB)
});

export const configDefault = createConfig(defaultConfig);
