import React, { useEffect, Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';
import axios, { AxiosError } from 'axios';
// import useToaster from '@src/hooks/useToaster';
import { AppDispatch } from '@src/store';
import { useDispatch } from 'react-redux';
import { setIsLogin } from '@src/domain/User/User.reducer';
import LogoutSuccess from '@src/components/molecules/LogoutSuccess/LogoutSuccess';
import { RETURN_URI_KEY_STORAGE } from '@src/common/constants/string';

const Logout = () => {
  const [searchParams] = useSearchParams();
  // const { setMessage } = useToaster();
  const dispatch: AppDispatch = useDispatch();
  const [showLogoutNotice, setShowLogoutNotice] = useState<boolean>(false);

  const LOGOUT_ID = searchParams.get('logoutId');

  const processLogout = async () => {
    try {
      const response = await axios.get('/spa/logout', {
        params: {
          logoutId: LOGOUT_ID
        }
      });
      showLogoutSuccess();
      if (response.status === 200) {
        if (response.data?.postLogoutRedirectUri) {
          setTimeout(() => {
            window.location.assign(response.data.postLogoutRedirectUri);
          }, 1000);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        showLogoutSuccess();
      }
    }
  };

  const showLogoutSuccess = () => {
    dispatch(setIsLogin(false));
    setShowLogoutNotice(true);
    sessionStorage.removeItem(RETURN_URI_KEY_STORAGE);
    localStorage.removeItem('isLogin');
  };

  useEffect(() => {
    processLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!showLogoutNotice ? <FullPageLoading /> : <LogoutSuccess />}
    </Fragment>
  );
};

export default Logout;
