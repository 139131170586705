/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@src/store';
import { setReturnUrl } from '@src/domain/Oidc/Oidc.reducer';
import {
  BACK_TO_PORTAL_KEY_PARAM,
  BACK_TO_PORTAL_KEY_STORAGE,
  RETURN_URI_KEY_STORAGE,
  RETURN_URL_KEY_PARAM
} from '@src/common/constants/string';
import { getUrlParamFromUrl, getUrlParameter } from '@src/common/utils/utils';

const OidcProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const returnUri = getUrlParameter(window.location.href, RETURN_URL_KEY_PARAM);
  console.log('returnUri: ', returnUri);
  const backToPortalParam = getUrlParamFromUrl(
    window.location.href,
    returnUri,
    BACK_TO_PORTAL_KEY_PARAM
  );
  console.log('backToPortalParam: ', backToPortalParam);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (returnUri) {
      dispatch(setReturnUrl(returnUri));

      sessionStorage.setItem(RETURN_URI_KEY_STORAGE, returnUri);
    }
    if (backToPortalParam) {
      sessionStorage.setItem(BACK_TO_PORTAL_KEY_STORAGE, backToPortalParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnUri, backToPortalParam]);

  return <>{children}</>;
};

export default OidcProvider;
