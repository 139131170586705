import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '@src/store';
import { setIsLogin } from '@src/domain/User/User.reducer';

const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch'
};
interface Props {
  url: string;
  method?: keyof typeof methods;
  credentials?: string;
}

const useLazyFetch = <ResultType>({
  url,
  method = 'GET',
  credentials
}: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const { user: UserStore } = useSelector((state: RootState) => ({
    user: state.user
  }));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResultType>();
  const [arrData] = useState<{ key: string; value: string }[]>();
  const [error, setError] = useState<AxiosResponse>();

  useEffect(() => {
    if (error?.statusText === 'Unauthorized') {
      window.location.assign('/login');
      dispatch(setIsLogin(false));
    }
  }, [error, dispatch]);

  const onAction = useCallback(
    (
      body: unknown = {},
      cb?: (success?: AxiosResponse, error?: AxiosResponse) => void
    ) => {
      setLoading(true);
      let bodyData: unknown = null;
      if (body instanceof Object) {
        let isFormData = false;
        Object.keys(body).forEach((key) => {
          if (!isFormData && body[key as keyof typeof body] instanceof File) {
            isFormData = true;
          }
        });
        if (isFormData) {
          const formd = new FormData();
          for (const key in body) {
            const keyName = key as keyof typeof body;
            if (body[keyName] instanceof File) {
              formd.append(
                keyName,
                body[keyName] as unknown as File,
                body[keyName].name
              );
            } else {
              formd.append(keyName, body[keyName] as unknown as string);
            }
          }
          bodyData = formd;
        } else {
          bodyData = body;
        }
      } else {
        bodyData = body;
      }
      const newUrl = url;
      let headers: {
        'Content-Type'?: string;
        Authorization?: string;
      } = {};
      if (!(bodyData instanceof FormData)) {
        headers = { ...headers, 'Content-Type': 'application/json' };
      }
      const getLocalData = localStorage.getItem(
        `oidc.user:${process.env.SSO_DOMAIN}:${process.env.SSO_CLIENT_ID}`
      );
      if (getLocalData) {
        const accessToken = JSON.parse(getLocalData).access_token;
        headers = { ...headers, Authorization: `Bearer ${accessToken}` };
      }
      axios({
        url: newUrl,
        method,
        headers,
        ...(method === 'GET' ? { params: bodyData } : { data: bodyData }),
        ...(credentials ? { credentials } : {})
      })
        .then((res: AxiosResponse) => {
          setLoading(false);
          if (cb) {
            setData(res.data as ResultType);
            cb && cb(res, undefined);
          } else {
            if (res?.data?.redirectUrl) {
              window.location.replace(res?.data?.redirectUrl);
            }
          }
        })
        .catch((err: AxiosError) => {
          setError(err.response);
          setLoading(false);
          cb && cb(undefined, err.response);
        });
    },
    [credentials, method, url]
  );

  return [onAction, { loading, data, arrData, error }] as const;
};

export default useLazyFetch;
