import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IToasterState } from './toaster.type';

const initialState: IToasterState = {
  render: undefined,
  show: false,
  position: {
    top: 132,
    left: '50%',
    transform: 'translate(-50%)'
  },
  timeout: 3000,
  action: undefined,
  variant: 'default'
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    setToaster: (state, action: PayloadAction<IToasterState>) => {
      state.render = action.payload.render;
      state.show = action.payload.show;
      state.position = action.payload.position ?? {
        top: 70,
        left: '50%',
        transform: 'translate(-50%)'
      };
      state.timeout = action.payload.timeout ?? 3000;
      state.action = action.payload.action;
      state.variant = action.payload.variant;
    }
  }
});

export const { setToaster } = toasterSlice.actions;

export default toasterSlice.reducer;
