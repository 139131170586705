import DashboardLayout from '../components/layouts/dashboard/DashboardLayout';
import { lazy } from 'react';
import NotFoundPage from './404/NotFoundPage';
import MyProfile from './MyProfile/MyProfile';
import Callback from './Callback/Callback';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import ResetPasswordSuccessPage from './ResetPasswordPage/ResetPasswordSuccessPage';
import Logout from './Logout/Logout';
import { RouteConfig } from '.';

import SignInPage from '@src/pages/SignInPage/SignInPage';
import SignUpPage from '@src/pages/SignUpPage/SignUpPage';
import OIDCLoading from '@src/components/atoms/LoadingScreen/OIDCLoading';
import OIDCSuccess from '@src/components/atoms/LoadingScreen/OIDCSuccess';
import SSOCallback from './SSOCallback/SSOCallback';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import BindCallback from './BindCallback/BindCallback';
import TestingPage from './Testing';
import TestingCallbackPage from './Testing/testingCallback';
// const SignInPage = lazy(() => import('@src/pages/SignInPage/SignInPage'));
// const SignUpPage = lazy(() => import('@src/pages/SignUpPage/SignUpPage'));
const EmailConfirmedPage = lazy(
  () => import('@src/pages/EmailVerifiedPage/EmailVerifiedPage')
);
const EmailVerificationPage = lazy(
  () => import('@src/pages/EmailVerificationPage/EmailVerificationPage')
);
const EmailVerificationCallback = lazy(
  () => import('@src/pages/EmailVerificationPage/EmailVerificationCallback')
);
const ForgotPasswordPage = lazy(
  () => import('@src/pages/ForgotPasswordPage/ForgotPasswordPage')
);
const NewPasswordPage = lazy(
  () => import('@src/pages/NewPasswordPage/NewPasswordPage')
);
const WalletPage = lazy(() => import('@src/pages/WalletPage/WalletPage'));
const SecurityPage = lazy(() => import('@src/pages/SecurityPage/SecurityPage'));
const ChangePassword = lazy(
  () => import('@src/pages/ChangePassword/ChangePassword')
);
const InvalidToken = lazy(() => import('@src/pages/InvalidToken/InvalidToken'));
const Error = lazy(() => import('@src/pages/ErrorPage/Error'));
const InvetoryPage = lazy(
  () => import('@src/pages/InventoryPage/InventoryPage')
);

const routes: RouteConfig[] = [
  {
    name: 'main',
    path: '/',
    Component: DashboardLayout,
    key: 'main',
    routes: [
      {
        key: 'home',
        name: 'home',
        path: '/',
        index: true,
        requireAuth: true,
        Component: MyProfile
      },
      {
        key: 'login',
        path: '/login',
        requireAuth: true,
        Component: SignInPage,
        lazy: true
      },
      {
        key: 'register',
        path: '/register',
        requireAuth: true,
        Component: SignUpPage,
        lazy: true
      },
      {
        key: 'profile',
        path: '/profile',
        requireAuth: true,
        Component: MyProfile
      },
      {
        key: 'wallet',
        path: '/wallet',
        requireAuth: true,
        Component: WalletPage,
        lazy: true
      },
      {
        key: 'security',
        path: '/security',
        requireAuth: true,
        Component: SecurityPage,
        lazy: true
      },
      {
        key: 'inventory',
        path: '/inventory',
        requireAuth: true,
        Component: InvetoryPage,
        lazy: true
      },
      {
        key: 'change-password',
        path: '/security/password',
        requireAuth: true,
        Component: ChangePassword,
        lazy: true
      },
      {
        key: 'forgot-password',
        path: '/forgot-password',
        Component: ForgotPasswordPage,
        lazy: true
      },
      {
        key: 'new-password',
        path: '/new-password',
        requireAuth: true,
        Component: NewPasswordPage,
        lazy: true
      },
      {
        key: 'callback-auth',
        path: '/auth/:id/callback',
        Component: Callback
      },
      {
        key: 'callback-bind',
        path: '/bind/:id/callback',
        requireAuth: true,
        Component: BindCallback
      },
      {
        key: 'confirm-email',
        path: '/confirm-email',
        requireAuth: false,
        Component: EmailVerificationPage,
        lazy: true
      },
      {
        key: 'check-email-confirmation',
        path: '/check-email-confirmation',
        Component: EmailVerificationCallback,
        props: {
          provider: 'email'
        },
        lazy: true
      },
      {
        key: 'check-wallet-email-confirmation',
        path: '/check-wallet-email-confirmation',
        Component: EmailVerificationCallback,
        props: {
          provider: 'wallet'
        },
        lazy: true
      },
      {
        key: 'check-external-email-confirmation',
        path: '/check-external-email-confirmation',
        Component: EmailVerificationCallback,
        props: {
          provider: 'external'
        },
        lazy: true
      },
      {
        key: 'confirmed-email',
        path: '/confirmed-email',
        Component: EmailConfirmedPage
      },
      {
        key: 'reset-password',
        path: '/reset-password',
        Component: ResetPasswordPage
      },
      {
        key: 'reset-password-success',
        path: '/reset-password-success',
        Component: ResetPasswordSuccessPage
      },
      {
        key: 'forgot-password-confirmation',
        path: '/forgot-password-confirmation',
        Component: ResetPasswordPage
      },
      {
        key: 'agreement',
        path: '/agreement',
        Component: PrivacyPolicy
      },
      {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        Component: PrivacyPolicy
      },
      {
        key: 'logout',
        path: '/logout',
        Component: Logout
      },
      {
        key: 'invalid-token',
        path: '/invalid-token',
        Component: InvalidToken,
        lazy: true
      },
      {
        key: 'error',
        path: '/error',
        Component: Error,
        lazy: true
      },
      {
        name: 'connect/authorize/callback',
        path: '/connect/authorize/callback',
        Component: OIDCLoading,
        key: 'authorize'
      },
      {
        name: 'oidc-success',
        path: '/oidc/success',
        Component: OIDCSuccess,
        key: 'oidc-success'
      },
      {
        name: '404',
        path: '*',
        Component: NotFoundPage,
        key: '404'
      },
      {
        name: 'sso-callback',
        path: '/sso/callback',
        Component: SSOCallback
      },
      {
        name: 'steam-testing-sso',
        path: '/steam-testing-sso',
        Component: TestingPage
      },
      {
        name: 'steam-testing-sso-callback',
        path: '/steam-testing-sso-callback',
        Component: TestingCallbackPage
      }
    ]
  }
];

export default routes;
