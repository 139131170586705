import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import InventoryIcon from '@src/components/Icons/InventoryIcon';
import Person from '@src/components/Icons/Person';
import SecurityIcon from '@src/components/Icons/SecurityIcon';
import WalletIcon from '@src/components/Icons/WalletIcon';

import { IMenu } from '@src/components/molecules/SidebarV2/SidebarV2.type';

export const defaultProfilePicture = `${
  process.env.REACT_APP_BUCKET_URL ?? ''
}/assets/img/png/profile-account.png`;

export const menu: (
  navigate: NavigateFunction,
  walletAddress?: string
) => IMenu[] = (navigate, walletAddress) => [
  {
    name: 'Account',
    icon: <Person />,
    child: [
      {
        name: 'Profile',
        path: '/',
        onClick: () => {
          return navigate('/');
        },
        icon: <Person />
      },
      {
        name: 'Wallet',
        path: '/wallet',
        onClick: () => {
          return navigate('/wallet');
        },
        icon: <WalletIcon />
      },
      // { name: 'Settings' },
      {
        name: 'Security',
        path: '/security',
        onClick: () => {
          return navigate('/security');
        },
        icon: <SecurityIcon />
      },
      {
        name: 'Inventory',
        path: '/inventory',
        disabled: !walletAddress,
        onClick: () => {
          if (walletAddress) return navigate('/inventory');
        },
        icon: <InventoryIcon />
      }
    ]
  }
  // {
  //   name: 'Assets',
  //   disabled: true,
  //   icon: <Locked />,
  //   child: [
  //     {
  //       name: 'Default Profile',
  //       icon: <Square />,
  //       child: [{ name: 'Profile' }, { name: 'Wallet' }, { name: 'Settings' }]
  //     }
  //   ]
  // }
];
