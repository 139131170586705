import useModal from '@src/hooks/useModal';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import styles from './Error.module.scss';
import ErrorIcon from '@src/components/Icons/ErrorIcon';
import { Button } from '@jupiter/react-common-component';

const ErrorModal = ({ error }: { error: any }) => {
  const { closeModal } = useModal();
  return (
    <>
      <div className={styles['title']}>Error</div>
      <div className={styles['icon']}>
        <ErrorIcon />
      </div>
      <div className={styles['content']}>
        Something went wrong, please try again later.
      </div>
      <p style={{ display: 'none' }}>{error ? error.toString() : ''}</p>
      <div style={{ display: 'none' }}>
        {error.componentStack ? error.componentStack : ''}
      </div>
      <pre style={{ display: 'none' }}>{error.message}</pre>
      <div className={styles['button']}>
        <Button
          label={'Close'}
          size={'responsive'}
          isLoading={false}
          variant={'default-magenta'}
          onClick={() => closeModal()}
        />
      </div>
    </>
  );
};

const Error: React.FC<FallbackProps> = ({ error }) => {
  const { showModal } = useModal();
  useEffect(() => {
    showModal(<ErrorModal error={error} />);
  }, [error, showModal]);
  return <></>;
};

export default Error;
