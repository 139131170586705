import * as React from 'react';
import { SVGProps } from 'react';

const PointIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.076 0c1.83 0 3.275.505 4.335 1.514C16.47 2.504 17 3.857 17 5.571c0 .439-.02.772-.058 1-.154 1.277-.587 2.4-1.3 3.372a6.953 6.953 0 0 1-2.717 2.257c-1.098.533-2.331.8-3.699.8H6.278c-.096 0-.163.057-.202.171l-.78 6.4a.47.47 0 0 1-.174.315.432.432 0 0 1-.317.114H.383a.432.432 0 0 1-.318-.114c-.058-.076-.077-.181-.058-.315L2.406.43A.535.535 0 0 1 2.55.114.432.432 0 0 1 2.868 0h8.208ZM9.139 8.714c.733 0 1.34-.21 1.821-.628.501-.438.751-1 .751-1.686 0-.552-.192-.99-.578-1.314-.366-.324-.866-.486-1.502-.486H7.319c-.097 0-.164.057-.203.171l-.462 3.772c0 .114.048.171.145.171h2.34Z"
      fill="#C4964D"
    />
  </svg>
);

export default PointIcon;
