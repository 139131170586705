import ButtonLogin from '@src/components/atoms/ButtonLogin/ButtonLogin';
import React from 'react';
import styles from './LoginOptions.module.scss';

type ILoginOptions = {
  onBtnGoogleClick?: () => void;
  onBtnTwitterClick?: () => void;
  onBtnSteamClick?: () => void;
  onBtnMetamaskClick?: () => void;
};

const LoginOptions: React.FC<ILoginOptions> = ({
  onBtnGoogleClick,
  onBtnTwitterClick,
  onBtnSteamClick,
  onBtnMetamaskClick
}) => {
  return (
    <>
      <div className={styles['button-login-wrapper']}>
        <div className={styles['another-login-wrapper']}>
          <ButtonLogin type="google" onButtonClick={onBtnGoogleClick} />
        </div>
        <div className={styles['another-login-wrapper']}>
          <ButtonLogin type="x" onButtonClick={onBtnTwitterClick} />
        </div>
        <div className={styles['another-login-wrapper']}>
          <ButtonLogin type="steam" onButtonClick={onBtnSteamClick} />
        </div>
        <div className={styles['another-login-wrapper']}>
          <ButtonLogin type="metamask" onButtonClick={onBtnMetamaskClick} />
        </div>
      </div>
    </>
  );
};

export default LoginOptions;
