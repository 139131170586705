import { delay } from '@src/common/utils/utils';
import { setToaster } from '@src/domain/toaster/toaster.reducer';
import { IConfig } from '@src/domain/toaster/toaster.type';
import { AppDispatch } from '@src/store';
import { useDispatch } from 'react-redux';

const useToaster = () => {
  const dispatch: AppDispatch = useDispatch();

  const setMessage = (
    props: {
      render: React.ReactNode;
      action?: React.ReactNode;
      variant?: 'default' | 'success' | 'error';
    },
    config?: IConfig
  ) => {
    const { variant = 'default' } = props;
    // dispatch(setToaster({ ...props, ...config, show: true }));
    delay(() => {
      dispatch(setToaster({ ...props, ...config }));
    }).then(() => {
      dispatch(
        setToaster({
          ...props,
          ...config,
          show: true,
          variant: variant
        })
      );
    });
  };

  return { setMessage };
};

export default useToaster;
