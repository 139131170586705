import { RETURN_URI_KEY_STORAGE } from '@src/common/constants/string';
import { checkAuth } from '@src/domain/User/User.reducer';
import { getUserProfile } from '@src/domain/User/User.thunk';
import { AppDispatch, RootState } from '@src/store';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useRequireAuth = (): boolean => {
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userState.isCheckedSession) {
      dispatch(checkAuth());
    }
  }, [dispatch, pathname, userState.isCheckedSession]);

  useEffect(() => {
    const isRegisterOrLoginPage =
      ['/login', '/register'].findIndex((item) => item === pathname) >= 0;
    const isLoginData = localStorage.getItem('isLogin');
    const isAuthenticated: boolean =
      (isLoginData !== null && isLoginData !== undefined) || userState.isLogin;
    const returnUriExist = sessionStorage.getItem(RETURN_URI_KEY_STORAGE);

    if (userState.intialState.isLoading || !userState.isCheckedSession) {
      // eslint-disable-next-line no-console
      console.log(
        'called check',
        userState.intialState.isLoading,
        userState.isCheckedSession
      );
      // The authentication check is still in progress, do nothing for now
      return;
    }
    if (!isAuthenticated) {
      if (!isRegisterOrLoginPage) {
        window.location.replace('/login');
      }
      // Perform the necessary redirect or show an error message
    } else {
      if (isRegisterOrLoginPage) {
        if (!returnUriExist) {
          if (pathname !== '/new-password') {
            window.location.replace('/');
          }
        } else {
          window.location.assign(returnUriExist);
        }
      }
      if (!userState.data) {
        dispatch(getUserProfile());
      }
    }
  }, [
    dispatch,
    pathname,
    userState.data,
    userState.intialState.isLoading,
    userState.isCheckedSession,
    userState.isLogin
  ]);
  return userState.isLogin;
};

export default useRequireAuth;
