import React from 'react';
import ReactLoading from 'react-loading';
import styles from './FullPageLoading.module.scss';
import { IFullPageLoading } from './FullPageLoading.type';
import { Loading } from '@jupiter/react-common-component';

const FullPageLoading: React.FC<IFullPageLoading> = ({ title }) => {
  return (
    <>
      {title === 'none' ? (
        <ReactLoading
          className="global-smloader-center"
          type={'spin'}
          color={'#36aeae'}
          height={'20%'}
          width={'20%'}
        />
      ) : (
        <div className={styles['container']}>
          <Loading size={96} />
        </div>
      )}
    </>
  );
};

export default FullPageLoading;
