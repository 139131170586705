import useLazyFetch from '@src/hooks/useLazyFetch';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import SignupOauthFormMetamask from '../OauthFormMetamask/OauthFormMetamask';
import useEthereum from '@src/hooks/useEthereum';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { returnUri, urlRedirection } from '@src/common/utils/utils';
import { RETURN_URL_KEY_PARAM } from '@src/common/constants/string';

const SignInMetamask: React.FC<{
  render: (metamaskHandler: () => void) => React.ReactNode;
}> = ({ render }) => {
  const [isOpenMetamask, setIsOpenMetamask] = useState(false);
  const [searchParams] = useSearchParams();
  const { connectMetamask } = useEthereum();
  const navigate = useNavigate();

  const returnUrlParam = searchParams.get(RETURN_URL_KEY_PARAM);
  const [onRegisterWallet] = useLazyFetch({
    url: '/spa/wallet/register',
    method: 'POST'
  });

  const [onLogin] = useLazyFetch({
    url: `/spa/wallet/login`,
    method: 'POST',
    credentials: 'include'
  });

  const [metamaskForm, setMetamaskForm] = useState({
    walletAddress: '',
    nonce: '',
    signature: '',
    email: '',
    firstName: '',
    userName: '',
    country: '',
    lastName: '',
    returnUrl: returnUrlParam ? returnUri(returnUrlParam) : ''
  });

  const onSubmitName = (
    {
      firstname,
      lastname,
      username,
      country
    }: {
      firstname: string;
      lastname: string;
      username: string;
      country: string;
    },
    {
      setShowEmailForm,
      setShowNameForm
    }: {
      setShowEmailForm: React.Dispatch<React.SetStateAction<boolean>>;
      setShowNameForm: React.Dispatch<React.SetStateAction<boolean>>;
    }
  ) => {
    setMetamaskForm({
      ...metamaskForm,
      firstName: firstname,
      lastName: lastname,
      country: country,
      userName: username
    });
    setShowNameForm(false);
    setShowEmailForm(true);
  };

  const onSubmitEmail = (
    email: string,
    {
      setIsLoading,
      setShowEmailForm,
      setShowVerify,
      setErrApiResponse
    }: {
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
      setShowEmailForm: React.Dispatch<React.SetStateAction<boolean>>;
      setShowVerify: React.Dispatch<React.SetStateAction<boolean>>;
      setErrApiResponse: React.Dispatch<React.SetStateAction<string>>;
    }
  ) => {
    setMetamaskForm({ ...metamaskForm, email });
    setIsLoading(true);
    setShowEmailForm(false);
    onRegisterWallet({ ...metamaskForm, email }, (_, error) => {
      setTimeout(() => {
        setIsLoading(false);
        if (!error) {
          setShowVerify(true);
        } else {
          setShowEmailForm(true);
          if (error instanceof AxiosError) {
            setErrApiResponse(
              error?.response?.data?.message ?? 'Something went wrong!'
            );
          } else if (error.data) {
            setErrApiResponse(error?.data?.message ?? 'Something went wrong!');
          } else {
            setErrApiResponse('Something went wrong!');
          }
        }
      }, 5000);
    });
  };

  const metamaskHandler = () => {
    connectMetamask({ refreshNonce: false }, (err, result) => {
      if (!err) {
        if (result != null) {
          setMetamaskForm({
            ...metamaskForm,
            walletAddress: result.walletAddress,
            signature: result.signature ?? '',
            nonce: result.nonce ?? ''
          });
          onLogin(
            {
              walletAddress: result.walletAddress,
              signature: result.signature,
              returnUrl: metamaskForm.returnUrl,
              nonce: result.nonce
            },
            (success, error) => {
              if (!error) {
                if (
                  success?.data.popupName &&
                  success?.data.popupName === 'SubmitName'
                ) {
                  setIsOpenMetamask(true);
                } else {
                  const redirectUrl = success?.data.returnUrl ?? '/';
                  urlRedirection(
                    redirectUrl,
                    redirectUrl,
                    navigate,
                    undefined,
                    true,
                    true
                  );
                }
              }
            }
          );
          // walletSignIn(walletAddress, result);
        }
      }
    });
  };
  return (
    <>
      {render(metamaskHandler)}

      <SignupOauthFormMetamask
        username={`${metamaskForm.walletAddress.substring(0, 5)}...
        ${metamaskForm.walletAddress.substring(
          metamaskForm.walletAddress.length - 4,
          metamaskForm.walletAddress.length
        )}`}
        show={isOpenMetamask}
        setShow={setIsOpenMetamask}
        caseName="SubmitName"
        onSubmitName={onSubmitName}
        onSubmitEmail={onSubmitEmail}
      />
    </>
  );
};

export default SignInMetamask;
