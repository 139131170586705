import React from 'react';

const DiscordNewIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        fill="#5662F6"
      />
      <g clipPath="url(#clip0_4156_117257)">
        <circle cx="23.9998" cy="24" r="18.24" fill="#5662F6" />
        <path
          d="M32.9741 15.7623C31.332 14.9968 29.554 14.4412 27.702 14.1202C27.6858 14.1197 27.6696 14.1227 27.6547 14.1291C27.6397 14.1355 27.6264 14.1451 27.6156 14.1572C27.3933 14.5647 27.1341 15.0956 26.9612 15.503C24.9968 15.2067 22.9991 15.2067 21.0347 15.503C20.8619 15.0832 20.6026 14.5647 20.368 14.1572C20.3557 14.1325 20.3186 14.1202 20.2816 14.1202C18.4296 14.4412 16.664 14.9968 15.0095 15.7623C14.9972 15.7623 14.9848 15.7746 14.9725 15.787C11.6142 20.8121 10.6882 25.7014 11.145 30.5414C11.145 30.5661 11.1573 30.5908 11.182 30.6031C13.4044 32.2329 15.5404 33.2206 17.6517 33.875C17.6888 33.8873 17.7258 33.875 17.7382 33.8503C18.232 33.1712 18.6765 32.4551 19.0593 31.702C19.084 31.6526 19.0593 31.6032 19.0099 31.5908C18.3061 31.3192 17.6394 30.9982 16.985 30.6278C16.9356 30.6031 16.9356 30.529 16.9727 30.492C17.1085 30.3932 17.2443 30.2821 17.3801 30.1833C17.4048 30.1586 17.4418 30.1586 17.4665 30.171C21.7138 32.1094 26.2945 32.1094 30.4924 30.171C30.5171 30.1586 30.5541 30.1586 30.5788 30.1833C30.7146 30.2944 30.8504 30.3932 30.9862 30.5043C31.0356 30.5414 31.0356 30.6155 30.9739 30.6401C30.3319 31.0229 29.6528 31.3316 28.949 31.6032C28.8996 31.6155 28.8873 31.6773 28.8996 31.7143C29.2947 32.4675 29.7392 33.1836 30.2207 33.8627C30.2578 33.875 30.2948 33.8873 30.3319 33.875C32.4555 33.2206 34.5915 32.2329 36.8139 30.6031C36.8386 30.5908 36.851 30.5661 36.851 30.5414C37.3942 24.9483 35.9496 20.096 33.0235 15.787C33.0111 15.7746 32.9988 15.7623 32.9741 15.7623ZM19.7013 27.5905C18.4296 27.5905 17.3678 26.4176 17.3678 24.973C17.3678 23.5284 18.4049 22.3555 19.7013 22.3555C21.0101 22.3555 22.0472 23.5408 22.0348 24.973C22.0348 26.4176 20.9977 27.5905 19.7013 27.5905ZM28.307 27.5905C27.0353 27.5905 25.9735 26.4176 25.9735 24.973C25.9735 23.5284 27.0106 22.3555 28.307 22.3555C29.6158 22.3555 30.6529 23.5408 30.6405 24.973C30.6405 26.4176 29.6158 27.5905 28.307 27.5905Z"
          fill="white"
        />
      </g>
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        stroke="#2D3FA4"
        strokeWidth="0.5"
      />
      <defs>
        <clipPath id="clip0_4156_117257">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiscordNewIcon;
