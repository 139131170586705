import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalItem, IModalState } from './modal.type';

const initialState: IModalState = {
  modals: []
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addModal: (state, action: PayloadAction<IModalItem>) => {
      state.modals = [...state.modals, { ...action.payload, isClose: false }];
    },
    closeLastModal: (state) => {
      state.modals = [
        ...state.modals.map((item, i, self) => {
          if (i === self.length - 1) {
            return { ...item, isClose: true };
          }
          return item;
        })
      ];
    },
    removeLastModal: (state) => {
      state.modals = [
        ...state.modals.filter((_, i, self) => i != self.length - 1)
      ];
    },
    onClickBackgroundLastModal: (
      state,
      action: PayloadAction<React.MouseEventHandler<HTMLDivElement>>
    ) => {
      state.modals = [
        ...state.modals.map((item, i, self) => {
          if (i === self.length - 1) {
            return { ...item, onClickBg: action.payload };
          }
          return item;
        })
      ];
    }
  }
});

export const {
  removeLastModal,
  addModal,
  closeLastModal,
  onClickBackgroundLastModal
} = modalSlice.actions;

export default modalSlice.reducer;
