import React from 'react';
import styles from './ButtonLogin.module.scss';
import {
  GoogleIcon,
  MetamaskIcon,
  DiscordIcon,
  TwitterIcon,
  SteamIcon,
  XIcon
} from '@src/components/Icons/ButtonIcon';

type IButtonLogin = {
  type?: 'google' | 'metamask' | 'discord' | 'twitter' | 'steam' | 'x';
  onButtonClick?: () => void;
};

const ButtonLogin: React.FC<IButtonLogin> = ({
  type = 'google',
  onButtonClick
}) => {
  return (
    <div
      className={`${styles['button-wrapper']} ${styles[type]}`}
      onClick={onButtonClick}
      data-testid="button"
    >
      {type === 'google' && <GoogleIcon />}
      {type === 'metamask' && <MetamaskIcon />}
      {type === 'twitter' && <TwitterIcon />}
      {type === 'discord' && <DiscordIcon />}
      {type === 'steam' && <SteamIcon />}
      {type === 'x' && <XIcon />}
    </div>
  );
};

export default ButtonLogin;
