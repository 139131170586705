import React from 'react';
import styles from './FullPageLoading.module.scss';
import CheckSuccessIcon from '@src/components/Icons/CheckSuccessIcon';

const OIDCSuccess = () => {
  return (
    <>
      <div
        style={{ width: '450px !required;' }}
        className={styles['container']}
      >
        <div className={styles['content-container']}>
          <div className={styles['content-title']}>
            <p>Done</p>
          </div>
          <CheckSuccessIcon />
          <div className={styles['content-description']}>
            <p>You can close this tab/window now.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OIDCSuccess;
