import CopyFile from '@src/components/Icons/CopyFile';
import LogoutIcon from '@src/components/Icons/LogoutIcon';
import BackToPortal from '@src/components/hoc/BackToPortal';
import React, { useEffect } from 'react';
import Logo from './Logo';
import styles from './SidebarV2.module.scss';
import { IMenu } from './SidebarV2.type';
import MenuItem from './contents/MenuItem/MenuItem';

const SidebarV2: React.FC<{
  name: string;
  email: string;
  tag: string;
  walletAddress: string;
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
  menu: IMenu[];
}> = ({ name, email, tag, walletAddress, isOpen, onClose, onLogout, menu }) => {
  const [isActive, setIsActive] = React.useState<string[]>([]);

  const checkIfDisabled = (keyName: string) => {
    const keys = keyName.split('/');
    if (keys.length > 0) {
      return menu[menu.findIndex((item) => item.name === keys[0])].disabled;
    }

    return false;
  };

  const onMenuControl = (keyName: string) => {
    const keys = keyName.split('/');

    if (keys.length > 0) {
      if (isActive.findIndex((item) => item === keyName) >= 0) {
        setIsActive((prev) => prev.filter((item) => item !== keyName));
      } else {
        setIsActive([...isActive, keyName]);
      }
    }
  };

  const searchPath: (
    value: IMenu[],
    keyName?: string,
    activePresent?: string[]
  ) => string[] = React.useCallback(
    (value, keyName = '', activePresent = []) => {
      let newActivePresent: string[] = activePresent;
      for (let j = 0; j < value.length; j++) {
        const item = value[j];
        let pathname = window.location.pathname.toLowerCase();
        if (pathname === '/profile') {
          pathname = '/';
        }
        if (item.path && item.path === pathname) {
          const curKeyName = `${keyName}/${item.name}`;
          const keys = curKeyName.split('/');
          for (let i = 0; i < keys.length; i++) {
            const kn = keys.filter((_, j) => j <= i).join('/');
            newActivePresent = [...newActivePresent, kn];
          }
        } else {
          if (item.child && item.child.length > 0) {
            newActivePresent = [
              ...newActivePresent,
              ...searchPath(
                item.child,
                `${keyName === '' ? '' : `${keyName}/`}${item.name}`,
                activePresent
              )
            ];
          }
        }
      }
      return newActivePresent;
    },
    []
  );

  useEffect(() => {
    setIsActive([...searchPath(menu)]);
    window.addEventListener(
      'popstate',
      function () {
        setIsActive([...searchPath(menu)]);
      },
      false
    );
  }, [menu, searchPath]);

  return (
    <>
      <div
        className={`${styles['bg-blur']}  ${isOpen ? styles['is-open'] : ''}`}
        onClick={() => onClose()}
      ></div>
      <div
        className={`${styles['container']} ${isOpen ? styles['is-open'] : ''}`}
      >
        <div className={styles['header-account']}>
          <div className={styles['account']}>
            <div className={styles['account-id']}>
              <div className={styles['account-name']}>
                {name}
                <span className={styles['account-tag']}>{tag}</span>
              </div>
              <div className={styles['account-email']}>{email}</div>
            </div>
            <div className={styles['account-action']} onClick={() => onClose()}>
              <div className={styles['close-sidebar']}></div>
            </div>
          </div>
          {walletAddress && (
            <div className={styles['wallet-id']}>
              <div className={styles['wallet-address']}>{walletAddress}</div>
              <CopyFile
                onClick={() => navigator.clipboard.writeText(walletAddress)}
                className={styles['wallet-copy']}
              />
            </div>
          )}
          <div className={styles['back-to-portal']}>
            <BackToPortal />
          </div>
        </div>
        <div className={styles['header']}>
          <div className={styles['icon']}>
            <Logo />
          </div>
          <div className={styles['title']}>My Account</div>
        </div>

        {menu.map((item: IMenu, key: number) => (
          <React.Fragment key={key}>
            <div className={`${styles['divider']} ${styles['sm']}`}></div>
            <div
              className={`${styles['menu-group']} ${
                item.disabled ? styles['disabled'] : ''
              }`}
            >
              <MenuItem
                item={item}
                keyName={item.name}
                activeList={isActive}
                checkIfDisabled={checkIfDisabled}
                onMenuControl={onMenuControl}
                last={menu.length - 1 === key}
                onClose={onClose}
              />
            </div>
          </React.Fragment>
        ))}

        <div
          className={`${styles['divider']} ${styles['mobile-logout']}`}
        ></div>

        <div className={`${styles['menu-group']} ${styles['mobile-logout']}`}>
          <div className={styles['menu-item']}>
            <div
              className={`${styles['menu-control']} ${styles['dropdown-control']}`}
              onClick={() => onLogout()}
            >
              <div className={styles['menu-icon']}>
                <LogoutIcon />
              </div>
              <div className={styles['menu-label']}>Log out</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarV2;
