import { configureStore } from '@reduxjs/toolkit';
import sliderReducer from './domain/slider/slider.reducer';
import uiReducer from './domain/ui/ui.reducer';
import navigationReducer from './domain/navigation/navigation.reducer';
import UserReducer from './domain/User/User.reducer';
import modalReducer from './domain/modal/modal.reducer';
import toasterReducer from './domain/toaster/toaster.reducer';
import oidcReducer from './domain/Oidc/Oidc.reducer';
import UserFeedbackReducer from './domain/UserFeedback/UserFeedback.reducer';
import ModalSteamReducer from './domain/ModalSteam/ModalSteam.reducer';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    slider: sliderReducer,
    navigation: navigationReducer,
    modal: modalReducer,
    toaster: toasterReducer,
    user: UserReducer,
    oidc: oidcReducer,
    userFeedback: UserFeedbackReducer,
    modalSteam: ModalSteamReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
