import React, { useEffect, useState, useRef } from 'react';
import styles from './ModalV2.module.scss';
import { IModalItem } from '@src/domain/modal/modal.type';

const ModalV2: React.FC<IModalItem> = ({
  content,
  onClickBg,
  isClose = false
}) => {
  const [activeBg, setActiveBg] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveBg(true);
  }, []);

  useEffect(() => {
    setActiveModal(true);
  }, []);

  useEffect(() => {
    if (isClose) {
      setActiveModal(false);
      setActiveBg(false);
    }
  }, [isClose]);

  return (
    <div className={styles['bg-fixed']}>
      <div
        className={`${styles['bg']} ${activeBg ? styles['active'] : ''}`}
        onClick={onClickBg}
      ></div>
      <div
        className={`${styles['modal']} ${activeModal ? styles['active'] : ''}`}
        ref={modalRef}
        style={{
          ...(modalRef.current && !activeModal
            ? { top: -modalRef.current.offsetHeight }
            : {})
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default ModalV2;
