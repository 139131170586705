import React from 'react';

const InventoryIcon: React.FC<{ color?: string }> = ({ color = '#E4E4E4' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 7473">
        <g id="grid_view">
          <path d="M7.75 6.25V0.25H13.75V6.25H7.75Z" fill={color} />
          <path d="M8.25 5.75V0.75H13.25V5.75H8.25Z" stroke={color} />
        </g>
        <g id="grid_view_2">
          <path d="M0.25 7.75V13.75H6.25V7.75H0.25Z" fill={color} />
          <path d="M5.75 13.25H0.75V8.25H5.75V13.25Z" stroke={color} />
        </g>
        <g id="grid_view_3">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1V5.5H5.5V1H1ZM0.25 0.25H6.25V6.25H0.25V0.25Z"
            fill={color}
          />
        </g>
        <path
          id="grid_view (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 1H5.5V5.5H1V1ZM0.25 0.25V6.25H6.25V0.25H0.25Z"
          fill={color}
        />
        <path
          id="grid_view (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 8.5V13H13V8.5H8.5ZM7.75 7.75H13.75V13.75H7.75V7.75Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default InventoryIcon;
