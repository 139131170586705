/* eslint-disable no-console */
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import SignIn from '@src/components/molecules/SignIn/SignIn';

import { useDispatch, useSelector } from 'react-redux';
import styles from './SignInPage.module.scss';

import SignInMoreOption from '@src/components/molecules/SignInMoreOption/SignInMoreOption';
import { AppDispatch, RootState } from '@src/store';
import ReCAPTCHA from 'react-google-recaptcha';
import SignInMetamask from '@src/components/molecules/SignInMetamask/SignInMetamask';
import { useTwitterData } from '@src/hooks/useTwitterData';
import {
  HandleFieldError,
  returnUri,
  urlRedirection
} from '@src/common/utils/utils';
import { getUserProfile } from '@src/domain/User/User.thunk';
import useToaster from '@src/hooks/useToaster';
import { setUserLoading } from '@src/domain/User/User.reducer';
import {
  RETURN_URI_KEY_STORAGE,
  RETURN_URL_KEY_PARAM
} from '@src/common/constants/string';
import { logEvent } from '@src/domain/Analytic/Analythic';
import { AnalyticsEvent } from '@src/domain/Analytic/Analythic.type';

// enum Providers {
//   Discord,
//   Google
// }

const SignInPage = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { oidc: oidcState } = useSelector((state: RootState) => state);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { getTwitterOAuthURL } = useTwitterData();
  const [email, setEmail] = useState<string>('');
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [remember, setRemember] = useState<boolean>(false);
  const [isErr, setIsErr] = useState<boolean>(false);
  const { setMessage } = useToaster();
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    password: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>(
    {
      email: '',
      password: ''
    }
  );

  const onSignUp = () => {
    logEvent(AnalyticsEvent.LINK_VISITED, 'Sign Up text link SignIn Click');
    const signUpUrl = oidcState.returnUri
      ? `/register?returnUrl=${encodeURIComponent(oidcState.returnUri)}`
      : '/register';
    navigate(signUpUrl);
  };

  const onForgotPass = () => {
    navigate('/forgot-password');
  };

  const setFieldErrorWithMessage = (fieldName: string, errMessage: string) => {
    HandleFieldError(fieldName, errMessage, setFieldErrors, setErrorMessages);
  };

  const loginValidation = () => {
    setFieldErrors({ email: false, password: false });
    if (email === '') {
      setFieldErrorWithMessage('email', 'This field is required');
      recaptchaRef.current?.reset();
      return false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, email: false }));
    }

    const emailRegex = /^[a-zA-Z0-9._-]{1,60}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setFieldErrorWithMessage('email', 'Please enter a valid email');
      recaptchaRef.current?.reset();
      return false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, email: false }));
    }

    if (password === '') {
      setFieldErrorWithMessage('password', 'This field is required');
      recaptchaRef.current?.reset();
      return false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, password: false }));
    }
    return true;
  };

  const loginHandler = useCallback(async () => {
    setIsErr(false);
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Sign In Button SignIn Click');
    const checkValidation = loginValidation();
    if (checkValidation) {
      let token;
      if (recaptchaRef.current) {
        token = await recaptchaRef?.current.executeAsync();
      }

      if (token === null) {
        setMessage({
          render: 'Unable to complete the CAPTCHA! Please reload the page'
        });
        return;
      }

      dispatch(setUserLoading());
      const returnUrlParam = searchParams.get(RETURN_URL_KEY_PARAM);
      const returnUrl = returnUrlParam ? returnUri(returnUrlParam) : '';

      const payload = {
        email,
        password,
        captchaSiteToken: token,
        returnUrl,
        remember
      };

      const config = {
        method: 'POST',
        url: `/spa/login`,
        headers: {
          'content-type': 'application/json'
        },
        credentials: 'include',
        data: JSON.stringify(payload)
      };

      setIsSigningIn(true);
      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            const returnUriExist = sessionStorage.getItem(
              RETURN_URI_KEY_STORAGE
            );
            if (!returnUriExist) {
              dispatch(getUserProfile({ rememberMe: remember }));
            }
            if (response.data && response.data.redirectUrl) {
              const redirectUrlParam = response.data.redirectUrl;
              urlRedirection(
                redirectUrlParam,
                response.data.redirectUrl,
                navigate,
                email,
                true,
                false
              );
            }
          }
          recaptchaRef.current?.reset();
          setIsSigningIn(false);
        })
        .catch((err) => {
          if (err.response.data.target) {
            setFieldErrorWithMessage(
              err.response.data.target,
              err.response.data.message ?? 'Something went wrong'
            );
          } else {
            setIsErr(true);
            setMessage({
              render: `${
                err.response.data === 'invalid-email-or-password'
                  ? 'Invalid Email or Password'
                  : err.response.data
              }`,
              variant: 'error'
            });
          }
          setIsSigningIn(false);
          recaptchaRef.current?.reset();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginValidation]);

  const onDiscordLogin = async () => {
    const clientId = process.env.OAUTH_DISCORD_ID;
    const redirectUri = encodeURIComponent(
      window.location.origin + '/auth/discord/callback'
    );
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Discord Button SignIn Click');
    const url = `https://discord.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=identify`;
    window.location.href = url;
  };

  const onGoogleLogin = async () => {
    const responseType = 'code';
    const clientId = process.env.OAUTH_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      window.location.origin + '/auth/google/callback'
    );
    const scope = 'openid profile email';
    const authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Google Button SignIn Click');
    window.location.href = authorizationUrl;
  };

  const onTwitterLogin = async () => {
    const redirectUri = window.location.origin + '/auth/twitter/callback';
    window.location.href = getTwitterOAuthURL(redirectUri);
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Twitter/X Button SignIn Click');
  };

  const onSteamLogin = () => {
    const steamApiKey = process.env.STEAM_API_KEY;
    const realm = window.location.origin + '/auth/steam/callback';
    const returnUrl = window.location.origin + '/auth/steam/callback';
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Steam Button SignIn Click');
    window.location.href = `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.realm=${realm}&openid.return_to=${returnUrl}&key=${steamApiKey}`;
  };

  const onFacebookLogin = () => {
    // TODO:
    // onLogin((error, response) => {
    //   if (!error) {
    //     const redirectUri = window.location.origin + '/auth/facebook/callback';
    //     window.location.replace(`${redirectUri}?code=${response?.accessToken}`);
    //   } else {
    //     // console.log('error');
    //   }
    // });
  };

  const googleHandler = useCallback(() => onGoogleLogin(), []);

  const twitterHandler = useCallback(() => onTwitterLogin(), []);

  const steamHandler = useCallback(() => onSteamLogin(), []);

  const [showMoreOption, setShowMoreOption] = useState<boolean>(false);

  return (
    <SignInMetamask
      render={(metamaskHandler) => (
        <div className={styles['wrapper']}>
          {!showMoreOption && (
            <SignIn
              onSignUp={onSignUp}
              onForgotPass={onForgotPass}
              onSignIn={loginHandler}
              onBtnGoogleClick={googleHandler}
              onBtnMetamaskClick={() => {
                logEvent(
                  AnalyticsEvent.BUTTON_CLICKED,
                  'Metamask Button SignIn Click'
                );
                metamaskHandler();
              }}
              remember={remember}
              setRemember={setRemember}
              onBtnSteamClick={steamHandler}
              onBtnTwitterClick={twitterHandler}
              username={email}
              setUsername={setEmail}
              password={password}
              setPassword={setPassword}
              // setIsUsernameErr={setIsUsernameErr}
              // setIsPassErr={setIsPassErr}
              usernameErrMess={errorMessages.email}
              passErrMess={errorMessages.password}
              isUsernameErr={fieldErrors.email}
              isPassErr={fieldErrors.password}
              setIsErr={setIsErr}
              onMoreOption={() => {
                setShowMoreOption(true);
              }}
              recaptchaRef={recaptchaRef}
              isSigningIn={isSigningIn}
              isErr={false}
              errMessage={''}
            />
          )}
          {showMoreOption && (
            <SignInMoreOption
              onSignUp={onSignUp}
              onClickButton={(
                type:
                  | 'google'
                  | 'mythic'
                  | 'twitter'
                  | 'metamask'
                  | 'steam'
                  | 'discord'
                  | 'facebook'
              ) => {
                switch (type) {
                  case 'mythic':
                    setShowMoreOption(false);
                    break;
                  case 'google':
                    onGoogleLogin();
                    break;
                  case 'discord':
                    onDiscordLogin();
                    break;
                  case 'twitter':
                    twitterHandler();
                    break;
                  case 'facebook':
                    onFacebookLogin();
                    break;
                  case 'steam':
                    onSteamLogin();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
        </div>
      )}
    />
  );
};

export default SignInPage;
