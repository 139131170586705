import React from 'react';

const ConfictionLabIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 6">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8071 17.6922L41.5091 13.5322C42.8567 16.6309 43.4462 20.0063 43.2287 23.3783C43.0111 26.7504 41.9929 30.0221 40.2583 32.922C34.2332 42.9906 21.143 46.284 11.0758 40.2575C1.00853 34.231 -2.28343 21.1394 3.74166 11.0736C9.76675 1.00772 22.8569 -2.28285 32.9242 3.74086C33.9922 4.37951 35.0023 5.1103 35.943 5.92487L30.2271 10.096C29.9678 9.91709 29.7057 9.74515 29.4284 9.58152C26.528 7.84445 23.1107 7.17834 19.7702 7.69893C16.4297 8.2195 13.3772 9.89387 11.1429 12.4311C8.90858 14.9684 7.63373 18.2082 7.53982 21.5877C7.44591 24.9672 8.53888 28.2728 10.6288 30.9302C12.7188 33.5876 15.6737 35.4289 18.9801 36.1342C22.2865 36.8395 25.7355 36.3642 28.7279 34.7909C31.7203 33.2175 34.067 30.6456 35.3602 27.522C36.6535 24.3983 36.8116 20.9203 35.8071 17.6922ZM29.2147 30.2454C31.2118 28.4972 32.5063 26.0833 32.8576 23.4524H11.141C11.4923 26.0833 12.7868 28.4972 14.784 30.2454C16.7811 31.9936 19.3451 32.9572 21.9993 32.9572C24.6535 32.9572 27.2175 31.9936 29.2147 30.2454Z"
        fill="#E2E2E2"
      />
    </g>
  </svg>
);

export default ConfictionLabIcon;
