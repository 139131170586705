import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store';
import { INavigation } from './navigation.type';

const initialState: INavigation = {
  loginUrl: '/'
};

export const navigationSlice = createSlice({
  name: 'navigation',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoginUrl: (state: INavigation, action: PayloadAction<string>) => {
      state.loginUrl = action.payload;
    }
  }
});

export const { setLoginUrl } = navigationSlice.actions;

export const selectNavigation = (state: RootState) => state.navigation;

export default navigationSlice.reducer;
