// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rrh5aRE99YvQlXDAmyyw{width:100%;height:100vh;display:flex;justify-content:center}@media screen and (min-width: 768px)and (max-width: 1279px){.rrh5aRE99YvQlXDAmyyw:not(.ZU3oZySK7KJF8etiAfju){margin-top:0}}@media screen and (max-width: 767px){.rrh5aRE99YvQlXDAmyyw:not(.ZU3oZySK7KJF8etiAfju){margin-top:-80px}}`, "",{"version":3,"sources":["webpack://./src/pages/ResetPasswordPage/ResetPasswordPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CAGF,4DACE,iDACE,YAAA,CAAA,CAIJ,qCACE,iDACE,gBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n}\n\n@media screen and (min-width: 768px) and (max-width: 1279px) {\n  .wrapper:not(.loading) {\n    margin-top: 0;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .wrapper:not(.loading) {\n    margin-top: -80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `rrh5aRE99YvQlXDAmyyw`,
	"loading": `ZU3oZySK7KJF8etiAfju`
};
export default ___CSS_LOADER_EXPORT___;
