import { UserFeedbackCommandApi } from '@src/API/generated-user-feedback';
import AxiosInstanceUserFeedback from '@src/common/services/AxiosInstanceUserFeedback';
import { setUserAccessToken } from '@src/domain/User/User.reducer';
import {
  retrySaveFeedback,
  saveFeedback
} from '@src/domain/UserFeedback/UserFeedback.thunk';
import { AppDispatch } from '@src/store';
import { AxiosInstance } from 'axios';
import { useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

const lDUFa = (AI: AxiosInstance) =>
  new UserFeedbackCommandApi(undefined, '', AI);

const useDashboard = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const auth = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const [showUserFeedbackForm, setShowUserFeedbackForm] =
    useState<boolean>(false);

  const _fetchUserFeedback = lDUFa(AxiosInstanceUserFeedback());
  const onUserFeedbackSubmit = async (value: {
    platform: string;
    topic: string;
    message: string;
    captchaToken: string | null;
  }) => {
    let captchaToken: string | null = null;
    if (recaptchaRef.current) {
      captchaToken = await recaptchaRef?.current.executeAsync();
      if (!captchaToken) return;
    }

    value.captchaToken = captchaToken;

    // Assuming _fetchUserFeedback is correctly initialized
    dispatch(saveFeedback({ value, _api: _fetchUserFeedback }));
  };

  const onRetry = () => {
    dispatch(retrySaveFeedback());
  };

  const getAccessToken = async () => {
    const maxRetries = 3; // Set the maximum number of retries
    let retries = 0;

    const tryGetAccessToken = async () => {
      try {
        const usr = await auth.signinSilent();
        if (usr) {
          dispatch(setUserAccessToken(usr.access_token));
          // Continue with other logic if needed
        } else {
          removeStorage();
          throw new Error('User object is null or undefined');
        }
      } catch (error) {
        if (retries < maxRetries) {
          retries++;
          await tryGetAccessToken();
        } else {
          removeStorage();
        }
      }
    };

    await tryGetAccessToken();
  };

  const removeStorage = () => {
    localStorage.removeItem(
      `oidc.user:${process.env.SSO_DOMAIN}:${process.env.SSO_CLIENT_ID}`
    );
  };

  return {
    showUserFeedbackForm,
    setShowUserFeedbackForm,
    onUserFeedbackSubmit,
    onRetry,
    getAccessToken,
    recaptchaRef
  };
};

export default useDashboard;
