import React, { useCallback, useState, useRef } from 'react';
import SignUp from '@src/components/molecules/SignUp/SignUp';
import styles from './SignUpPage.module.scss';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootState } from '@src/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserData,
  setUserError,
  setUserLoading,
  setUserSuccess
} from '@src/domain/User/User.reducer';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTwitterData } from '@src/hooks/useTwitterData';
import useFacebook from '@src/hooks/useFacebook';
import SignInMetamask from '@src/components/molecules/SignInMetamask/SignInMetamask';
import {
  HandleFieldError,
  returnUri,
  urlRedirection
} from '@src/common/utils/utils';
import useToaster from '@src/hooks/useToaster';
import {
  PASSWORD_REGEX,
  RETURN_URL_KEY_PARAM
} from '@src/common/constants/string';
import { logEvent } from '@src/domain/Analytic/Analythic';
import { AnalyticsEvent } from '@src/domain/Analytic/Analythic.type';
import useEmailVerification from '../EmailVerificationPage/useVerificationEmail';

declare global {
  interface Window {
    FB: any;
  }
}

const SignUpPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { onLogin } = useFacebook();
  const dispatch: AppDispatch = useDispatch();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [searchParams] = useSearchParams();
  const { getTwitterOAuthURL } = useTwitterData();
  const { oidc: oidcState } = useSelector((state: RootState) => state);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [isErr, setIsErr] = useState<boolean>(false);
  const { setMessage } = useToaster();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    region: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>(
    {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      region: ''
    }
  );

  const [isEulaChecked, setIsEulaChecked] = useState<boolean>(false);
  const [isEulaErr, setIsEulaErr] = useState<boolean>(false);
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false);

  const { onSendEmailVerification } = useEmailVerification({ email: email });

  const onSignIn = useCallback(() => {
    logEvent(AnalyticsEvent.LINK_VISITED, 'Sign In text link SignUp Click');
    const signInUrl = oidcState.returnUri
      ? `/login?returnUrl=${encodeURIComponent(oidcState.returnUri)}`
      : '/login';
    navigate(signInUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFieldErrorWithMessage = (fieldName: string, errMessage: string) => {
    HandleFieldError(fieldName, errMessage, setFieldErrors, setErrorMessages);
  };

  const signUpValidation = () => {
    const nameRegex = /^.{2,20}$/;
    let isValid = true;

    const validateInput = (value: string, fieldName: any, errMessage: any) => {
      if (value === '') {
        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: true }));
        setErrorMessages((prevState) => ({
          ...prevState,
          [fieldName]: 'This field is required'
        }));
        recaptchaRef.current?.reset();
        isValid = false;
      } else if (!nameRegex.test(value)) {
        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: true }));
        setErrorMessages((prevState) => ({
          ...prevState,
          [fieldName]: errMessage
        }));
        recaptchaRef.current?.reset();
        isValid = false;
      } else {
        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: false }));
      }
    };

    validateInput(
      firstName,
      'firstName',
      'Please choose a username between 2 and 20 characters in length.'
    );
    validateInput(
      lastName,
      'lastName',
      'Please choose a username between 2 and 20 characters in length.'
    );

    const emailRegex = /^[a-zA-Z0-9._-]{1,60}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/;

    if (email === '') {
      setFieldErrorWithMessage('email', 'Please enter a valid email');
      recaptchaRef.current?.reset();
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setFieldErrorWithMessage('email', 'Invalid email address');
      recaptchaRef.current?.reset();
      isValid = false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, email: false }));
    }

    if (password === '') {
      setFieldErrorWithMessage('password', 'This field is required');
      recaptchaRef.current?.reset();
      isValid = false;
    } else if (!PASSWORD_REGEX.test(password)) {
      setFieldErrorWithMessage(
        'password',
        'Password must be 8 or more characters with a minimum of each an uppercase letter, lowercase letter, a number and a symbol.'
      );
      recaptchaRef.current?.reset();
      isValid = false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, password: false }));
    }

    if (confirmPassword === '') {
      setFieldErrorWithMessage('confirmPassword', 'This field is required.');
      recaptchaRef.current?.reset();
      isValid = false;
    } else if (password !== confirmPassword) {
      setFieldErrorWithMessage(
        'confirmPassword',
        'The passwords entered do not match. Please retype the new password in both fields.'
      );
      recaptchaRef.current?.reset();
      isValid = false;
    } else {
      setFieldErrors((prevState) => ({ ...prevState, confirmPassword: false }));
    }

    if (country === '') {
      setFieldErrorWithMessage('region', 'Please select your region');
      recaptchaRef.current?.reset();
      isValid = false;
    }

    if (!isEulaChecked) {
      setIsEulaErr(true);
      recaptchaRef.current?.reset();
      isValid = false;
    } else {
      setIsEulaErr(false);
    }

    return isValid;
  };

  const signUpHandler = useCallback(
    async () => {
      logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Sign Up Button SignUp Click');
      const checkValidation = signUpValidation();
      setIsSigningUp(true);
      if (checkValidation) {
        let token;
        if (recaptchaRef.current) {
          token = await recaptchaRef?.current.executeAsync();
        }

        if (token === null) {
          setMessage({
            render: 'Unable to complete the CAPTCHA! Please reload the page'
          });
          setIsSigningUp(false);
          return;
        }
        dispatch(setUserLoading());
        const returnUrlParam = searchParams.get(RETURN_URL_KEY_PARAM);
        const returnUrl = returnUrlParam ? returnUri(returnUrlParam) : '';

        const payload = {
          firstName,
          lastName,
          email,
          password,
          captchaSiteToken: token,
          country,
          returnUrl: returnUrl
        };

        const config = {
          method: 'POST',
          url: `/spa/register`,
          headers: {
            'content-type': 'application/json'
          },
          credentials: 'include',
          data: JSON.stringify(payload)
        };

        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              dispatch(setUserData({ email: email }));
              dispatch(setUserSuccess(response.data.accountId));
              const redirectUrlParam = response.data.redirectUrl;
              onSendEmailVerification(email);
              urlRedirection(
                redirectUrlParam,
                response.data.redirectUrl,
                navigate,
                email,
                false,
                false
              );
            }
            setIsSigningUp(false);
          })
          .catch((err) => {
            dispatch(setUserError(err));
            if (err.response.data.target) {
              setFieldErrorWithMessage(
                err.response.data.target,
                err.response.data.message ?? 'Something went wrong'
              );
            } else {
              setIsErr(true);
              setMessage({
                render: err.response.data
              });
            }

            recaptchaRef.current?.reset();
            setIsSigningUp(false);
          });
      } else {
        setIsSigningUp(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signUpValidation]
  );

  const onDiscordLogin = async () => {
    const clientId = process.env.OAUTH_DISCORD_ID;
    const redirectUri = encodeURIComponent(
      window.location.origin + '/auth/discord/callback'
    );
    const url = `https://discord.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=identify`;
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Discord Button SignUp Click');
    window.location.href = url;
  };

  const onGoogleLogin = async () => {
    const responseType = 'code';
    const clientId = process.env.OAUTH_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      window.location.origin + '/auth/google/callback'
    );
    const scope = 'openid profile email';
    const authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Google Button SignUp Click');
    window.location.href = authorizationUrl;
  };

  const onFacebookLogin = () => {
    onLogin((error, response) => {
      if (!error) {
        const redirectUri = window.location.origin + '/auth/facebook/callback';
        window.location.replace(`${redirectUri}?code=${response?.accessToken}`);
      } else {
        // console.log('error');
      }
    });
  };

  const onTwitterLogin = async () => {
    const redirectUri = window.location.origin + '/auth/twitter/callback';
    window.location.href = getTwitterOAuthURL(redirectUri);
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Twitter/X Button SignUp Click');
  };

  const onSteamLogin = () => {
    const steamApiKey = process.env.STEAM_API_KEY;
    const realm = window.location.origin + '/auth/steam/callback';
    const returnUrl = window.location.origin + '/auth/steam/callback';
    logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Steam Button SignUp Click');
    window.location.href = `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.realm=${realm}&openid.return_to=${returnUrl}&key=${steamApiKey}`;
  };

  const discordHandler = useCallback(() => onDiscordLogin(), []);
  const googleHandler = useCallback(() => onGoogleLogin(), []);
  const facebookHandler = useCallback(
    () => onFacebookLogin(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const twitterHandler = useCallback(
    () => onTwitterLogin(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const steamHandler = useCallback(() => onSteamLogin(), []);

  return (
    <SignInMetamask
      render={(metamaskHandler) => (
        <div className={styles['wrapper']}>
          <SignUp
            email={email}
            setEmail={setEmail}
            isEmailErr={fieldErrors.email}
            emailErrMess={errorMessages.email}
            isPassErr={fieldErrors.password}
            passErrMess={errorMessages.password}
            password={password}
            setPassword={setPassword}
            firstName={firstName}
            setFirstName={setFirstName}
            isFirstNameErr={fieldErrors.firstName}
            firstNameErrMessage={errorMessages.firstName}
            lastName={lastName}
            setLastName={setLastName}
            isLastNameErr={fieldErrors.lastName}
            // setIsLastNameErr={setIsLastNameErr}
            lastNameErrMessage={errorMessages.lastName}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            isConfirmPasswordErr={fieldErrors.confirmPassword}
            // setIsConfirmPasswordErr={setIsConfirmPasswordErr}
            confirmPasswordErrMessage={errorMessages.confirmPassword}
            onSignIn={onSignIn}
            onSignUp={() => signUpHandler()}
            onDiscordClick={discordHandler}
            onGoogleClick={googleHandler}
            onMetamaskClick={metamaskHandler}
            onFacebookClick={facebookHandler}
            onTwitterClick={twitterHandler}
            onSteamClick={steamHandler}
            isEulaChecked={isEulaChecked}
            setIsEulaChecked={setIsEulaChecked}
            isEulaErr={isEulaErr}
            setIsEulaErr={setIsEulaErr}
            isErr={isErr}
            errMessage={''}
            recaptchaRef={recaptchaRef}
            isSigningUp={isSigningUp}
            popupModal={state && state.registerModal}
            eulaErrMessage={''}
            region={country}
            setRegion={setCountry}
            isRegionErr={fieldErrors.region}
            regionErrMess={errorMessages.region}
          />
        </div>
      )}
    />
  );
};

export default SignUpPage;
