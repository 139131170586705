import React from 'react';

const EditIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19324 10.5443L5.45566 13.8068L13.7961 5.46948L10.5305 2.20394L2.19324 10.5443ZM0.0183 15.6192C-0.0535731 15.9098 0.090173 16.0536 0.38079 15.9817L4.73067 14.5317L1.46826 11.2693L0.0183 15.6192ZM15.246 0.75398C13.7992 -0.695982 12.3461 0.39149 12.3461 0.39149C12.1086 0.572735 11.2586 1.47896 11.2586 1.47896L14.521 4.74137C14.521 4.74137 15.4241 3.8914 15.6085 3.6539C15.6085 3.6539 16.696 2.20707 15.246 0.75398Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
