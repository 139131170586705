import { Area } from 'react-easy-crop/types';

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getRadianAngle = (degreeValue: number): number =>
  (degreeValue * Math.PI) / 180;

export const rotateSize = (
  width: number,
  height: number,
  rotation: number
): { width: number; height: number } => {
  const rotRad = getRadianAngle(rotation);

  const widthScale = Math.abs(Math.cos(rotRad));
  const heightScale = Math.abs(Math.sin(rotRad));

  const newWidth = width * widthScale + height * heightScale;
  const newHeight = width * heightScale + height * widthScale;

  return {
    width: newWidth,
    height: newHeight
  };
};

interface CropResult {
  dataUrl: string;
  blob: Blob;
}

export const getCroppedImg = async ({
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { vertical: false, horizontal: false }
}: {
  imageSrc: string;
  pixelCrop?: Area;
  rotation?: number;
  flip?: { vertical: boolean; horizontal: boolean };
}): Promise<CropResult | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);
  const { width: imageWidth, height: imageHeight } = image;

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    imageWidth,
    imageHeight,
    rotation
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-imageWidth / 2, -imageHeight / 2);

  ctx.drawImage(image, 0, 0);

  let pxData = {
    x: 0,
    y: 0,
    width: imageWidth,
    height: imageHeight
  };

  if (pixelCrop) {
    pxData = {
      x: pixelCrop.x,
      y: pixelCrop.y,
      width: pixelCrop.width,
      height: pixelCrop.height
    };
  }
  const data = ctx.getImageData(
    pxData.x,
    pxData.y,
    pxData.width,
    pxData.height
  );

  canvas.width = pxData.width;
  canvas.height = pxData.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const dataUrl = URL.createObjectURL(blob);
        resolve({ dataUrl, blob });
      }
    }, 'image/jpeg');
  });
};

export const getFlippedImage = async ({
  imageSrc,
  rotation = 0,
  flip = { vertical: false, horizontal: false }
}: {
  imageSrc: string;
  rotation?: number;
  flip?: { vertical: boolean; horizontal: boolean };
}): Promise<CropResult | null> => getCroppedImg({ imageSrc, rotation, flip });
