import { RootState } from '@src/store';
import axios from 'axios';
import { useSelector } from 'react-redux';

/**
 *
 * @param idempotencyId is the PageName
 */
const AxiosInstance = (baseUrl: string) => {
  const { user: UserStore } = useSelector((state: RootState) => ({
    user: state.user
  }));
  const instance = axios.create({
    baseURL: baseUrl || process.env.API_URL,
    timeout: 30000,
    headers: { checksum: process.env.CHECKSUM || '' }
  });
  instance.interceptors.request.use(
    function (config) {
      if (!config || !config.headers) {
        return;
      }
      if (UserStore.userToken) {
        config.headers.Authorization = `Bearer ${UserStore.userToken}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  return instance;
};

export default AxiosInstance;
