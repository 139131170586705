import { useDispatch } from 'react-redux';

import { AppDispatch } from '@src/store';
import { addData } from '@src/domain/ModalSteam/ModalSteam.reducer';

const useModalSteam = () => {
  const dispatch: AppDispatch = useDispatch();

  const showModal = (error: any) => {
    const dataSteam = error.data.data;
    const isTempEmail = dataSteam.email.includes('@mythictempmail.com');
    const message = isTempEmail
      ? 'This temporary account has been created <br /> via Steam. Please complete the account <br /> creation process to continue.'
      : 'This Steam ID is already bound to another Confiction <br /> account. Please select another to continue, or you may <br /> choose to merge your existing Confiction Labs<br /> accounts together.';
    const titleError = isTempEmail
      ? 'Complete Your Account'
      : 'Steam Account Merging';

    const payload = {
      isShow: true,
      providerUserName: dataSteam.providerUserName,
      message,
      titleError
    };
    dispatch(addData(payload));
  };

  return { showModal };
};

export default useModalSteam;
