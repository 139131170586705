import React from 'react';

const Logo = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9034 8.84633L20.7544 6.76632C21.4282 8.31569 21.723 10.0034 21.6142 11.6894C21.5054 13.3755 20.9963 15.0113 20.129 16.4612C17.1165 21.4956 10.5714 23.1422 5.53777 20.129C0.504144 17.1157 -1.14184 10.57 1.87071 5.53703C4.88326 0.504106 11.4284 -1.14118 16.462 1.87067C16.996 2.19 17.501 2.55539 17.9714 2.96268L15.1134 5.04823C14.9838 4.95879 14.8527 4.87282 14.7141 4.791C13.2639 3.92247 11.5552 3.58942 9.88498 3.84971C8.21474 4.11 6.68846 4.94718 5.57131 6.21581C4.45417 7.48444 3.81674 9.10435 3.76979 10.7941C3.72283 12.4838 4.26932 14.1366 5.31429 15.4654C6.35927 16.7941 7.8367 17.7147 9.48991 18.0674C11.1431 18.42 12.8676 18.1823 14.3638 17.3957C15.86 16.609 17.0334 15.3231 17.68 13.7612C18.3266 12.1994 18.4057 10.4604 17.9034 8.84633ZM14.6072 15.1229C15.6058 14.2488 16.253 13.0419 16.4287 11.7265H5.57036C5.74601 13.0419 6.39327 14.2488 7.39186 15.1229C8.39044 15.997 9.67241 16.4789 10.9995 16.4789C12.3266 16.4789 13.6086 15.997 14.6072 15.1229Z"
        fill="#E2E2E2"
      />
    </svg>
  );
};

export default Logo;
