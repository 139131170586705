const clientId = process.env.OAUTH_DISCORD_ID;
const clientSecret = process.env.OAUTH_DISCORD_SECRET;
const redirectUri = encodeURIComponent(
  window.location.origin + '/bind/discord/callback'
);

import { useState } from 'react';
import axios from 'axios';

const useDiscordAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchTokenDiscord = async (code: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://discord.com/api/oauth2/token',
        `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}`
      );

      const token = response.data.access_token;
      return token;
    } catch (error: any) {
      setError(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  //   const handleCallback = async () => {
  //     const code = new URLSearchParams(window.location.search).get('code');
  //     if (code) {
  //       await fetchToken(code);
  //     } else {
  //       setIsLoading(false);
  //     }
  //   };

  //   useEffect(() => {

  //     handleCallback();
  //   }, [clientId, clientSecret, redirectUri]);

  const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=identify`;

  const authorize = () => {
    window.location.href = authUrl;
  };

  return { error, authorize, isLoading, fetchTokenDiscord };
};

export default useDiscordAuth;
