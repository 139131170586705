import React from 'react';

const TestingCallbackPage = () => {
  function copyText() {
    // Get the text field
    const copyText = document.getElementById('myInput');

    // Select the text field
    copyText?.select();
    copyText?.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    alert('Copied the text: ' + copyText.value);
  }
  return (
    <center>
      Your steam ticket is here:
      <input
        id="myInput"
        type="text"
        style={{
          color: 'white'
        }}
        value={window.location.href.substring(
          window.location.href.indexOf('?') + 1,
          window.location.href.length
        )}
      ></input>
      <button onClick={copyText}>Copy</button>
    </center>
  );
};

export default TestingCallbackPage;
