import { useState, useEffect, useRef } from 'react';

interface CountdownState {
  startInterval: () => void;
  stopInterval: () => void;
  countdown: {
    minutes: number;
    seconds: number;
  };
}

const useCountdown = (
  maxTime: number,
  callback: React.Dispatch<React.SetStateAction<boolean>>,
  sentMailKey: string,
  lastSentMailKey: string,
  callbackVoidInterval?: () => void
): CountdownState => {
  const intervalRef = useRef<number | null>(null);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [count, setCount] = useState<number>(maxTime);
  const emailSentBefore = sessionStorage.getItem(sentMailKey);

  const startInterval = () => {
    if (intervalRef.current !== null) return;
    intervalRef.current = window.setInterval(() => {
      setCount((prev) => (prev > 0 ? prev - 1 : 0));
      if (callbackVoidInterval) {
        callbackVoidInterval();
      }
    }, 1000);
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      sessionStorage.removeItem(sentMailKey);
      sessionStorage.removeItem(lastSentMailKey);
    }
  };

  useEffect(() => {
    startInterval();
    return stopInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!emailSentBefore) return;
    const lastSentTimestampStr = sessionStorage.getItem(lastSentMailKey);
    const lastSentTimestamp = Number(lastSentTimestampStr);

    // Calculate the time elapsed since the last sent email in seconds
    // Get the current timestamp
    const currentTimestamp = Date.now();

    // Calculate the difference in milliseconds
    const timeDifference = currentTimestamp - lastSentTimestamp;

    // Convert milliseconds to seconds
    const secondsDifference = Math.floor(timeDifference / 1000);

    const countdownValue = maxTime - secondsDifference;
    if (countdownValue > 0) {
      setCount(countdownValue);
    } else {
      setCount(0);
      callback(false);
    }

    startInterval();
    callback(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSentBefore, document.hidden]);

  useEffect(() => {
    if (count <= 0) {
      callback(false);
      stopInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return {
    startInterval,
    stopInterval,
    countdown: {
      minutes: Math.floor(count / 60),
      seconds: count % 60
    }
  };
};

export default useCountdown;
