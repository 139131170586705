import * as React from 'react';
import { SVGProps } from 'react';

const ConfictionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.904 8.846 2.85-2.08a10.628 10.628 0 0 1-.625 9.695c-3.012 5.034-9.558 6.681-14.591 3.668-5.034-3.013-6.68-9.56-3.667-14.592C4.883.504 11.428-1.141 16.462 1.87c.534.319 1.04.684 1.51 1.092l-2.858 2.085a7.232 7.232 0 1 0 2.79 3.798Zm-3.297 6.277a5.477 5.477 0 0 0 1.822-3.397H5.57a5.477 5.477 0 0 0 9.037 3.397Z"
      fill="#E2E2E2"
    />
  </svg>
);

export default ConfictionIcon;
