import React from 'react';

const Person = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49996 9.00002C7.35413 9.00002 6.37322 8.59203 5.55725 7.77606C4.74128 6.96009 4.33329 5.97919 4.33329 4.83335C4.33329 3.68752 4.74128 2.70662 5.55725 1.89065C6.37322 1.07467 7.35413 0.666687 8.49996 0.666687C9.64579 0.666687 10.6267 1.07467 11.4427 1.89065C12.2586 2.70662 12.6666 3.68752 12.6666 4.83335C12.6666 5.97919 12.2586 6.96009 11.4427 7.77606C10.6267 8.59203 9.64579 9.00002 8.49996 9.00002ZM0.166626 17.3334V14.4167C0.166626 13.8264 0.31871 13.2837 0.622876 12.7886C0.926349 12.2941 1.32982 11.9167 1.83329 11.6563C2.90968 11.1181 4.00343 10.7143 5.11454 10.4448C6.22565 10.1761 7.35413 10.0417 8.49996 10.0417C9.64579 10.0417 10.7743 10.1761 11.8854 10.4448C12.9965 10.7143 14.0902 11.1181 15.1666 11.6563C15.6701 11.9167 16.0736 12.2941 16.377 12.7886C16.6812 13.2837 16.8333 13.8264 16.8333 14.4167V17.3334H0.166626Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default Person;
