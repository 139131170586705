/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store';
import { getUserProfile } from './User.thunk';
import { IUser } from './User.type';

const initialState: IUser = {
  isLogin: false,
  data: undefined,
  intialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    massage: ''
  },
  isCheckedSession: false,
  rememberMe: false,
  userToken: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state: IUser, action: PayloadAction<any>) => {
      state.data = action.payload;
    },

    setIsLogin: (state: IUser, action: PayloadAction<boolean>) => {
      localStorage.setItem('isLogin', JSON.stringify(action.payload));
      state.isLogin = action.payload;
      state.intialState.isLoading = false;
    },

    setUserLoading: (state: IUser) => {
      const newStatus = {
        isLoading: true,
        isError: false,
        isSuccess: false,
        massage: ''
      };
      state.intialState = newStatus;
    },

    setUserSuccess: (state: IUser) => {
      const newStatus = {
        isLoading: false,
        isError: false,
        isSuccess: true,
        massage: ''
      };
      state.intialState = newStatus;
    },

    setUserAccessToken: (state: IUser, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },

    setUserError: (state: IUser) => {
      const newStatus = {
        isLoading: false,
        isError: true,
        isSuccess: false,
        massage: ''
      };
      state.intialState = newStatus;
    },

    checkAuth: (state: IUser) => {
      const isLogin = JSON.parse(localStorage.getItem('isLogin') as string);
      if (isLogin) {
        state.rememberMe = true;
        state.isLogin = isLogin;
        const newStatus = {
          isLoading: true,
          isError: false,
          isSuccess: true,
          massage: ''
        };
        state.intialState = newStatus;
      }
      state.isCheckedSession = true;
    },
    onLogout: (state: IUser) => {
      state.isLogin = false;
      state.data = undefined;
      state.rememberMe = false;
      localStorage.removeItem('isLogin');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        const newStatus = {
          isLoading: true,
          isError: false,
          isSuccess: false,
          massage: ''
        };
        state.intialState = newStatus;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        const newStatus = {
          isLoading: false,
          isError: false,
          isSuccess: true,
          massage: ''
        };
        state.data = payload.result.data.data;
        state.isLogin = true;
        state.intialState = newStatus;
        state.rememberMe = payload.rememberMe ?? false;
        localStorage.setItem('isLogin', JSON.stringify('true'));
      })
      .addCase(getUserProfile.rejected, (state, { payload }) => {
        const newStatus = {
          isLoading: false,
          isError: true,
          isSuccess: false,
          massage: ''
        };
        state.data = undefined;
        state.isLogin = false;
        state.intialState = newStatus;

        const result = payload as {
          data: { redirectUrl: string };
          status: number;
        };
        localStorage.removeItem('isLogin');

        if (result?.data?.redirectUrl) {
          window.location.replace(result?.data?.redirectUrl);
        }
      });
  }
});

export const {
  setUserData,
  setIsLogin,
  setUserLoading,
  setUserSuccess,
  setUserError,
  checkAuth,
  onLogout,
  setUserAccessToken
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
