import React from 'react';

const BindIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Vector">
      <path
        d="M4.97912 10.9257C4.50813 10.4547 4.50813 9.69109 4.97912 9.22009L9.11959 5.07962C9.59059 4.60862 10.3542 4.60862 10.8252 5.07962C11.2962 5.55062 11.2962 6.31426 10.8252 6.78526L6.68476 10.9257C6.21376 11.3967 5.45012 11.3967 4.97912 10.9257Z"
        fill="white"
      />
      <path
        d="M7.07868 4.40756L8.88035 2.60589C10.1268 1.35946 12.1477 1.35946 13.3941 2.6059C14.6405 3.85234 14.6405 5.87321 13.3941 7.11965L11.5924 8.92132L12.7741 10.1029L14.5757 8.30128C16.4748 6.40224 16.4748 3.32331 14.5757 1.42428C12.6767 -0.474756 9.59776 -0.474759 7.69873 1.42427L5.89706 3.22594L7.07868 4.40756Z"
        fill="white"
      />
      <path
        d="M4.00787 7.47837L2.82625 6.29675L1.42427 7.69872C-0.474758 9.59776 -0.474757 12.6767 1.42427 14.5757C3.32331 16.4748 6.40224 16.4748 8.30127 14.5757L9.70325 13.1737L8.52163 11.9921L7.11965 13.3941C5.87321 14.6405 3.85234 14.6405 2.6059 13.3941C1.35946 12.1477 1.35946 10.1268 2.6059 8.88035L4.00787 7.47837Z"
        fill="white"
      />
    </g>
  </svg>
);

export default BindIcon;
