import React from 'react';

const TestingPage = () => {
  const handleSteamSignin = () => {
    const steamApiKey = process.env.STEAM_API_KEY;
    const realm = encodeURIComponent(
      window.location.origin + '/steam-testing-sso-callback'
    );
    const returnUrl = encodeURIComponent(
      window.location.origin + '/steam-testing-sso-callback'
    );
    window.location.href = `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.realm=${realm}&openid.return_to=${returnUrl}&key=${steamApiKey}`;
  };

  return (
    <center>
      <button onClick={handleSteamSignin}>Login With Steam</button>
    </center>
  );
};

export default TestingPage;
