import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaProps {
  title?: string;
  urlSite?: string;
  desc?: string;
  ogType?: string;
  ogTitle?: string;
  ogDesc?: string;
  ogImg?: string;
  ogUrl?: string;
  ogSiteName?: string;
  keywords?: string;
}

const MetaHelmet = ({ title = 'Confiction Labs Account' }: MetaProps) => {
  const isProduction = process.env.REACT_APP_TYPE === 'prod';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {!isProduction && <meta name="robots" content="noindex, nofollow" />}
        <title>{title}</title>
      </Helmet>
    </>
  );
};

export default MetaHelmet;
