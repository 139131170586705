import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import PrivacyPolicyContent from './PrivacyPolicyContent';

type IPrivacyPolicy = {
  height: string;
};

const PrivacyPolicy: React.FC<IPrivacyPolicy> = ({ height = '835px' }) => {
  return (
    <div className={styles['big-container']} style={{ height: height }}>
      <div className={styles['header-wrapper']}>Privacy Policy</div>
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPolicy;
