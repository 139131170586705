import React from 'react';

const SteamNewIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        fill="#171D25"
      />
      <g clipPath="url(#clip0_4156_117269)">
        <ellipse cx="24" cy="23.4399" rx="14" ry="14" fill="#171D25" />
        <path
          d="M24 9.43994C25.8385 9.43994 27.659 9.80206 29.3576 10.5056C31.0561 11.2092 32.5995 12.2404 33.8995 13.5404C35.1995 14.8405 36.2308 16.3838 36.9343 18.0824C37.6379 19.7809 38 21.6014 38 23.4399C38 27.153 36.525 30.7139 33.8995 33.3394C31.274 35.9649 27.713 37.4399 24 37.4399C17.56 37.4399 12.17 33.1279 10.504 27.2619L15.866 29.4739C16.0505 30.3704 16.5382 31.1759 17.2471 31.7547C17.956 32.3336 18.8428 32.6504 19.758 32.6519C21.942 32.6519 23.72 30.8739 23.72 28.6899V28.5079L28.48 25.1059H28.592C31.504 25.1059 33.87 22.7399 33.87 19.8279C33.87 16.9159 31.504 14.5499 28.592 14.5499C25.68 14.5499 23.3 16.9159 23.3 19.8279V19.8979L19.982 24.7419L19.758 24.7279C18.932 24.7279 18.162 24.9799 17.532 25.4139L10 22.3199C10.602 15.1099 16.622 9.43994 24 9.43994ZM18.792 30.6779C19.912 31.1399 21.2 30.6219 21.662 29.5019C22.124 28.3819 21.592 27.1079 20.5 26.6459L18.708 25.9039C19.394 25.6519 20.164 25.6379 20.892 25.9459C21.634 26.2399 22.208 26.8139 22.502 27.5559C22.81 28.2839 22.81 29.0959 22.502 29.8239C21.9 31.3359 20.122 32.0639 18.61 31.4339C17.91 31.1399 17.378 30.6079 17.084 29.9779L18.792 30.6779ZM32.12 19.8279C32.12 21.7739 30.538 23.3559 28.592 23.3559C27.6587 23.3522 26.765 22.9789 26.1064 22.3177C25.4478 21.6564 25.078 20.7612 25.078 19.8279C25.0762 19.366 25.1658 18.9082 25.3417 18.481C25.5177 18.0538 25.7764 17.6657 26.1031 17.339C26.4298 17.0124 26.8179 16.7536 27.2451 16.5777C27.6722 16.4017 28.13 16.3121 28.592 16.3139C29.5253 16.3139 30.4205 16.6837 31.0817 17.3423C31.743 18.0009 32.1163 18.8947 32.12 19.8279ZM25.96 19.8279C25.96 21.2839 27.136 22.4739 28.606 22.4739C30.062 22.4739 31.238 21.2839 31.238 19.8279C31.238 18.3719 30.062 17.1819 28.606 17.1819C27.136 17.1819 25.96 18.3719 25.96 19.8279Z"
          fill="white"
        />
      </g>
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        stroke="#151528"
        strokeWidth="0.5"
      />
      <defs>
        <clipPath id="clip0_4156_117269">
          <rect x="10" y="10" width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SteamNewIcon;
