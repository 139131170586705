import React, { Fragment } from 'react';
import styles from './ModalContent.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

type IModalContent = {
  onClose: () => void;
  children: React.ReactNode;
  show: boolean;
  title?: string;
  noTitle?: boolean;
  paddingBody?: string;
};

const ModalContent: React.FC<IModalContent> = ({
  onClose,
  children,
  show,
  title = undefined,
  noTitle = false,
  paddingBody
}) => {
  const modalVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: {
      opacity: 0,
      when: 'afterChildren',
      duration: { delay: 1 }
    }
  };

  const contentVariants = {
    hidden: {
      height: 0,
      opacity: 0
    },
    visible: {
      opacity: 1,
      height: 'max-content',
      transition: { duration: 0.5, delay: 0.5 }
    },
    exit: {
      opacity: 0,
      height: 0
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <>
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={styles['drop-shadow']}
            onClick={onClose}
            data-testid="modal-content"
          ></motion.div>
          <div>
            <motion.div
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles['content-container']}
              style={
                {
                  '--padding-body': paddingBody
                } as React.CSSProperties
              }
            >
              {!noTitle && (
                <Fragment>
                  <div className={styles['close-button']} onClick={onClose}>
                    &times;
                  </div>
                  {title != undefined && (
                    <div className={styles['title-container']}>
                      <p>{title}</p>
                    </div>
                  )}
                </Fragment>
              )}
              <div className={styles['content-wrapper']}>{children}</div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ModalContent;
