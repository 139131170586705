import { oidcConfig } from '@src/common/constants/Auth.OidcConfig';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const SSOCallback = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const paramsName = urlParams.get('redirect_to');
  useEffect(() => {
    if (auth.isAuthenticated && !auth.isLoading) {
      let newUrl =
        localStorage.getItem('sign') === null
          ? '/'
          : (localStorage.getItem('sign') as string);

      if (localStorage.getItem('code') !== null) {
        newUrl += '?code=' + localStorage.getItem('code');
      }
      //   if (!!paramsName) {
      //     window.location.href = handleRedirectCallbackUrl(paramsName);
      //   } else {
      navigate(newUrl, { replace: true });
      //   }
    } else {
      if (!auth.isLoading) {
        if (!!paramsName) {
          auth.signinRedirect({
            redirect_uri: `${oidcConfig.redirect_uri}?redirect_to=${paramsName}`,
            extraQueryParams: {
              back_url: `https://${window.location.host}`
            }
          });
        }
      }
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  return (
    <div>
      <FullPageLoading />
    </div>
  );
};

export default SSOCallback;
