import {
  Button,
  DatePicker,
  Input,
  Loading,
  Select
} from '@jupiter/react-common-component';
import { iso3166 } from '@src/common/constants/IsoLists';
import {
  addZero,
  canDisconnectPlatform,
  dayLong,
  enableFacebookOauth,
  formatNumber,
  monthLong,
  onConvToDateServer
} from '@src/common/utils/utils';
import DiscordNewIcon from '@src/components/Icons/DiscordNewIcon';
import SteamNewIcon from '@src/components/Icons/SteamNewIcon';
import TwitterNewIcon from '@src/components/Icons/TwitterNewIcon';
import Card from '@src/components/atoms/Card/Card';
import FullPageLoading from '@src/components/atoms/LoadingScreen/FullPageLoading';
import CropProfile from '@src/components/molecules/CropProfile/CropProfile';
import { getUserProfile } from '@src/domain/User/User.thunk';
import useDiscordOAuth from '@src/hooks/useDiscord';
import useFacebook from '@src/hooks/useFacebook';
import useSteamAuth from '@src/hooks/useSteam';
import useToaster from '@src/hooks/useToaster';
import { useTwitterData } from '@src/hooks/useTwitterData';
import { AppDispatch, RootState } from '@src/store';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLazyFetch from '../../hooks/useLazyFetch';
import EditIcon from './EditIcon';
import ErrorIcon from './ErrorIcon';
import styles from './MyProfile.module.scss';
import { logEvent } from '@src/domain/Analytic/Analythic';
import { AnalyticsEvent } from '@src/domain/Analytic/Analythic.type';
import PointDisplay from '@src/components/atoms/PointDisplay';
import ConfictionIcon from '@src/components/Icons/ConfictionIcon';
import PointIcon from '@src/components/Icons/PointIcon';
import { PointData } from './MyProfile.constants';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import ProfileAlert from '@src/components/molecules/ProfileAlert/ProfileAlert';
import ModalSteam from '@src/components/molecules/ModalSteam/ModalSteam';

const MyProfile: React.FC = () => {
  const { user: userLogs } = useSelector((state: RootState) => state);
  const { authorize } = useDiscordOAuth();
  const { getTwitterOAuthURL } = useTwitterData();
  const { getAuthorizeSteamUrl } = useSteamAuth();
  const dispatch: AppDispatch = useDispatch();
  const [profileUrl, setProfileUrl] = useState('');
  const [isLoadingUnbindDiscord, setIsLoadingUnbindDiscord] =
    useState<boolean>(false);
  const [isLoadingUnbindTwitter, setIsLoadingUnbindTwitter] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCrop, setShowCrop] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>('');
  const [selectedImageSize, setSelectedImageSize] = useState({
    height: 0,
    width: 0
  });
  const [adjustmentRange, setAdjustmentRange] = useState<boolean>(true);
  const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);
  const [isGenderOpen, setIsGenderOpen] = useState<boolean>(false);
  const [isCalendarShown, setIsCalendarShown] = useState<boolean>(false);

  const { setMessage } = useToaster();
  const [points, setPoints] = useState<PointData[]>([]);
  const { width } = useWindowDimensions();

  const today = new Date();
  const minAgeDate = new Date();
  minAgeDate.setFullYear(today.getFullYear() - 13);

  const disabledDate = (current: Date) => {
    // Disable dates that are after the minimum age date
    return current && current > minAgeDate;
  };

  const [onEdit] = useLazyFetch({
    url: '/spa/user-profile',
    method: 'PUT'
  });

  const [onBindAccount] = useLazyFetch({
    url: '/spa/user-profile/bind-external-account',
    method: 'PATCH'
  });

  const [onUnbindAccount] = useLazyFetch({
    url: '/spa/user-profile/unbind-external-account',
    method: 'PATCH'
  });

  const [onGetPoint] = useLazyFetch({
    url: `${process.env.POINT_SYSTEM_URL}`,
    method: 'GET'
  });

  // const [onGetScore] = useLazyFetch({
  //   url: `${process.env.URL_VALIDATION_SERVICES}`,
  //   method: 'GET'
  // });

  const [isEdit, setIsEdit] = useState(false);
  const [editForm, setEditForm] = useState({
    name: '',
    nickname: '',
    tag: '',
    email: '',
    birthDate: '',
    country: '',
    gender: ''
  });
  const [image, setImage] = useState<{
    file: File | null;
    fileUrl: string | null;
  }>({
    file: null,
    fileUrl: null
  });

  const [errorInput, setErrorInput] = useState<{
    name: string | null;
    nickname: string | null;
    tag: string | null;
    email: string | null;
    birthDate: string | null;
    country: string | null;
    gender: string | null;
    picture: string | null;
  }>({
    name: null,
    nickname: null,
    tag: null,
    email: null,
    birthDate: null,
    country: null,
    gender: null,
    picture: null
  });

  const inputFileRef = useRef<HTMLInputElement>(null);
  const connectedPlatformText = !canDisconnectPlatform ? 'Bound' : 'Unbind';

  const { onLogin } = useFacebook();

  useEffect(() => {
    onGetPoint({}, (success, error) => {
      if (!error) {
        if (success?.data.data) {
          setPoints(success?.data.data);
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   onGetScore({}, (success, error) => {
  //     if (!error) {
  //       if (success?.data.data) {
  //         setPoints(success?.data.data);
  //       }
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   const getLocalData = localStorage.getItem(
  //     `oidc.user:${process.env.SSO_DOMAIN}:${process.env.SSO_CLIENT_ID}`
  //   );

  //   if (getLocalData) {
  //     const accessToken = JSON.parse(getLocalData).access_token;

  //     const fetchScore = async () => {
  //       try {
  //         const result = await axios.get(
  //           `${process.env.URL_VALIDATION_SERVICES}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${accessToken}`
  //             }
  //           }
  //         );
  //         console.log(result);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };

  //     fetchScore();
  //   }
  // }, []);

  useEffect(() => {
    if (!isCountryOpen && !isGenderOpen) {
      return;
    }

    if (isCountryOpen && !isGenderOpen && !isCalendarShown) {
      setIsGenderOpen(false);
      setIsCalendarShown(false);
    }

    if (isGenderOpen && !isCountryOpen && !isCalendarShown) {
      setIsCountryOpen(false);
      setIsCalendarShown(false);
    }

    if (isCalendarShown && !isCountryOpen && !isGenderOpen) {
      setIsCountryOpen(false);
      setIsGenderOpen(false);
    }
  }, [isCountryOpen, isGenderOpen, isCalendarShown]);

  const onConnectFacebook = () => {
    onLogin((error, response) => {
      if (!error) {
        onBindAccount(
          {
            accessToken: response?.accessToken,
            providerName: 'facebook'
          },
          (_, error) => {
            if (!error) {
              dispatch(getUserProfile());
              setMessage({
                render: 'Successfully connected to Facebook',
                variant: 'success'
              });
            } else {
              setMessage({ render: 'Something went wrong!', variant: 'error' });
            }
          }
        );
      }
    });
  };

  const onDisconnectAccount = (providerName: string) => {
    if (providerName === 'Discord') {
      setIsLoadingUnbindDiscord(true);
    }
    if (providerName === 'Twitter') {
      setIsLoadingUnbindTwitter(true);
    }
    onUnbindAccount(
      {
        providerName: providerName
      },
      (_, error) => {
        if (!error) {
          dispatch(getUserProfile());
          setMessage({
            render: `Successfully disconnected to ${providerName}`,
            variant: 'success'
          });
          setIsLoadingUnbindDiscord(false);
          setIsLoadingUnbindTwitter(false);
        } else {
          setMessage({ render: 'Something went wrong!', variant: 'error' });
          setIsLoadingUnbindDiscord(false);
          setIsLoadingUnbindTwitter(false);
        }
      }
    );
  };

  const onConnectTwitter = async () => {
    const redirectUri = window.location.origin + '/bind/twitter/callback';
    window.location.href = getTwitterOAuthURL(redirectUri);
  };

  const onConvDate = useCallback((value: string) => {
    const dt = new Date(value);

    return `${addZero(`${dt.getDate()}`)}/${addZero(
      `${dt.getMonth() + 1}`
    )}/${dt.getFullYear()}`;
  }, []);

  const onChangeInput = (e: {
    currentTarget: {
      value: string;
      name: string;
    };
  }) => {
    setErrorInput({
      ...errorInput,
      [e.currentTarget.name as keyof typeof errorInput]: null
    });

    if (e.currentTarget.name === 'name') {
      if (e.currentTarget.value.length === 0) {
        setErrorInput({
          ...errorInput,
          name: 'This field is required'
        });
      }
      const nameRegex = /^.{2,40}$/;
      if (!nameRegex.test(e.currentTarget.value.trim())) {
        setErrorInput({
          ...errorInput,
          name: 'Please choose a name between 2 and 40 characters in length.'
        });
      }
    }

    if (e.currentTarget.name === 'birthDate') {
      const dateOfBirth = e.currentTarget.value;
      const currentDate = new Date();
      const [day, month, year] = dateOfBirth.split('/');

      // Create a new Date object using the specified date format
      const enteredDate = new Date(`${year}-${month}-${day}`);

      // Check if the enteredDate is a valid date
      if (isNaN(enteredDate.getTime())) {
        setErrorInput({
          ...errorInput,
          birthDate: 'Invalid date format. Please use dd/mm/yyyy.'
        });
        return;
      }
      const minAge = 13;
      // Calculate the age difference in years
      const ageDiff = currentDate.getFullYear() - enteredDate.getFullYear();

      // Check if the age is less than the required minimum
      if (ageDiff < minAge) {
        setErrorInput({
          ...errorInput,
          birthDate: 'You must be at least 13 years old.'
        });
        return;
      }
    }
    if (e.currentTarget.name === 'nickname') {
      if (!userLogs.data?.canChangeTag) {
        setErrorInput({
          ...errorInput,
          ['tag' as keyof typeof errorInput]: getTagErrorMessage()
        });

        return null;
      }
      const validNicknameRegex = /^[A-Za-z0-9_.]{0,32}$/;
      if (!validNicknameRegex.test(e.currentTarget.value)) {
        setErrorInput({
          ...errorInput,
          nickname: 'Please only enter the following: a-z, "." and "_”'
        });
        return null;
      }

      if (e.currentTarget.value.length > 32) {
        setErrorInput({
          ...errorInput,
          nickname: 'A nickname cannot exceed 32 characters in length'
        });
        return null;
      }
      if (e.currentTarget.value.length < 5) {
        setErrorInput({
          ...errorInput,
          nickname: 'A nickname minimum 5 characters in length'
        });
      }
    }

    if (e.currentTarget.name === 'tag') {
      if (!userLogs.data?.canChangeTag) {
        setErrorInput({
          ...errorInput,
          [e.currentTarget.name as keyof typeof errorInput]:
            getTagErrorMessage()
        });

        return null;
      } else {
        if (e.currentTarget.value.length > 4) {
          return null;
        }
        if (e.currentTarget.value.length < 4) {
          setErrorInput({
            ...errorInput,
            ['tag']: 'Tag must be 4 digits'
          });
        }
      }
    }
    return setEditForm({
      ...editForm,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const getTagErrorMessage = () => {
    if (!userLogs.data?.canChangeTag) {
      const date = Date.parse(userLogs.data?.nextTagUpdateDate as string);
      const dt = new Date(date);

      return `You can edit your username and tagline every 30 days. You will able to change it again on ${
        dayLong[dt.getDay()]
      }, ${dt.getDate()} ${monthLong[dt.getMonth()]} ${dt.getFullYear()}.`;
    }
    return '';
  };

  const onSubmit = () => {
    if (
      Object.keys(errorInput)
        .filter((key) => key !== 'tag')
        .map((key) => errorInput[key as keyof typeof errorInput])
        .filter((item) => item !== null).length === 0
    ) {
      if (image.file !== null) {
        setIsLoading(true);
        onUploadProfile(
          {
            image: image.file
          },
          (_, error) => {
            if (!error) {
              onEditProfile();
            } else {
              setIsLoading(false);
              if (error.data && error.data.message) {
                setErrorInput({
                  ...errorInput,
                  picture: error.data?.message
                });
              } else {
                let msg =
                  "Your picture couldn't be changed due to an unexpected internet error.";
                if (error.data && error.data.detail) {
                  msg = error.data.detail;
                }
                setErrorInput({
                  ...errorInput,
                  picture: msg
                });
              }
            }
          }
        );
      } else {
        setIsLoading(true);
        onEditProfile();
      }
    }
  };

  const onEditProfile = () => {
    onEdit(
      {
        name: editForm.name,
        nickname: editForm.nickname,
        email: editForm.email,
        birthDate: onConvToDateServer(editForm.birthDate),
        country: editForm.country,
        gender: editForm.gender,
        tag: editForm.tag
      },
      (_, error) => {
        if (error) {
          setIsLoading(false);
          if (error.data.error?.code === '122102') {
            setErrorInput({
              ...errorInput,
              tag: error.data.error.message
            });
          } else {
            if (error.data.message) {
              setMessage({ render: error.data.message });
            } else {
              setMessage({
                render: 'Failed to edit profile.',
                variant: 'error'
              });
            }
          }
        } else {
          dispatch(getUserProfile());
          setIsLoading(false);
          setIsEdit(false);
          resetForm();
          setMessage({
            render: 'Profile Information has been updated.',
            variant: 'success'
          });
        }
      }
    );
  };

  const resetForm = () => {
    setImage({ file: null, fileUrl: null });
    setErrorInput({
      name: null,
      nickname: null,
      tag: null,
      email: null,
      birthDate: null,
      country: null,
      gender: null,
      picture: null
    });
    setIsCalendarShown(false);
    setIsGenderOpen(false);
    setIsCountryOpen(false);
  };

  const [onUploadProfile] = useLazyFetch({
    url: '/spa/user-profile/upload-profile-picture',
    method: 'POST'
  });

  const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImage((prev) => ({
      ...prev,
      file: null,
      fileUrl: null
    }));
    setErrorInput((prev) => ({
      ...prev,
      picture: null
    }));
    setAdjustmentRange(true);
    const file = e.currentTarget?.files?.[0];
    const MAX_FILE_SIZE = 5242880;
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorInput({
          ...errorInput,
          picture:
            'File size limit exceeded. Please select a file smaller than 5MB.'
        });
        setImage({ ...image, file: null });
        return true;
      }
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          // setImage(img);
          if (img.width < 200 || img.height < 200) {
            setAdjustmentRange(false);
            setErrorInput({
              ...errorInput,
              picture: `Your picture is too small (${img.height}x${img.width}). Please choose a photo at least 200 pixels wide.`
            });
            setImage({ ...image, file: null });
            return true;
          } else {
            resetForm();
            setSelectedImage(URL.createObjectURL(file));
            setSelectedImageSize({ width: img.width, height: img.height });
            setTimeout(() => {
              setShowCrop(true);
            }, 100);
          }
        };
        img.onerror = () => {
          setErrorInput({
            ...errorInput,
            picture:
              'Invalid image format. Only JPG and PNG formats are allowed.'
          });
          setImage({ ...image, file: null });
        };
      };
      reader.readAsDataURL(file);

      inputFileRef.current!.value = '';
    }
  };

  useEffect(() => {
    if (isEdit && userLogs?.data) {
      setEditForm({
        name: userLogs?.data.name ?? '',
        tag: userLogs?.data.tag ?? '',
        nickname: userLogs?.data.nickname ?? '',
        email: userLogs?.data.email ?? '',
        birthDate: userLogs
          ? onConvDate(userLogs?.data.birthDate ?? '1970-01-01')
          : '1970-01-01',
        country: userLogs?.data.country ?? '',
        gender: userLogs?.data.gender ?? ''
      });
    }
  }, [isEdit, onConvDate, userLogs]);

  useEffect(() => {
    setProfileUrl(
      userLogs.data?.profilePictureUrl !== null
        ? `${userLogs.data?.profilePictureUrl}`
        : `${
            process.env.REACT_APP_BUCKET_URL ?? ''
          }/assets/img/png/profile-account.png`
    );
  }, [userLogs.data]);

  const getPointData = (code: string): PointData | undefined => {
    return points.find(
      (point) => point.pointCode.toLowerCase() === code.toLowerCase()
    );
  };

  return userLogs.intialState.isLoading || !userLogs.data ? (
    <FullPageLoading />
  ) : (
    <>
      <div className={styles['container-main']}>
        <Card
          title="Profile"
          {...(!isEdit
            ? {
                actionButton: (
                  <div
                    onClick={() => {
                      logEvent(
                        AnalyticsEvent.BUTTON_CLICKED,
                        'Edit Profile Pencil Button Click'
                      );
                      setIsEdit(true);
                    }}
                    className={styles['action-button']}
                  >
                    <EditIcon color="#C4964D" />
                  </div>
                )
              }
            : undefined)}
        >
          <form
            className={`${styles['content']} ${isEdit ? styles['edit'] : ''}`}
            onSubmit={(e) => {
              e.preventDefault();
              return isEdit ? onSubmit() : null;
            }}
          >
            {!userLogs.data?.country && (
              <ProfileAlert
                text={
                  <>
                    Please add your <span>region information</span> to receive
                    relevant
                    <span> updates and services</span>.
                  </>
                }
              />
            )}
            {!isEdit ? (
              <>
                <div className={styles['photo-profile-container']}>
                  <img
                    loading="lazy"
                    className={styles['photo-profile']}
                    src={profileUrl}
                  />
                  <div className={styles['point-container']}>
                    <PointDisplay
                      icon={<ConfictionIcon />}
                      text={formatNumber(getPointData('exp')?.point) ?? '0'}
                      height="30px"
                      tooltipPosition={width > 768 ? 'bottom' : 'top'}
                      tooltipText={
                        <span>
                          XP. Boost your XP by engaging with the
                          <br />
                          Confiction Lab product ecosystem.
                        </span>
                      }
                    />
                    <PointDisplay
                      icon={<PointIcon />}
                      text={formatNumber(getPointData('cs')?.point) ?? '0'}
                      height="30px"
                      tooltipPosition="bottom"
                      tooltipText={
                        <span>
                          Points. Earn points by completing quests on <br />
                          the quest page.
                        </span>
                      }
                      type="cs"
                    />
                  </div>
                </div>
                <div className={styles['data-user']}>
                  <div className={styles['data-row']}>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Name</div>
                      <div className={styles['value']}>
                        {userLogs.data?.name}
                      </div>
                    </div>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Username</div>
                      <div className={styles['value']}>
                        {userLogs.data?.nickname}{' '}
                        <span className={styles['grey-text']}>
                          #{userLogs.data?.tag}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles['data-row']}>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Email</div>
                      <div className={styles['value-email']}>
                        {userLogs.data?.email}
                      </div>
                    </div>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Date of Birth</div>
                      <div className={styles['value']}>
                        {userLogs.data?.birthDate &&
                          onConvDate(userLogs.data?.birthDate)}
                      </div>
                    </div>
                  </div>
                  <div className={styles['data-row']}>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Region</div>
                      <div className={styles['value']}>
                        {(userLogs.data?.country &&
                          iso3166.filter(
                            (item) => item.alpha2 === userLogs.data?.country
                          )?.[0]?.name) ??
                          ''}
                      </div>
                    </div>
                    <div className={styles['data-col']}>
                      <div className={styles['label']}>Gender</div>
                      <div className={styles['value']}>
                        {userLogs.data?.gender}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles['input-photo']}>
                  <img
                    loading="lazy"
                    onError={() => {
                      if (image.fileUrl && image.fileUrl !== '') {
                        setErrorInput({
                          ...errorInput,
                          picture:
                            'Invalid image format. Only JPG and PNG formats are allowed.'
                        });
                        setImage({ ...image, file: null });
                      }
                    }}
                    className={styles['photo-profile']}
                    src={
                      !errorInput.picture &&
                      image.fileUrl &&
                      image.fileUrl !== ''
                        ? image.fileUrl
                        : profileUrl
                    }
                  />
                  <div className={styles['input-photo-action']}>
                    <div className={styles['input-photo-button']}>
                      <Button
                        isLoading={false}
                        label="Change Picture"
                        size={'responsive'}
                        variant="default-magenta"
                        onClick={() => inputFileRef?.current?.click()}
                      />
                      <input
                        ref={inputFileRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={onChangePhoto}

                        // onClick={(
                        //   e: React.MouseEvent<HTMLInputElement, MouseEvent>
                        // ) => (e.target.value = null)}
                      />
                      {errorInput['picture'] && (
                        <div className={styles['data-col-error']}>
                          <ErrorIcon />
                          {errorInput.picture}
                        </div>
                      )}
                    </div>
                    <div className={styles['input-photo-description']}>
                      Upload a file from your device. Image should be square, at
                      least 200px x 200px.
                    </div>
                  </div>
                </div>
                <div className={styles['data-user']}>
                  <div className={styles['data-row']}>
                    <div className={styles['data-col']}>
                      <Input
                        variant="new-confiction"
                        label="Name"
                        name="name"
                        error={
                          errorInput.name !== ''
                            ? (errorInput.name as string)
                            : ''
                        }
                        maxLength={50}
                        value={editForm.name}
                        onChange={onChangeInput}
                        onRemove={(e) =>
                          setEditForm({
                            ...editForm,
                            [e.currentTarget.name]: ''
                          })
                        }
                      />
                    </div>
                    <div className={styles['data-col']}>
                      <div
                        className={`${styles['data-row']} ${styles['username']}`}
                      >
                        <div className={styles['data-col']}>
                          <Input
                            variant="new-confiction"
                            label="Username"
                            name="nickname"
                            error={
                              errorInput['nickname'] != ''
                                ? (errorInput['nickname'] as string)
                                : ''
                            }
                            value={editForm.nickname}
                            onChange={onChangeInput}
                            onRemove={(e) => {
                              if (!userLogs.data?.canChangeTag) {
                                setErrorInput({
                                  ...errorInput,
                                  ['tag' as keyof typeof errorInput]:
                                    getTagErrorMessage()
                                });
                              } else {
                                setEditForm({
                                  ...editForm,
                                  [e.currentTarget.name]: ''
                                });
                              }
                            }}
                          />
                        </div>
                        <div
                          className={`${styles['data-col']} ${styles['tag']}`}
                        >
                          <Input
                            type="number"
                            variant="new-confiction"
                            label="Tag"
                            name="tag"
                            error={
                              errorInput['tag'] != '' &&
                              userLogs.data?.canChangeTag
                                ? (errorInput['tag'] as string)
                                : ''
                            }
                            suffix="#"
                            value={editForm.tag}
                            onChange={onChangeInput}
                          />
                        </div>
                      </div>
                      {!userLogs.data?.canChangeTag && errorInput['tag'] && (
                        <div className={styles['data-col-error']}>
                          <ErrorIcon />
                          {errorInput.tag}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles['data-row']}>
                    <div className={styles['data-col']}>
                      <div className={styles['email-col']}>
                        {editForm.email}
                      </div>
                    </div>
                    <div className={styles['data-col']}>
                      <DatePicker
                        calendarExtraClassName={styles.extraDatepicker}
                        variant="new-confiction"
                        label="Date of birth"
                        name="birthDate"
                        error={
                          errorInput['birthDate'] ? errorInput['birthDate'] : ''
                        }
                        value={editForm.birthDate}
                        onChange={onChangeInput}
                        calendarIsShown={
                          isCalendarShown && !isCountryOpen && !isGenderOpen
                        }
                        setCalendarIsShown={setIsCalendarShown}
                        disableDate={disabledDate}
                      />
                    </div>
                  </div>
                  <div className={styles['data-row']}>
                    <div
                      className={`${styles['data-col']} ${styles['select']}`}
                    >
                      <Select
                        name="country"
                        label="Location"
                        variant="new-confiction"
                        value={editForm.country}
                        isExpanded={
                          isCountryOpen && !isGenderOpen && !isCalendarShown
                        }
                        setIsExpanded={setIsCountryOpen}
                        options={iso3166
                          .filter((item) => item.alpha2 !== null)
                          .map((item) => ({
                            text: item.name,
                            value: item.alpha2 ?? ''
                          }))}
                        withFilter
                        onChange={onChangeInput}
                        optionsWrapperExtraClassName={styles['select-extra']}
                      />
                    </div>
                    <div
                      className={`${styles['data-col']} ${styles['select']}`}
                    >
                      <Select
                        name="gender"
                        label="Gender"
                        variant="new-confiction"
                        isExpanded={
                          isGenderOpen && !isCountryOpen && !isCalendarShown
                        }
                        setIsExpanded={setIsGenderOpen}
                        value={editForm.gender.replace(/\s/g, '')}
                        options={[
                          { text: 'Not Specified', value: 'NotSpecified' },
                          { text: 'Male', value: 'Male' },
                          { text: 'Female', value: 'Female' },
                          { text: 'Other', value: 'Other' }
                        ]}
                        withFilter
                        onChange={(e) =>
                          setEditForm({
                            ...editForm,
                            [e.currentTarget.name]: e.currentTarget.value
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={styles['footer-action']}>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    label="Save"
                    size={'responsive'}
                    variant="default-magenta"
                    onClick={() => null}
                  />
                  <Button
                    type="button"
                    theme="dark"
                    isLoading={isLoading}
                    disabled={isLoading}
                    label="Cancel"
                    size={'responsive'}
                    variant="default-magenta"
                    onClick={() => {
                      resetForm();
                      setIsEdit(false);
                    }}
                  />
                </div>
              </>
            )}
          </form>
        </Card>
        <Card title="Social Media">
          <div className={styles['content-social-media']}>
            <div className={styles['socmed-item']}>
              {/* <img
                loading="lazy"
                className={styles['socmed-image']}
                src={`${
                  process.env.REACT_APP_BUCKET_URL ?? ''
                }/assets/img/png/social/social-x-alt.png`}
              /> */}
              <TwitterNewIcon />
              <div className={styles['socmed-action']}>
                <div className={styles['socmed-name']}>X</div>
                <div className={styles['socmed-connection']}>
                  {userLogs.data.twitterId ? (
                    <>
                      Bound @
                      <span className={styles['socmed-username']}>
                        {userLogs.data.twitterUserName}
                      </span>
                    </>
                  ) : (
                    <div className={styles['socmed-status']}>
                      <ErrorIcon color="#647286" />
                      <span>Not Bound</span>
                    </div>
                  )}
                </div>
                <div
                  className={`${styles['socmed-button']} ${
                    userLogs.data.twitterId ? '' : styles['active']
                  } ${
                    userLogs.data.twitterId && !canDisconnectPlatform
                      ? styles['disable']
                      : ''
                  }`}
                  onClick={() => {
                    logEvent(
                      AnalyticsEvent.BUTTON_CLICKED,
                      '3rd Party Connect and Disconnect Profile Click'
                    );
                    if (userLogs.data?.twitterId) {
                      if (canDisconnectPlatform) {
                        onDisconnectAccount('Twitter');
                      }
                    } else {
                      onConnectTwitter();
                    }
                  }}
                >
                  {!isLoadingUnbindTwitter ? (
                    <>
                      {userLogs.data.twitterId ? connectedPlatformText : 'Bind'}
                    </>
                  ) : (
                    <Loading size={10} />
                  )}
                </div>
              </div>
            </div>

            {enableFacebookOauth && (
              <div className={styles['socmed-item']}>
                <img
                  loading="lazy"
                  className={styles['socmed-image']}
                  src={`${
                    process.env.REACT_APP_BUCKET_URL ?? ''
                  }/assets/img/png/social/social-facebook.png`}
                />
                <div className={styles['socmed-action']}>
                  <div className={styles['socmed-name']}>Facebook</div>
                  <div className={styles['socmed-connection']}>
                    {userLogs.data.facebookId ? (
                      `Bound ${userLogs.data.facebookName}`
                    ) : (
                      <div className={styles['socmed-status']}>
                        <ErrorIcon color="#647286" />
                        <span>Not Bound</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${styles['socmed-button']} ${
                      userLogs.data.facebookId ? '' : styles['active']
                    } ${
                      userLogs.data.facebookId && !canDisconnectPlatform
                        ? styles['disable']
                        : ''
                    }`}
                    onClick={(e) => {
                      logEvent(
                        AnalyticsEvent.BUTTON_CLICKED,
                        '3rd Party Connect and Disconnect Profile Click'
                      );
                      if (userLogs.data?.facebookId) {
                        if (canDisconnectPlatform) {
                          onDisconnectAccount('Facebook');
                        }
                      } else {
                        onConnectFacebook();
                      }
                    }}
                  >
                    {userLogs.data.facebookId ? connectedPlatformText : 'Bind'}
                  </div>
                </div>
              </div>
            )}

            <div className={styles['socmed-item']}>
              <DiscordNewIcon />
              <div className={styles['socmed-action']}>
                <div className={styles['socmed-name']}>Discord</div>
                <div className={styles['socmed-connection']}>
                  {userLogs.data.discordId ? (
                    <>
                      Bound @
                      <span className={styles['socmed-username']}>
                        {userLogs.data.discordUserName}
                      </span>
                    </>
                  ) : (
                    <div className={styles['socmed-status']}>
                      <ErrorIcon color="#647286" />
                      <span>Not Bound</span>
                    </div>
                  )}
                </div>
                <div
                  className={`${styles['socmed-button']} ${
                    userLogs.data.discordId ? '' : styles['active']
                  } ${
                    userLogs.data.discordId && !canDisconnectPlatform
                      ? styles['disable']
                      : ''
                  }`}
                  onClick={(e) => {
                    logEvent(
                      AnalyticsEvent.BUTTON_CLICKED,
                      '3rd Party Connect and Disconnect Profile Click'
                    );
                    if (userLogs.data?.discordId) {
                      if (canDisconnectPlatform) {
                        onDisconnectAccount('Discord');
                      }
                    } else {
                      authorize();
                    }
                  }}
                >
                  {!isLoadingUnbindDiscord ? (
                    <>
                      {userLogs.data.discordId ? connectedPlatformText : 'Bind'}
                    </>
                  ) : (
                    <Loading size={10} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles['socmed-item']}>
              {/* <img
                loading="lazy"
                className={styles['socmed-image']}
                src={`${
                  process.env.REACT_APP_BUCKET_URL ?? ''
                }/assets/img/png/social/social-steam.png`}
              /> */}
              <SteamNewIcon />
              <div className={styles['socmed-action']}>
                <div className={styles['socmed-name']}>Steam</div>
                <div className={styles['socmed-connection']}>
                  {userLogs.data.steamId ? (
                    <>
                      Bound @
                      <span className={styles['socmed-username']}>
                        {userLogs.data.steamUserName}
                      </span>
                    </>
                  ) : (
                    <div className={styles['socmed-status']}>
                      <ErrorIcon color="#647286" />
                      <span>Not Bound</span>
                    </div>
                  )}
                </div>
                <div
                  className={`${styles['socmed-button']} ${
                    userLogs.data.steamId ? '' : styles['active']
                  } ${
                    userLogs.data.steamId && !canDisconnectPlatform
                      ? styles['disable']
                      : ''
                  }`}
                  onClick={(e) => {
                    logEvent(
                      AnalyticsEvent.BUTTON_CLICKED,
                      '3rd Party Connect and Disconnect Profile Click'
                    );
                    if (userLogs.data?.steamId) {
                      if (canDisconnectPlatform) {
                        onDisconnectAccount('Steam');
                      }
                    } else {
                      getAuthorizeSteamUrl();
                    }
                  }}
                >
                  {!isLoadingUnbindDiscord ? (
                    <>
                      {userLogs.data.steamId ? connectedPlatformText : 'Bind'}
                    </>
                  ) : (
                    <Loading size={10} />
                  )}
                </div>
              </div>
            </div>

            {/* <div className={styles['socmed-item']}>
              <img
                loading="lazy"
                className={styles['socmed-image']}
                src={`${
                  process.env.REACT_APP_BUCKET_URL ?? ''
                }/assets/img/png/social/social-metaone.png`}
              />
              <div className={styles['socmed-action']}>
                <div className={styles['socmed-name']}>MetaOne</div>
                <div className={styles['socmed-connection']}>Not Connected</div>
                <div
                  className={`${styles['socmed-button']} ${styles['active']}`}
                >
                  Connect
                </div>
              </div>
            </div> */}
          </div>
        </Card>
      </div>
      <CropProfile
        showCrop={showCrop}
        setShowCrop={setShowCrop}
        selectedImage={selectedImage}
        setCroppedImage={setImage}
        adjustmentRange={adjustmentRange}
      />

      <ModalSteam />
    </>
  );
};

export default MyProfile;
