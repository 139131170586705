import { ButtonBackCommon } from '@jupiter/react-common-component';
import {
  BACK_TO_PORTAL_KEY_STORAGE,
  RETURN_URL_KEY_PARAM
} from '@src/common/constants/string';
import { getUrlParameter, getUrlParamFromUrl } from '@src/common/utils/utils';
import { logEvent } from '@src/domain/Analytic/Analythic';
import { AnalyticsEvent } from '@src/domain/Analytic/Analythic.type';
import React from 'react';

const BackToPortal = () => {
  const isValidURL = (url: string | URL) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const returnUri = getUrlParameter(window.location.href, RETURN_URL_KEY_PARAM);
  const display = getUrlParamFromUrl(
    window.location.href,
    returnUri,
    'display'
  );

  if (display !== undefined && display === 'popup') {
    return <></>;
  }

  return (
    <ButtonBackCommon
      isShowArrow={false}
      title="Mythic Portal"
      tooltipText="Return to Portal"
      onBackClick={() => {
        const portalUrlStorage = sessionStorage.getItem(
          BACK_TO_PORTAL_KEY_STORAGE
        );
        let url = process.env.REACT_APP_PORTAL_URL as string;
        if (
          portalUrlStorage != undefined &&
          portalUrlStorage != '' &&
          isValidURL(portalUrlStorage)
        ) {
          url = portalUrlStorage;
          sessionStorage.removeItem(BACK_TO_PORTAL_KEY_STORAGE);
        }
        logEvent(AnalyticsEvent.BUTTON_CLICKED, 'Back To Portal Click');
        window.location.replace(url);
      }}
    />
  );
};

export default BackToPortal;
