import { Button, Input } from '@jupiter/react-common-component';
import React, { LegacyRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import LoginOptions from '../LoginOptions/LoginOptions';
import styles from './SignIn.module.scss';
import { useTranslation } from 'react-i18next';

type ISignIn = {
  username: string;
  password: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  isUsernameErr: boolean;
  remember: boolean;
  setRemember: React.Dispatch<React.SetStateAction<boolean>>;
  // setIsUsernameErr: React.Dispatch<React.SetStateAction<boolean>>;
  usernameErrMess: string;
  isPassErr: boolean;
  // setIsPassErr: React.Dispatch<React.SetStateAction<boolean>>;
  passErrMess: string;
  onSignIn: () => void;
  onSignUp: () => void;
  onForgotPass: () => void;
  onBtnGoogleClick: () => void;
  onBtnMetamaskClick: () => void;
  onBtnSteamClick: () => void;
  onBtnTwitterClick: () => void;
  setIsErr: React.Dispatch<React.SetStateAction<boolean>>;
  isErr: boolean;
  errMessage: string;
  onMoreOption: () => void;
  recaptchaRef: LegacyRef<ReCAPTCHA>;
  isSigningIn: boolean;
};

const SignIn: React.FC<ISignIn> = ({
  username,
  password,
  setUsername,
  setPassword,
  // setIsUsernameErr,
  usernameErrMess,
  // setIsPassErr,
  passErrMess,
  onSignIn,
  onSignUp,
  onBtnGoogleClick,
  onBtnSteamClick,
  onBtnTwitterClick,
  onBtnMetamaskClick,
  onForgotPass,
  isErr,
  setRemember,
  remember,
  errMessage,
  onMoreOption,
  recaptchaRef,
  isSigningIn,
  isUsernameErr,
  isPassErr
}) => {
  // const onUsernameFocus = useCallback(() => {
  //   setIsUsernameErr(false);
  //   setIsErr(false);
  // }, [setIsErr, setIsUsernameErr]);

  // const onPassFocus = useCallback(() => {
  //   setIsPassErr(false);
  //   setIsErr(false);
  // }, [setIsErr, setIsPassErr]);
  const { t } = useTranslation();
  const siteKey = process.env.REACT_INVISIBLE_RECAPTHA_SITE_KEY;

  return (
    <div className={styles['wrapper']} data-testid="signIn">
      <div className={styles['body-container']}>
        <p className={styles['title']}>
          {t('signin.form.title')} <br />
          Confiction Account
        </p>
        <form
          className={styles['input-wrapper']}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {isErr && <p className={styles['input-err']}>{errMessage}</p>}

          <div className={styles['input-container']}>
            <div className={styles['input']}>
              <Input
                label={t('signin.form.email')}
                value={username}
                name="username"
                type="email"
                onChange={(e) => {
                  setUsername(e.currentTarget.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  // onUsernameFocus();
                }}
                variant="general-line"
                error={isUsernameErr ? usernameErrMess : ''}
              />
            </div>
            <div className={`${styles['input']} ${styles['input-password']}`}>
              <Input
                label={t('signin.form.password')}
                value={password}
                name="password"
                type="password"
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  // onPassFocus();
                }}
                variant="general-line"
                error={isPassErr ? passErrMess : ''}
              />
            </div>
          </div>
          <div className={styles['recaptcha-container']}>
            <ReCAPTCHA
              className={styles['recaptcha']}
              sitekey={siteKey as string}
              ref={recaptchaRef}
              size="invisible"
            />
          </div>
          <div className={styles['forgot-password-wrapper']}>
            <label className={styles['keep-login']}>
              <input
                checked={remember ?? false}
                type="checkbox"
                onChange={(e) => {
                  setRemember(e.target.checked);
                }}
              />{' '}
              <div className={styles['text-checkbox']}>
                {t('signin.form.keepLogin')}
              </div>
            </label>
            <a
              className={styles['forgot-password-text']}
              onClick={(e) => {
                e.preventDefault();
                onForgotPass();
              }}
            >
              {t('signin.form.forgotPassword')}
            </a>
          </div>

          <Button
            isLoading={isSigningIn}
            label={t('general.signIn')}
            size="responsive"
            variant={'default-magenta'}
            onClick={onSignIn}
          />

          <div className={styles['separator-container']}>
            <hr />
            <span className={styles['separator-caption']}>
              {t('general.or')}
            </span>
            <hr />
          </div>
          <LoginOptions
            onBtnGoogleClick={onBtnGoogleClick}
            onBtnTwitterClick={onBtnTwitterClick}
            onBtnSteamClick={onBtnSteamClick}
            onBtnMetamaskClick={onBtnMetamaskClick}
          />
          <a className={styles['more-option']} onClick={onMoreOption}>
            {t('signin.form.moreOptions')}
          </a>
        </form>
      </div>

      <div className={styles['noaccount']}>
        <p>
          {t('signin.form.dontHave')}
          <span onClick={onSignUp}>
            {' '}
            <u>{t('general.createOne')}</u>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
