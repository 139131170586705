// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MTGHA8W0d7OLH8DF91cS{width:100%;height:36px;box-sizing:border-box;background:rgba(192,134,44,.4);display:flex;justify-content:flex-start;align-items:center;-moz-column-gap:8px;column-gap:8px;padding-left:11px}.MTGHA8W0d7OLH8DF91cS .BQaQAsgnYayI3kkUl367{display:flex;justify-content:center;align-items:center;box-sizing:border-box}.MTGHA8W0d7OLH8DF91cS .Xn_xpl5bHBoiNFBjI8vC{color:#e4e4e4;font-family:"Barlow",sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:14px}.MTGHA8W0d7OLH8DF91cS .Xn_xpl5bHBoiNFBjI8vC span{font-weight:700}.MTGHA8W0d7OLH8DF91cS .Xn_xpl5bHBoiNFBjI8vC a{text-decoration:underline;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/molecules/ProfileAlert/ProfileAlert.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,8BAAA,CAEA,YAAA,CACA,0BAAA,CACA,kBAAA,CAEA,mBAAA,CAAA,cAAA,CAEA,iBAAA,CAEA,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,qBAAA,CAGF,4CACE,aAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,iDACE,eAAA,CAGF,8CACE,yBAAA,CACA,cAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 36px;\n  box-sizing: border-box;\n\n  background: rgba(192, 134, 44, 0.4);\n\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  column-gap: 8px;\n\n  padding-left: 11px;\n\n  .icon-wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    box-sizing: border-box;\n  }\n\n  .text-wrapper {\n    color: #e4e4e4;\n    font-family: 'Barlow', sans-serif;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 14px;\n\n    span {\n      font-weight: 700;\n    }\n\n    a {\n      text-decoration: underline;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MTGHA8W0d7OLH8DF91cS`,
	"icon-wrapper": `BQaQAsgnYayI3kkUl367`,
	"text-wrapper": `Xn_xpl5bHBoiNFBjI8vC`
};
export default ___CSS_LOADER_EXPORT___;
