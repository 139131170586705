// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HJXfcvrOR8x_I7u4bFD5{width:100%;height:100%;background:var(--white);cursor:pointer;display:flex;justify-content:center;align-items:center;border-radius:4px}.HJXfcvrOR8x_I7u4bFD5.FlLchvXbpIN2cGoFOM7h{background:#171d25;border:.5px solid #101418}.HJXfcvrOR8x_I7u4bFD5.ZhEJI_vpWMNPUF7ChQi5{background:#1da1f2;border:.5px solid #0281cf}.HJXfcvrOR8x_I7u4bFD5.f53RRtRolqSqa8O0_IF2{background:#5c6bc0;border:.5px solid #2d3fa4}.HJXfcvrOR8x_I7u4bFD5.jLI_x1oalWFLgplStlnR,.HJXfcvrOR8x_I7u4bFD5.n_EJhtphyQmuQHa2cnZp,.HJXfcvrOR8x_I7u4bFD5.XjtwyC75yX69rQyC_E84{background:#171d25;border:.5px solid #101418}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ButtonLogin/ButtonLogin.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,uBAAA,CACA,cAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,2CACE,kBAAA,CACA,yBAAA,CAGF,2CACE,kBAAA,CACA,yBAAA,CAGF,2CACE,kBAAA,CACA,yBAAA,CAGF,iIAGE,kBAAA,CACA,yBAAA","sourcesContent":[".button-wrapper {\n  width: 100%;\n  height: 100%;\n  background: var(--white);\n  cursor: pointer;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 4px;\n\n  &.google {\n    background: #171d25;\n    border: 0.5px solid #101418;\n  }\n\n  &.twitter {\n    background: #1da1f2;\n    border: 0.5px solid #0281cf;\n  }\n\n  &.discord {\n    background: #5c6bc0;\n    border: 0.5px solid #2d3fa4;\n  }\n\n  &.steam,\n  &.x,\n  &.metamask {\n    background: #171d25;\n    border: 0.5px solid #101418;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-wrapper": `HJXfcvrOR8x_I7u4bFD5`,
	"google": `FlLchvXbpIN2cGoFOM7h`,
	"twitter": `ZhEJI_vpWMNPUF7ChQi5`,
	"discord": `f53RRtRolqSqa8O0_IF2`,
	"steam": `jLI_x1oalWFLgplStlnR`,
	"x": `n_EJhtphyQmuQHa2cnZp`,
	"metamask": `XjtwyC75yX69rQyC_E84`
};
export default ___CSS_LOADER_EXPORT___;
