import Carret from '@src/components/Icons/Carret';
import React from 'react';
import { IMenu } from '../../SidebarV2.type';
import styles from './MenuItem.module.scss';

const MenuItem: React.FC<{
  item: IMenu;
  keyName: string;
  activeList: string[];
  checkIfDisabled: (keyName: string) => boolean | undefined;
  onMenuControl: (keyName: string) => void;
  last?: boolean;
  onClose: () => void;
}> = ({
  item,
  keyName,
  activeList,
  checkIfDisabled,
  onMenuControl,
  last = false,
  onClose
}) => {
  return (
    <div className={styles['menu-item']}>
      <div
        className={`${styles['menu-control']} ${
          activeList.findIndex((item) => item === keyName) >= 0 &&
          !(item.child && item.child.length > 0)
            ? styles['active']
            : ''
        }`}
        onClick={() => {
          if (item.onClick) {
            item.onClick();
            onClose();
          }
        }}
      >
        {keyName !== 'Account' && (
          <>
            {' '}
            {item.icon && (
              <div className={styles['menu-icon']}>{item.icon}</div>
            )}
            <div className={styles['menu-label']}>{item.name}</div>
          </>
        )}
      </div>
      {item.child && item.child.length > 0 && (
        <div
          className={`${styles['menu-dropdown']} ${
            activeList.findIndex((item) => item === keyName) >= 0
              ? styles['is-dropdown-open']
              : ''
          }`}
        >
          {item.child.map((subitem: IMenu, key: number) => (
            <div
              key={key}
              className={styles[subitem.disabled ? 'disabled' : '']}
              style={{ color: 'pink' }}
            >
              <MenuItem
                item={subitem}
                keyName={`${keyName}/${subitem.name}`}
                activeList={activeList}
                checkIfDisabled={checkIfDisabled}
                onMenuControl={onMenuControl}
                last={(item.child?.length ?? 0) - 1 === key}
                onClose={onClose}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
