import React from 'react';

const WalletIcon: React.FC<{ color?: string }> = ({ color = '#E4E4E4' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 15C3.675 15 2.96875 14.7063 2.38125 14.1188C1.79375 13.5312 1.5 12.825 1.5 12V6C1.5 5.175 1.79375 4.46875 2.38125 3.88125C2.96875 3.29375 3.675 3 4.5 3H13.5C14.325 3 15.0313 3.29375 15.6188 3.88125C16.2063 4.46875 16.5 5.175 16.5 6V12C16.5 12.825 16.2063 13.5312 15.6188 14.1188C15.0313 14.7063 14.325 15 13.5 15H4.5ZM4.5 6H13.5C13.775 6 14.0375 6.03125 14.2875 6.09375C14.5375 6.15625 14.775 6.25625 15 6.39375V6C15 5.5875 14.8531 5.23438 14.5594 4.94063C14.2656 4.64688 13.9125 4.5 13.5 4.5H4.5C4.0875 4.5 3.73438 4.64688 3.44063 4.94063C3.14688 5.23438 3 5.5875 3 6V6.39375C3.225 6.25625 3.4625 6.15625 3.7125 6.09375C3.9625 6.03125 4.225 6 4.5 6ZM3.1125 8.4375L11.4563 10.4625C11.5688 10.4875 11.6813 10.4875 11.7938 10.4625C11.9062 10.4375 12.0125 10.3875 12.1125 10.3125L14.7188 8.1375C14.5813 7.95 14.4063 7.79688 14.1938 7.67813C13.9813 7.55938 13.75 7.5 13.5 7.5H4.5C4.175 7.5 3.89062 7.58438 3.64688 7.75313C3.40313 7.92188 3.225 8.15 3.1125 8.4375Z"
        fill={color}
      />
    </svg>
  );
};

export default WalletIcon;
