import React, { useRef } from 'react';
import styles from './Agreements.module.scss';
import { useTranslation } from 'react-i18next';

type TAgreements = {
  isEulaChecked: boolean;
  setIsEulaChecked: React.Dispatch<React.SetStateAction<boolean>>;
  eulaErrMessage: string;
  setShowAgreements: React.Dispatch<React.SetStateAction<boolean>>;
  className?: React.CSSProperties;
  isEulaErr: boolean;
};

const Agreements = ({
  isEulaChecked,
  setIsEulaChecked,
  eulaErrMessage,
  setShowAgreements,
  isEulaErr,
  className
}: TAgreements) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    if (checkboxRef.current) {
      setIsEulaChecked(checkboxRef.current.checked);
    }
  };

  return (
    <div
      className={`${styles['field-input']} ${className} ${
        isEulaErr ? styles['error'] : ''
      }`}
      style={{ marginBottom: 20 }}
    >
      <div className={styles['input']}>
        <div className={styles['checkbox-container']}>
          <div>
            <input
              ref={checkboxRef}
              type="checkbox"
              checked={isEulaChecked}
              name="eula"
              id="eulaCheck"
              onChange={handleCheckboxChange}
            />
          </div>
          <label
            htmlFor="eulaCheck"
            className={styles[`${eulaErrMessage && 'invalid-text'}`]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {t('signup.form.eulaLabel')}{' '}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(
                  `${process.env.REACT_APP_PORTAL_URL}/terms-of-use`,
                  '_blank'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('signup.form.tou')}{' '}
            </span>
            and
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(
                  `${process.env.REACT_APP_PORTAL_URL}/privacy-policy`,
                  '_blank'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {t('signup.form.communityAgreement')}
            </span>
          </label>
        </div>
      </div>

      {eulaErrMessage && (
        <p className={styles['danger-text']}>{eulaErrMessage}</p>
      )}
    </div>
  );
};

export default Agreements;
