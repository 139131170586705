/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store';
import { IOidc } from './Oidc.type';

const initialState: IOidc = {
  returnUri: ''
};

export const oidcSlice = createSlice({
  name: 'oidc',
  initialState,
  reducers: {
    setReturnUrl: (state: IOidc, action: PayloadAction<any>) => {
      state.returnUri = action.payload;
    }
  }
});

export const { setReturnUrl } = oidcSlice.actions;

export const selectOidc = (state: RootState) => state.oidc;

export default oidcSlice.reducer;
