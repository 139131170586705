import React from 'react';
import styles from './FullPageLoading.module.scss';
import { Loading } from '@jupiter/react-common-component';

const OIDCLoading = () => {
  return (
    <>
      <div className={styles['container']}>
        <div className={styles['content-container']}>
          <div className={styles['content-title']}>
            <p>
              Signing in to Confiction <br /> Account
            </p>
          </div>
          <Loading size={40} />
          <div className={styles['content-description']}>
            <p>
              Redirecting to your dashboard… <br /> Do not close this tab/window
              page
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OIDCLoading;
