import React from 'react';

const CopyFile: React.FC<{ onClick: () => void; className: string }> = ({
  onClick,
  className
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1678_33060"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1678_33060)">
        <path
          d="M3 17.25C2.5875 17.25 2.23438 17.1031 1.94063 16.8094C1.64688 16.5156 1.5 16.1625 1.5 15.75V5.25H3V15.75H11.25V17.25H3ZM6 14.25C5.5875 14.25 5.23438 14.1031 4.94063 13.8094C4.64688 13.5156 4.5 13.1625 4.5 12.75V2.25C4.5 1.8375 4.64688 1.48438 4.94063 1.19063C5.23438 0.896875 5.5875 0.75 6 0.75H11.25L15.75 5.25V12.75C15.75 13.1625 15.6031 13.5156 15.3094 13.8094C15.0156 14.1031 14.6625 14.25 14.25 14.25H6ZM10.5 6H14.25L10.5 2.25V6Z"
          fill="#F2F2F2"
        />
      </g>
    </svg>
  );
};

export default CopyFile;
