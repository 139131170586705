import { Button } from '@jupiter/react-common-component';
import React from 'react';
import styles from './LogoutSuccess.module.scss';

const LogoutSuccess: React.FC = () => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['body-container']}>
        <p className={styles['title']}>Logout Success</p>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6362 36.2181L7.99951 24.5817L12.0724 20.509L19.6362 28.0726L35.9277 11.7817L40.0005 15.8545L19.6362 36.2181Z"
            fill="#04963E"
          />
        </svg>
        <p className={styles['description']}>
          You have <strong>successfully</strong> logged out. <br />
        </p>
        <div className={styles['button-wrapper']}>
          <Button
            label="Return to Home"
            size="responsive"
            variant={'default-magenta'}
            onClick={() => {
              window.location.replace('/login');
            }}
            isLoading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoutSuccess;
