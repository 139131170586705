import React from 'react';

const TwitterNewIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        fill="#0C0F12"
      />
      <g clipPath="url(#clip0_4156_117247)">
        <path
          d="M30.2048 14.25H33.5128L26.2858 22.51L34.7878 33.75H28.1298L22.9158 26.933L16.9498 33.75H13.6398L21.3698 24.915L13.2148 14.25H20.0398L24.7528 20.481L30.2028 14.25H30.2048ZM29.0438 31.77H30.8768L19.0448 16.126H17.0778L29.0438 31.77Z"
          fill="white"
        />
      </g>
      <rect
        x="0.25"
        y="0.25"
        width="47.5"
        height="47.5"
        rx="3.75"
        stroke="#151528"
        strokeWidth="0.5"
      />
      <defs>
        <clipPath id="clip0_4156_117247">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterNewIcon;
