import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '@src/store';
import { onClose } from '@src/domain/ModalSteam/ModalSteam.reducer';

import ModalContent from '@src/components/atoms/ModalContent/ModalContent';
import SteamNewIcon from '@src/components/Icons/SteamNewIcon';
import ConfictionLabIcon from '@src/components/Icons/ConfictionLabIcon';
import BindIcon from '@src/components/Icons/BindIcon';

import styles from './ModalSteam.module.scss';

const URL_ACCOUNT_MARGE = process.env.URL_MERGING_ACCOUNT;
const ModalSteam: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isShow, titleError, message, providerUserName } = useSelector(
    (state: RootState) => state.modalSteam
  );

  return (
    <ModalContent
      show={isShow}
      onClose={() => {
        dispatch(onClose());
      }}
      noTitle={true}
      title={undefined}
      paddingBody="24px 32px 32px 32px"
    >
      <div className={styles['modal-warp-discord']}>
        <ConfictionLabIcon />
        <h4>{titleError}</h4>
        <div
          className={styles['desc']}
          dangerouslySetInnerHTML={{ __html: message }}
        />

        <div>
          <SteamNewIcon />
          <div>
            <p>Steam ID</p>
            <p>
              <BindIcon /> {providerUserName}
            </p>
          </div>
        </div>

        <button
          onClick={() => {
            window.open(URL_ACCOUNT_MARGE, '_blank');
          }}
        >
          Open Merging Account
        </button>
        <button
          className={styles['second']}
          onClick={() => {
            dispatch(onClose());
          }}
        >
          Close
        </button>
      </div>
    </ModalContent>
  );
};

export default ModalSteam;
