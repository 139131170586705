/* tslint:disable */
/* eslint-disable */
/**
 * Mythic User Feedback Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface BasicResponse
 */
export interface BasicResponse {
  /**
   *
   * @type {IError}
   * @memberof BasicResponse
   */
  error?: IError;
  /**
   *
   * @type {string}
   * @memberof BasicResponse
   */
  message?: string | null;
  /**
   *
   * @type {HttpStatusCode}
   * @memberof BasicResponse
   */
  statusCode?: HttpStatusCode;
}

/**
 *
 * @export
 * @interface HealthResponseModel
 */
export interface HealthResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof HealthResponseModel
   */
  status?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
  Continue: 'Continue',
  SwitchingProtocols: 'SwitchingProtocols',
  Processing: 'Processing',
  EarlyHints: 'EarlyHints',
  Ok: 'OK',
  Created: 'Created',
  Accepted: 'Accepted',
  NonAuthoritativeInformation: 'NonAuthoritativeInformation',
  NoContent: 'NoContent',
  ResetContent: 'ResetContent',
  PartialContent: 'PartialContent',
  MultiStatus: 'MultiStatus',
  AlreadyReported: 'AlreadyReported',
  ImUsed: 'IMUsed',
  Ambiguous: 'Ambiguous',
  Moved: 'Moved',
  Redirect: 'Redirect',
  RedirectMethod: 'RedirectMethod',
  NotModified: 'NotModified',
  UseProxy: 'UseProxy',
  Unused: 'Unused',
  TemporaryRedirect: 'TemporaryRedirect',
  PermanentRedirect: 'PermanentRedirect',
  BadRequest: 'BadRequest',
  Unauthorized: 'Unauthorized',
  PaymentRequired: 'PaymentRequired',
  Forbidden: 'Forbidden',
  NotFound: 'NotFound',
  MethodNotAllowed: 'MethodNotAllowed',
  NotAcceptable: 'NotAcceptable',
  ProxyAuthenticationRequired: 'ProxyAuthenticationRequired',
  RequestTimeout: 'RequestTimeout',
  Conflict: 'Conflict',
  Gone: 'Gone',
  LengthRequired: 'LengthRequired',
  PreconditionFailed: 'PreconditionFailed',
  RequestEntityTooLarge: 'RequestEntityTooLarge',
  RequestUriTooLong: 'RequestUriTooLong',
  UnsupportedMediaType: 'UnsupportedMediaType',
  RequestedRangeNotSatisfiable: 'RequestedRangeNotSatisfiable',
  ExpectationFailed: 'ExpectationFailed',
  MisdirectedRequest: 'MisdirectedRequest',
  UnprocessableEntity: 'UnprocessableEntity',
  Locked: 'Locked',
  FailedDependency: 'FailedDependency',
  UpgradeRequired: 'UpgradeRequired',
  PreconditionRequired: 'PreconditionRequired',
  TooManyRequests: 'TooManyRequests',
  RequestHeaderFieldsTooLarge: 'RequestHeaderFieldsTooLarge',
  UnavailableForLegalReasons: 'UnavailableForLegalReasons',
  InternalServerError: 'InternalServerError',
  NotImplemented: 'NotImplemented',
  BadGateway: 'BadGateway',
  ServiceUnavailable: 'ServiceUnavailable',
  GatewayTimeout: 'GatewayTimeout',
  HttpVersionNotSupported: 'HttpVersionNotSupported',
  VariantAlsoNegotiates: 'VariantAlsoNegotiates',
  InsufficientStorage: 'InsufficientStorage',
  LoopDetected: 'LoopDetected',
  NotExtended: 'NotExtended',
  NetworkAuthenticationRequired: 'NetworkAuthenticationRequired'
} as const;

export type HttpStatusCode =
  (typeof HttpStatusCode)[keyof typeof HttpStatusCode];

/**
 *
 * @export
 * @interface IError
 */
export interface IError {
  /**
   *
   * @type {string}
   * @memberof IError
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof IError
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof IError
   */
  target?: string | null;
  /**
   *
   * @type {Array<any>}
   * @memberof IError
   */
  details?: Array<any> | null;
  /**
   *
   * @type {IInnerError}
   * @memberof IError
   */
  innererror?: IInnerError;
}
/**
 *
 * @export
 * @interface IInnerError
 */
export interface IInnerError {
  /**
   *
   * @type {string}
   * @memberof IInnerError
   */
  code?: string | null;
  /**
   *
   * @type {IInnerError}
   * @memberof IInnerError
   */
  innererror?: IInnerError;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  target?: string | null;
  /**
   *
   * @type {Array<any>}
   * @memberof ModelError
   */
  details?: Array<any> | null;
  /**
   *
   * @type {IInnerError}
   * @memberof ModelError
   */
  innererror?: IInnerError;
}
/**
 *
 * @export
 * @interface PostFeedbackRequestDto
 */
export interface PostFeedbackRequestDto {
  /**
   *
   * @type {Product}
   * @memberof PostFeedbackRequestDto
   */
  product?: Product;
  /**
   *
   * @type {Topic}
   * @memberof PostFeedbackRequestDto
   */
  topic?: Topic;
  /**
   *
   * @type {string}
   * @memberof PostFeedbackRequestDto
   */
  description?: string | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const Product = {
  Other: 'Other',
  MythicPortal: 'MythicPortal',
  Riftstorm: 'Riftstorm',
  InductionHub: 'InductionHub',
  DecentralizedUniversalMeta: 'DecentralizedUniversalMeta',
  MythicRepository: 'MythicRepository'
} as const;

export type Product = (typeof Product)[keyof typeof Product];

/**
 *
 * @export
 * @enum {string}
 */

export const Topic = {
  Other: 'Other',
  BugReport: 'BugReport',
  FeatureRequest: 'FeatureRequest',
  PerformanceIssue: 'PerformanceIssue',
  GeneralComment: 'GeneralComment',
  SecurityConcern: 'SecurityConcern'
} as const;

export type Topic = (typeof Topic)[keyof typeof Topic];

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLivenessGet: async (
      apiVersion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/health/liveness`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (apiVersion != null) {
        localVarHeaderParameter['api-version'] = String(apiVersion);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthReadinessGet: async (
      apiVersion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/health/readiness`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (apiVersion != null) {
        localVarHeaderParameter['api-version'] = String(apiVersion);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthLivenessGet(
      apiVersion?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.healthLivenessGet(apiVersion, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthReadinessGet(
      apiVersion?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<HealthResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.healthReadinessGet(apiVersion, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HealthApiFp(configuration);
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthLivenessGet(
      apiVersion?: string,
      options?: any
    ): AxiosPromise<BasicResponse> {
      return localVarFp
        .healthLivenessGet(apiVersion, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthReadinessGet(
      apiVersion?: string,
      options?: any
    ): AxiosPromise<HealthResponseModel> {
      return localVarFp
        .healthReadinessGet(apiVersion, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
  /**
   *
   * @param {string} [apiVersion] The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public healthLivenessGet(apiVersion?: string, options?: AxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .healthLivenessGet(apiVersion, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [apiVersion] The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthApi
   */
  public healthReadinessGet(apiVersion?: string, options?: AxiosRequestConfig) {
    return HealthApiFp(this.configuration)
      .healthReadinessGet(apiVersion, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserFeedbackCommandApi - axios parameter creator
 * @export
 */
export const UserFeedbackCommandApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {PostFeedbackRequestDto} [postFeedbackRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackPost: async (
      apiVersion?: string,
      postFeedbackRequestDto?: PostFeedbackRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/feedback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration
      );

      if (apiVersion != null) {
        localVarHeaderParameter['api-version'] = String(apiVersion);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postFeedbackRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * UserFeedbackCommandApi - functional programming interface
 * @export
 */
export const UserFeedbackCommandApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    UserFeedbackCommandApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {PostFeedbackRequestDto} [postFeedbackRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbackPost(
      apiVersion?: string,
      postFeedbackRequestDto?: PostFeedbackRequestDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackPost(
        apiVersion,
        postFeedbackRequestDto,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * UserFeedbackCommandApi - factory interface
 * @export
 */
export const UserFeedbackCommandApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserFeedbackCommandApiFp(configuration);
  return {
    /**
     *
     * @param {string} [apiVersion] The requested API version
     * @param {PostFeedbackRequestDto} [postFeedbackRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackPost(
      apiVersion?: string,
      postFeedbackRequestDto?: PostFeedbackRequestDto,
      options?: any
    ): AxiosPromise<BasicResponse> {
      return localVarFp
        .feedbackPost(apiVersion, postFeedbackRequestDto, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * UserFeedbackCommandApi - object-oriented interface
 * @export
 * @class UserFeedbackCommandApi
 * @extends {BaseAPI}
 */
export class UserFeedbackCommandApi extends BaseAPI {
  /**
   *
   * @param {string} [apiVersion] The requested API version
   * @param {PostFeedbackRequestDto} [postFeedbackRequestDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserFeedbackCommandApi
   */
  public feedbackPost(
    apiVersion?: string,
    postFeedbackRequestDto?: PostFeedbackRequestDto,
    options?: AxiosRequestConfig
  ) {
    return UserFeedbackCommandApiFp(this.configuration)
      .feedbackPost(apiVersion, postFeedbackRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
