import React, { useEffect, useRef, useState } from 'react';
import styles from './PointDisplay.module.scss';

interface PointDisplayProps {
  icon: React.ReactNode;
  text: string;
  tooltipText: string | React.ReactNode | undefined;
  width?: string;
  height?: string;
  tooltipPosition?: 'top' | 'bottom';
  type?: 'exp' | 'cs';
}

const PointDisplay: React.FC<PointDisplayProps> = ({
  icon,
  text,
  tooltipText,
  height,
  width,
  tooltipPosition = 'top',
  type = 'exp'
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adjustTooltipPosition = () => {
      if (tooltipRef.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        if (tooltipRect.top < 0) {
          tooltipRef.current.classList.remove(styles.top);
          tooltipRef.current.classList.add(styles.bottom);
        } else if (tooltipRect.bottom > window.innerHeight) {
          tooltipRef.current.classList.remove(styles.bottom);
          tooltipRef.current.classList.add(styles.top);
        }
      }
    };

    if (showTooltip) {
      adjustTooltipPosition();
      window.addEventListener('scroll', adjustTooltipPosition);
      window.addEventListener('resize', adjustTooltipPosition);
    }

    return () => {
      window.removeEventListener('scroll', adjustTooltipPosition);
      window.removeEventListener('resize', adjustTooltipPosition);
    };
  }, [showTooltip]);
  return (
    <div
      style={{
        height: height,
        width: width
      }}
      className={styles.pointDisplay}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className={styles.text}>
        <span className={styles[type]}>{type === 'exp' ? 'EXP ' : 'CS '}</span>
        {text}
      </div>
      {showTooltip && tooltipText != undefined && (
        <div
          ref={tooltipRef}
          className={`${styles.tooltip} ${styles[tooltipPosition]}`}
        >
          {tooltipText}
          <div
            className={`${styles.arrow} ${styles[tooltipPosition + 'Arrow']}`}
          ></div>
        </div>
      )}
    </div>
  );
};

export default PointDisplay;
